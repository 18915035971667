@charset "utf-8";

.u-use--pc {
	@include _sp() {
		display: none;
	}
}

.u-use--sp {
	@include _pc() {
		display: none;
	}

  .not-responsive & {
    display: none !important;
  }
}
// firefoxのbrで高さが付いてきてしまうバグ補正用
.u-use--firefox-br{
  @include _firefox{
    line-height: 0;
      }
}