@charset "utf-8";


// -----------------------------------------------------------------
.p-beginner {
}


// -----------------------------------------------------------------
.p-beginner-heading {
  margin-bottom: 80px;
  @include _sp {
    margin-bottom: 60px;
  }

  &__ttl {
    margin: 65px 0 40px;
    text-align: center;
    @include _sp {
      margin: 60px 0 30px;
    }
  }

  &__lede {
    width: $NARROW-CONTENT-WIDTH;
    margin: 0 auto;
    @include _sp {
      width: auto;
    }

    > * {
      &:not(:last-child){
        margin-bottom: 2em;
      }
    }
  }
}


// -----------------------------------------------------------------
.p-beginner-ttl {
  margin-bottom: 50px;
  text-align: center;
  @include _sp {
  }
}


// -----------------------------------------------------------------
.p-beginner-reason {
  &__group {
    display: flex;
    @include _sp(){
      display: block;
    }
  }

  &__item {
    position: relative;
    width: 333px;
    padding: 25px 30px 30px;
    background: rgba($COLOR-02,.9);
    @include _sp(){
      width: auto;
      padding: 25px 30px 30px;
    }
    &:not(:last-child){
      margin-right: 29px;
      @include _sp(){
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }

  &__en {
    margin-bottom: 15px;
    font-family: $FONT-FAMILY-ADOBE-GARAMOND;
    font-size: 34px;
    line-height: 1;
    text-align: center;
    color: $COLOR-06;
    @include _sp(){
      font-size: 30px;
      span {
        display: inline-block;
        padding: 0 12px;
      }
    }
  }

  &__ja {
    margin-bottom: 15px;
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-size: 20px;
    font-weight: normal;
    line-height: $LINE-HEIGHT-180;
    text-align: center;
    @include _sp(){
      font-size: 20px;
    }
  }

  &__txt {
    @include _sp(){
      letter-spacing: normal;
      text-align: justify;
    }
  }
}


// -----------------------------------------------------------------
.p-beginner-voice {
  &__group {
    display: flex;
    @include _sp(){
      display: block;
    }
  }

  &__item {
    &:not(:last-child){
      margin-right: 29px;
      @include _sp(){
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    a {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 333px;
      height: 100%;
      // padding: 25px 30px 30px;
      color: $COLOR-01;
      border-top: 3px solid $COLOR-06;
      background: $COLOR-02;
      @include _transition-bg-color();
      @include _sp(){
        width: auto;
      }

      &:hover {
        background: $COLOR-13;
        @include _sp(){
          background: $COLOR-02;
        }
      }
    }
  }

  &__head {
    display: flex;
    height: 94px;
    padding: 20px 30px 0;
    border-bottom: 1px solid $COLOR-BORDER-05;
    @include _sp(){
      align-items: center;
      padding: 0 30px;
    }

    &-icn {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 58px;
      height: 58px;
      margin-right: 15px;
      font-size: 34px;
      color: $COLOR-06;
      border-radius: 50%;
      background: $COLOR-13;
    }

    &-txt {
      font-size: 12px;
      letter-spacing: $LETTER-SPACING-05;
      line-height: $LINE-HEIGHT-170;
    }
  }


  &__body {
    padding: 30px 30px 25px;

    &-ttl {
      margin-bottom: 15px;
      font-size: 14px;
      @include _fontbold();
      letter-spacing: $LETTER-SPACING-05;
      line-height: $LINE-HEIGHT-170;
      text-align: center;
      @include _sp(){
        letter-spacing: $LETTER-SPACING-03;
      }
    }

    &-txt {
      font-size: 12px;
      letter-spacing: $LETTER-SPACING-05;
      line-height: $LINE-HEIGHT-170;
      @include _sp(){
        text-align: justify;
      }
    }
  }
}


// -----------------------------------------------------------------
.p-explanation-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include _sp(){
    display: block;
  }

  &_item {
    display: flex;
    flex-direction: column;
    flex: 0 1 218px;
    margin-left: 40px;

    &:first-child {
      margin-left: 0;
    }
    &:nth-of-type(4) {
      margin-left: 0;
    }
    &:nth-of-type(n+4) {
      margin-top: 40px;
    }

    @include _sp(){
      margin-left: 0;
      margin-top: 40px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
  &_title {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #142F71;
    line-height: 28.8px;
  }
  &_text {
    margin-top: 10px;
    font-size: 14px;
    line-height: 28px;
  }
  &_img {
    order: -1;
    text-align: center;
  }
}

// -----------------------------------------------------------------

.p-recruitment-link-block {  
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.p-recruitment-link-block .c-link--arw {
  color: #0068B7;
}
.p-recruitment-link-block .c-link--arw::before {
  color: #0068B7;
}

// -----------------------------------------------------------------

.p-beginner-help__wrap {
  max-width: 800px;
  margin: 0 auto;
}

// -----------------------------------------------------------------
.p-entry-banner-area {
  max-width: 800px;
  margin: 0 auto;
  background-image: url(/_assets/img/beginner/top/bk-img-pc.png);
  background-repeat: no-repeat;
    @include _sp(){
      background-image: url(/_assets/img/beginner/top/bk-img-sp.png);
      background-position: top;
      background-size: 100% auto;
      
    }

  &_inner {
    display: grid;
    grid-template: "img contents" auto / auto 1fr;
    background: linear-gradient(270deg, #F5F5F5 60%, rgba(217, 217, 217, 0) 100%);
    padding: 32px 40px;
      @include _sp(){
        background: linear-gradient(360deg, #F5F5F5 50%, rgba(217, 217, 217, 0) 100%);
        display: block;
        padding: 170px 25px 20px;
      }
  }

  &_contentWrap {
    grid-area: content;
  }

  &_title {
    font-size: 16px;
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-weight: normal;
    @include _sp(){
      line-height: 33.6px;  
    }
    span {
      font-size: 24px;
      margin: 0 5px;
      color: $COLOR-06;
      font-weight: bold;
    }
  }
  &_list {
    margin-top: 15px;

    li {
      color: #142F71;
      font-size: 16px;
      font-weight: bold;
      display: flex;
      align-items: center;
      margin-top: 5px;

      img {
        margin-right: 5px;
      }

      &:first-child {
        margin-top: 0;
      }
        @include _sp(){
          font-size: 14px;
          line-height: 16.8px;
        }
    }
  }
  &_text {
    margin-top: 20px;
    font-size: 14px;
  }
  &_img {
    margin-top: 10px;
    width: 100%;
  }
}

// -----------------------------------------------------------------
.p-tab-content {
  &_list {
    display: flex;
    margin-top: 40px;
    border-bottom: 1px solid #c9caca;
  }
  &_listItem {
    width: 50%;
    height: 50px;
    background: #fff;
    position: relative;
    top: 1px;
    border-radius: 0 4px 0 0;
    border-left: 1px solid #c9caca;

    &:not(.is-current) {
      background: #f5f5f5;
      border-bottom: 1px solid #c9caca;
    }

    &:not(.is-current):hover {
      background: #e8f8ff;
    }

    &:first-child {
      border-radius: 4px 0 0 0;

      &:not(.is-current) {
        border-left: none;
      }
    }

    &.is-current {
      border-top: 4px solid #0068b7;
      pointer-events: none;

      .p-tab-content_link {
        color: #222222;
      }
    }
  }
  &_link {
    line-height: 16.8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    color: #0068B7;
  }
  &_item {
    &.is-hide {
      display: none;
    }
  }

}


// -----------------------------------------------------------------
.beginner-slide-wrap {
  width: 100%;
  max-width: 960px;
  margin: auto;
  margin-bottom: 70px;

  @media screen and (max-width: 768px) {
    overflow: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    padding-left: 10px;
    margin-bottom: 0;
  }
  .beginner-slide-inner, .beginner-slide-item-block {
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 768px) {
    .beginner-slide-inner {
      display: block !important;
    }
  }
  @media screen and (max-width: 768px) {
    .beginner-slide-item-block {
      width: auto;
      display: inline-block !important;
    }
  }
  .beginner-slide-item {
    background-color: #F5F5F5;
    padding: 20px 25px 25px;
    overflow: hidden;
  
    @media screen and (max-width: 768px) {
      width: 85vw !important;
      max-width: 305px;
      padding: 15px 15px;
      display: inline-block;
      overflow: hidden;
  
      + .beginner-slide-item {
        margin-left: 10px;
      }
  
      &:last-child {
        margin-right: 10px;
      }
    }
  }
  .beginner-slide-item.col-2 {
    width: calc(100% / 2 - 10px);
    border-radius: 2px;
  
    &:nth-last-child(n+3) {
      margin-bottom: 40px;
  
      @media screen and (max-width: 768px) {
        margin-bottom: 0;
      }
    }
  }
}



.beginner-slide-wrap {
  .case-head {
    width: calc(100% + 50px);
    height: 120px;
    background-color: #B99969;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    transform: translate(-25px, -20px);
    position: relative;

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 23px 10px 0 10px;
      border-color: #B99969 transparent transparent transparent;
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
    }

    .inner {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .img {
      width: 80px;
      margin-right: 20px;
      position: relative;
    }

    .exp {
      .num {
        color: #fff;
        font-family: "Noto Serif JP", serif;
        font-size: 24px;
        font-weight: normal;
        line-height: 1;
        letter-spacing: 1px;
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;
        
        span {
          font-size: 36px;
          padding-left: 1px;
        }
      }

      .persona {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .case-reason {
    min-height: 64px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;

    .bal {
      width: 64px;
      height: 64px;
      background-color: #B99969;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.3;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
    }

    .comment {
      width: calc(100% - 76px);
      font-size: 14px;
      line-height: 1.8;
    }
  }

  .case-data-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    white-space: normal;
  }

  .case-data-list-item {
    width: calc((100% - 30px) / 2);
    text-align: center;
    position: relative;

    .box {
      min-height: 106px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }

    .head {
      width: calc(100% + 0px);
      height: 32px;
      font-weight: bold;
      font-size: 16px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
    }
    .head.before {
      background-color: #666666;
    }
    .head.after {
      background-color: #142F71;
    }
    .cont {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.3;
      margin-bottom: 15px;
      min-height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      span {
        font-size: 1.3rem;
        display: block;
      }
    }

    .income {
      font-size: 14px;
      line-height: 1;

      span {
        font-size: 20px;
        font-weight: 700;
        padding: 0 0.15em;
        vertical-align: text-bottom;
      }
    }

    .income.after {
      span {
        color: #B99969;
        font-size: 20px;
        vertical-align: text-bottom;
      }
    }
  }

  .ix-icon--arw-1 {
    display: flex;
    align-items: center;

    &::before {
      content: "\e90a";
      width: 16px;
      height: 16px;
      color: #B99969;
    }
  }

  @media screen and (max-width: 768px) {
    .case-head {
      height: 95px;
      margin-bottom: 5px;

      &::after {
        border-width: 12px 7px 0 7px;
        bottom: -12px;
      }

      .img {
        width: 60px;
        height: 60px;
        margin-right: 15px;
      }

      .exp {
        .num {
          font-size: 20px;
          margin-bottom: 5px;

          span {
            font-size: 32px;
          }
        }

        .persona {
          font-size: 16px;
        }
      }
    }

    .case-reason {
      min-height: 77px;

      .bal {
        width: 48px;
        height: 48px;
        font-size: 12px;
        line-height: 1.2;
        margin-right: 7px;
      }

      .comment {
        width: calc(100% - 60px);
        font-size: 14px;
        white-space: normal;
      }
    }

    .case-data-list {
      justify-content: space-between;
    }

    .case-data-list-item {
      width: calc((100% - 30px) / 2);

      .box {
        height: 90px;
      }

      &:nth-of-type(1)::after {
        width: 15px;
        height: 15px;
        right: -23px;
      }
      .head {
        height: 28px;
        font-size: 16px;
      }

      .cont {
        font-size: 14px;
        margin-bottom: 10px;
        letter-spacing: 0;

        span {
          font-size: 1.0rem;
        }
      }

      .income {
        font-size: 12px;

        span {
          font-size: 20px;
          font-weight: bold;
        }
      }

      .income.after {
        span {
          font-size: 20px;
        }
      }
    }
  }
}


.beginner-slide-wrap {
  .slick-arrow {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: .3s ease;

    &:hover {
      opacity: 0.7;
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      border-top: #D5D5D5 solid 3px;
      border-right: #D5D5D5 solid 3px;
      position: absolute;
    }
  }
  .next.slick-arrow {
    right: -70px;
  }
  .prev.slick-arrow {
    left: -70px;
  }

  .next.slick-arrow::before {
    transform: rotate(45deg);
  }
  .prev.slick-arrow::before {
    transform: rotate(-135deg);
  }

  .slick-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 30px;
    
    li {
      width: 15px;
      height: 15px;

      &:not(:last-child) {
        margin-right: 5px;
      }

      button {
        width: 15px;
        height: 15px;
        background-color: #fff;
        padding: 0;
        text-indent: -999em;
        border-radius: 50%;
        transition: .1s ease;
        cursor: pointer;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "•";
          width: 20px;
          height: 20px;
          font-family: slick;
          font-size: 10px;
          line-height: 20px;
          text-align: center;
          color: #d5d5d5;
          opacity: 1;
          -webkit-font-smoothing: antialiased;
        }

        &:hover {
          opacity: 0.75;
        }
      }
    }

    li.slick-active {
      button {
        &::before {
          color: #B99969;
        }
      }
    }
  }

  @media (max-width: 1180px) and (min-width: 769px) {
    width: 100% !important;
    max-width: none;
  
    .slick-arrow {
      width: 40px;
      height: 40px;
    }
  
    .next.slick-arrow {
      right: 10px;
    }
    .prev.slick-arrow {
      left: 10px;
      z-index: 2;
    }
  
    .beginner-slide-wrap .beginner-slide-item {
      padding: 20px 15px 15px;
    }
  
    .beginner-slide-wrap .case-data-ttl {
      width: calc(100% + 30px);
      transform: translate(-15px, -20px);
    }
  }
}

.p-service-explanation-box {
  margin-top: 15px;
  &__inner {
    display: flex;
    @include _sp(){
      display: block;
    }
  }
  &__content {
    flex-basis: 347px;

    + .p-service-explanation-box__content {
      margin-left: 40px;

      @include _sp(){
        margin-left: 0;
        margin-top: 40px;
      }
    }
  }
  &__title {
    color: #142F71;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  &__img-1,
  &__img-2 {
    display: block;
    margin: 0 auto;
    margin-bottom: 25px;
    @include _sp(){
      margin-bottom: 20px;
    }
  }
  &__img-1 {
    width: 136px;
    @include _sp(){
      width: 102px;
    }
  }
  &__img-2 {
    width: 186px;
    @include _sp(){
      width: 139px;
    }
  }

  &__list {
    margin-top: 15px;
    @extend %_c-list-disc;
    padding-left: 1.25em;
    line-height: 1.5;
    
    li {
      &:not(:last-child){
        margin-bottom: 5px;
      }
    }
  }
  &__note {
    font-size: 11px;
    text-align: center;
    color: #999;
    margin-top: 15px;

    @include _sp(){
      margin-top: 35px;
      text-align: left;
    }
  }

  + .p-cta-box-light {
    margin-top: 25px;
  }
}

.p-heading-sub-text {
  margin-top: 20px;
  line-height: 1.5;
  @include _sp(){
    margin-top: 12px;
    margin-right: 20px;
    margin-left: 20px;
    text-align: left;
  }
  a {
    color: #0068b7;
  }
  a:hover {
    text-decoration: underline;
  }
}
.ix-l-content__inner {
  .p-heading-sub-text {
    @include _sp(){
      margin-right: 0;
      margin-left: 0;
    }
  }
}