.u-fw {
  &--normal {
    font-weight: normal;
  }

  &--bold {
    font-weight: bold;
  }
}

