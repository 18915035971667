@charset "utf-8";


// -----------------------------------------------------------------
.p-brand {
  margin-bottom: 100px;
  @include _sp {
    margin-bottom: 70px;
  }

  &__content {
    width: 100%;
  }

  &__copy {
    padding-top: 140px;
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-size: 24px;
    font-weight: normal;
    line-height: $LINE-HEIGHT-170;
    text-align: center;
    @include _sp(){
      padding-top: 100px;
      font-size: 18px;
    }
    @include _sp(374px){
      font-size: calc(18/375*100vw);
    }
  }

  &__logo {
    width: 402px;
    margin: 23px auto 86px;
    // padding-top: 20px;
    text-align: center;
    @include _sp(){
      width: 274px;
      margin: 16px auto 55px;
      // padding-top: 10px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__ttl {
    margin-bottom: 50px;
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-size: 24px;
    font-weight: normal;
    line-height: $LINE-HEIGHT-180;
    letter-spacing: $LETTER-SPACING-07;
    text-align: center;
    @include _sp() {
      font-size: 22px;
    }
  }
}


// -----------------------------------------------------------------
.p-brand-heading {
  @include _sp {
    margin-top: 30px;
  }
}

// -----------------------------------------------------------------
.p-brand-banner {
  padding: 60px 0;
  background-color: $COLOR-13;
  @include _sp {
    padding: 40px 0;
  }
  &__inner {
    width: 860px;
    margin: 0 auto;
    padding: 0 70px;
    @include _sp {
      width: 100%;
      padding: 0 35px;
    }
    a {
      transition: opacity .3s ease 0s;
      &:hover {
        opacity: .8;
      }
    }
  }
  &__lead {
    color: $COLOR-01;
    padding-top: 20px ;
  }
}

// -----------------------------------------------------------------
.p-brand-persol {
  background: $COLOR-02;
  padding-bottom: 80px;

  &__inner {
    width: 860px;
    margin: 0 auto;
    padding: 30px 70px 40px;
    @include _sp {
      width: auto;
      margin: 20px 20px 20px;
      padding: 0;
      border: 8px solid #f5f5f5;
    }
  }
  &__body {
    @include _sp {
      padding: 30px 20px 40px;
      &:first-child {
        padding-bottom: 0;
        border-bottom: 10px solid #f5f5f5;
      }
    }
  }

  &__logo {
    margin-bottom: 10px;
    text-align: center;
  }

  &__ttl {
    margin-bottom: 30px;
    text-align: center;
    @include _sp {
      width: auto;
      margin-bottom: 20px;
    }
  }

  &__lede {
    margin-bottom: 40px;
    @include _sp {
      margin-bottom: 50px;
    }
  }
}



// -----------------------------------------------------------------
.p-brand-persol-info {
  margin: 0 -70px 50px;
  padding: 30px 70px 25px;
  background: $COLOR-13;
  @include _sp {
    margin: 0 -20px 50px;
    padding: 30px 12px 25px;
  }

  &__group {
    display: flex; 
    margin-bottom: 12px;
    @include _sp {
      flex-wrap: wrap;
      margin-bottom: 15px;
    }
  }

  &__item {
    width: 25%;
    &:not(:last-child){
      margin-right: 20px;
      @include _sp {
        margin-right: 0;
      }
    }
    @include _sp {
      width: calc(50% - 6px);
      &:nth-child(odd){
        margin-right: 12px;
      }
      &:nth-child(n+3){
        margin-top: 12px;
      }
    }

    &-ttl {
      margin-bottom: 8px;
      font-size: 12px;
      @include _fontbold();
      text-align: center;
    }

    &-txt {
      margin-bottom: 3px;
      padding: 7px 0;
      @include _fontbold();
      text-align: center;
      line-height: 1;
      color: $COLOR-02;
      background: $COLOR-09;
      @include _sp {
        margin-right: 0;
      }
      @include _ie11 {
        padding: 13px 0 1px;
      }

      .t-1 {
        font-size: 30px;
        @include _sp {
          font-size: 28px;
        }
      }
      .t-2 {
        font-size: 15px;
        @include _sp {
          font-size: 14px;
        }
      }
    }
  }
}



// -----------------------------------------------------------------
.p-brand-persol-service {
  margin-bottom: 50px;
  @include _sp {
    margin-bottom: 40px;
  }

  &__ttl {
    margin-bottom: 20px;
    text-align: center;
  }

  &__group {
    display: flex;
    justify-content: center;
    @include _sp {
      flex-direction: column;
      align-items: center;
    }
  }

  &__item {
    width: 33.3%;
    @include _sp {
      width: 224px;
    }
    &:not(:last-child){
      margin-right: 15px;
      @include _sp {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      height: 100px;
      background: $COLOR-BORDER-05;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 1px;
        left: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background: $COLOR-02;
        @include _transition-all();
      }

      &:hover {
        &::before {
          top: 6px;
          left: 6px;
          width: calc(100% - 12px);
          height: calc(100% - 12px);
        }
      }

      > * {
        position: relative;
      }

      p {
        margin: 10px 0 13px;
        font-size: 13px;
        color: $COLOR-01;
      }
    }
  }
}


// -----------------------------------------------------------------
.p-brand-persol-privacy {
  margin: 0 -70px;
  padding: 40px 70px 0;
  border-top: 1px solid $COLOR-BORDER-05;
  @include _sp {
    margin: 0 -20px;
    padding: 40px 20px 0;
  }

  &__inner {
    display: flex;
    align-items: center;
    @include _sp {
      flex-direction: column;
    }
  }

  &__logo {
    width: 58px;
    min-width: 58px;
    margin-right: 20px;
    @include _sp {
      margin-right: 0;
      margin-bottom: 20px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  &__btn {
    width: 315px;
    font-size: 14px;
    margin-bottom: 40px;
    span::after {
      display: inline-block;
      content: "\e90b";
      margin-left: 5px;
      font-size: 11px;
      font-family: ix-tenshoku!important;
    }
    @include _sp {
      margin-bottom: 0;
    }
  }
}



