@charset "utf-8";

// -----------------------------------------------------------------
.c-pressroom {
  $__self: &;

  a {
    display: flex;
    color: $COLOR-01;
    // background: $COLOR-02;
    @include _transition-bg-color;

    &:hover {
      background: $COLOR-13;

      @include _sp {

        // background: none;
        // #{$__self}__txt {
        //   text-decoration: underline;
        // }
      }
    }

    @include _sp {
      display: block;
    }
  }

  &__inner {
    display: flex;
    padding: 20px 30px 15px;
    
    @include _sp {
      display: block;
      padding: 20px 20px 15px;
    }
  }

  &__col-1 {
    width: 140px;
    padding-right: 10px;
    @include _sp {
      display: flex;
      align-items: center;
      width: auto;
      margin-bottom: 20px;
      padding-right: 0;
    }
  }

  &__col-2 {
    width: 855px;
    padding-left: 40px;
    border-left: 1px solid $COLOR-BORDER-05;
    @include _sp {
      width: auto;
      padding-left: 0;
      border-left: none;
    }
  }

  &__date {
    color: $COLOR-08;
    @include _sp {
      font-size: 13px;
      line-height: 1;
    }
  }

  &__category {
    font-size: 12px;
    line-height: $LINE-HEIGHT-160;
    color: #a07a33;
    @include _sp {
      margin-left: 20px;
      padding-left: 20px;
      font-size: 13px;
      line-height: 1;
      border-left: 1px solid $COLOR-BORDER-05;
    }
  }

  &__txt {
    @include _sp {
      letter-spacing: $LETTER-SPACING-03;
      line-height: $LINE-HEIGHT-180;
    }
  }

  &__tag {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0 0 -5px;
    li {
      display: flex;
      align-items: center;
      height: 21px;
      margin: 5px 0 0 5px;
      padding: 0 12px;
      font-size: 12px;
      line-height: $LINE-HEIGHT-160;
      color: $COLOR-08;
      border: 1px solid $COLOR-02;
      background: $COLOR-13;
    }
  }
}


// -----------------------------------------------------------------
.c-pressroom-pager {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: $COLOR-08;

  @include _sp() {
    height: 60px;
    margin: 0 -20px;
    padding: 0 20px;
    background: $COLOR-24;
  }

  &__left {
    @include _sp() {
      display: flex;
      position: relative;
      margin-right: auto;
      z-index: 1;
    }
  }

  &__center {
    @include _sp() {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__right {
    @include _sp() {
      display: flex;
      position: relative;
      margin-left: auto;
    }
  }



  &__prev {
    margin-right: 60px;

    @include _sp() {
      margin-right: auto;
    }

    a {
      display: flex;
      transform: scaleX(-1);

      &::before {
        @include _ix-icon(\e908);
        display: flex;
        font-size: 13px;
        color: $COLOR-06;
      }
    }

    &--all {
      margin-left: -5px;
      margin-right: 35px;

      a {
        display: flex;
        transform: scaleX(-1);

        &::before,
        &::after {
          @include _ix-icon(\e908);
          display: flex;
          font-size: 13px;
          color: $COLOR-06;
        }

        &::before {
          margin-right: -7px;
        }
      }
    }
  }

  &__next {
    margin-left: 60px;

    @include _sp() {
      margin-left: auto;
    }

    a {
      display: flex;

      &::before {
        @include _ix-icon(\e908);
        display: flex;
        font-size: 13px;
        color: $COLOR-06;
      }
    }

    &--all {
      margin-left: 35px;
      margin-right: -5px;

      a {
        display: flex;

        &::before,
        &::after {
          @include _ix-icon(\e908);
          display: flex;
          font-size: 13px;
          color: $COLOR-06;
        }

        &::before {
          margin-right: -7px;
        }
      }
    }
  }

  &__list {
    display: flex;

    li {
      margin: 0 12px;
      font-size: 16px;
      color: $COLOR-08;
    }

    a {
      color: $COLOR-08;

      &:hover {
        text-decoration: underline;
      }

      &.is-current {
        @include _fontbold();
        color: #000;
      }
    }
  }

  &__page {}
}


// -----------------------------------------------------------------
.c-ps-hd-1 {
  margin-bottom: 50px;
  font-family: $FONT-FAMILY-NOTO-SERIF;
  font-size: 22px;
  font-weight: normal;
  letter-spacing: $LETTER-SPACING-07;
  line-height: $LINE-HEIGHT-190;

  @include _sp {
    margin-bottom: 40px;
    font-size: 18px;
    letter-spacing: $LETTER-SPACING-03;
  }
}

.c-ps-hd-2 {
  margin-bottom: 20px !important;
  font-size: 16px;
  @include _fontbold();
  line-height: $LINE-HEIGHT-180;

  @include _sp {
    letter-spacing: $LETTER-SPACING-03;
  }
}

.c-ps-date {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: $LINE-HEIGHT-160;
  text-align: right;
  color: $COLOR-08;

  @include _sp {
    font-size: 11px;
  }
}

.c-ps-lede {
  margin-bottom: 60px;

  @include _sp {
    margin-bottom: 45px;
    letter-spacing: $LETTER-SPACING-03;
  }
  &-meaningless-txt {
    display: inline-block;
  }
}

%_c-ps-txt {
  font-size: 14px;
  letter-spacing: $LETTER-SPACING-05;
  line-height: $LINE-HEIGHT-BASE;
}

.c-ps-txt {
  @extend %_c-ps-txt;
}

.c-ps-txt--small {
  @extend %_c-ps-txt;
  font-size: 13px;
  line-height: $LINE-HEIGHT-190;
}

.c-ps-cap {
  font-size: 10px;
  letter-spacing: $LETTER-SPACING-02;
  line-height: $LINE-HEIGHT-180;
  color: #555;

  @include _sp {
    line-height: $LINE-HEIGHT-160;
  }
}

%_c-ps-grid {
  display: flex;
  margin-bottom: 60px !important;

  @include _sp() {
    display: block;
    margin-bottom: 40px !important;
  }

  >* {}
}

.c-ps-grid--2 {
  @extend %_c-ps-grid;

  >* {
    width: calc(50% -15px);

    @include _sp() {
      width: auto;
    }

    &:not(:last-child) {
      margin-right: 30px;

      @include _sp() {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}

.c-ps-colbox {
  margin-bottom: 60px !important;
  padding: 30px 35px;
  border: 1px solid #E9E9E9;

  @include _sp() {
    padding: 30px;
    letter-spacing: $LETTER-SPACING-03;
  }
}

.c-ps-dl {

  dt {
    margin-bottom: 8px;
    @include _fontbold();
  }
}

.c-ps-share {
  display: flex;
  justify-content: center;
  margin-top: 70px;
  @include _sp {
    margin-top: 50px;
  }

  &__item {
    &:not(:first-child) {
      margin-left: 16px;
    }

    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 42px;
      color: #727171;
      background: #f5f5f5;
      font-size: 16px;
      text-decoration: none;
      text-align: center;
      border-radius: 4px;
      transition: 300ms;

      &:hover {
        color: #000;
      }
    }
  }
}

.c-ps-underline {
  text-decoration: underline;
  font-weight: bold;
}