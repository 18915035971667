@charset "utf-8";

// -----------------------------------------------------------------
.c-link--txt,
a.c-link--txt {
  margin-right: 4px;
  text-decoration: none;
  color: $COLOR-15;
  word-break: break-all;
  &:hover {
    text-decoration: underline;
  }

  &[target="_blank"] {
    &::after {
      display: inline-block;
      @include _ix-icon(\e90b);
      margin-left: 5px;
      font-size: 11px;
      text-decoration: none;
      color: $COLOR-15;
      @include _ie11(){
        position: relative;
        top: 1px;
        height: 11px;
        overflow: hidden;
      }
    }
  }
}


// -----------------------------------------------------------------
%_c-link-arw {
  position: relative;
  display: inline-flex;
  padding-left: 1.25em;
  // 
  line-height: 1.6;
  color: $COLOR-01;
  word-break: break-all;
  @include _transition-color();

  &:hover {
    span {
      text-decoration: underline;
    }
  }

  &::before {
    @include _ix-icon(\e908);  
    display: inline-flex;
    position: absolute;
    top: 4px;
    left: 0;
    font-size: 13px;
    color: $COLOR-06;
    @include _ie11(){
      top: 2px;
    }
  }
}

.c-link--arw {
  @extend %_c-link-arw;
}

.c-link--arw-b {
  @extend %_c-link-arw;
  @include _fontbold();
}

.c-link--arw-l {
  @extend %_c-link-arw;
  font-size: 16px;
  &::before {
    top: 5px;
  }
}

.c-link--arwb-lb {
  @extend %_c-link-arw;
  font-size: 16px;
  @include _fontbold();
  &::before {
    top: 5px;
  }
}


// -----------------------------------------------------------------
.c-link--zoom {
  font-size: 16px;
  @include _fontbold();
  color: #000;

  i {
    position: relative;
    top: 1px;
    margin-right: 5px;
    font-size: 17px;
  }
}


// -----------------------------------------------------------------
.c-link-subnav {
  li {
    border-bottom: 1px solid $COLOR-BORDER-05;
  }

  a {
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 3px 0 5px;
    color: $COLOR-01;
    @include _transition-bg-color();
    @include _ie11(){
      padding-top: 5px;
    }
    @include _sp(){
      padding: 0 15px 0 20px;
    }

    &:hover {
      background: $COLOR-13;
      @include _sp(){
        background: none;
      }
    }

    &::after {
      @include _ix-icon('\e908');
      margin-left: auto;
      color: $COLOR-06;
      @include _ie11(){
        position: absolute;
        top: calc(50% - 7px);
        right: 3px;
      }
    }
  }
}




