@charset "utf-8";

.p-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 200px + $GLOBAL-HEADER-HEIGHT;
  padding-top: $GLOBAL-HEADER-HEIGHT;
  @include _sp() {
    // justify-content: flex-end;
    // height: 120px + $GLOBAL-HEADER-HEIGHT-SP;
    // padding-top: $GLOBAL-HEADER-HEIGHT-SP;
    justify-content: flex-start;
    height: auto;
    margin-top: $GLOBAL-HEADER-HEIGHT-SP;
    padding-top: 84px;

    &--en {
      // height: 160px + $GLOBAL-HEADER-HEIGHT-SP;
    }
  }
}


// -----------------------------------------------------------------
.p-heading-2 {
  margin-bottom: 40px;
  text-align: center;
  @include _sp() {
    margin-bottom: 30px;
  }
}


