@charset "utf-8";


// -----------------------------------------------------------------
.p-column-1 {
  &__group {
    display: flex;
    padding: 50px 0 40px;
    border-top: 1px solid $COLOR-BORDER-02;
    &:last-child {
      border-bottom: 1px solid $COLOR-BORDER-02;
    }
    @include _sp(){
      display: block;
      padding: 40px 0;
    }
  }

  &__col-1 {
    // width: 270;
    width: calc(250/1056*100%);
    @include _sp(){
      width: auto;
      margin-right: 0;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  &__col-2 {
    // width: 786;
    width: calc(766/1056*100%);
    margin-left: 20px;
    @include _sp(){
      width: auto;
      margin-left: 0;
    }

    > * {
      &:not(:last-child) {
        margin-bottom: 1.5em;
      }
    }

    &--2 {
      padding-left: 1.5em;
    }
  }
}


// -----------------------------------------------------------------
.p-column-2 {
  display: flex;
  margin-bottom: 50px;
  @include _sp(){
    display: block;
    margin-bottom: 30px;
  }
  &__group {
    width: 50%;
    @include _sp(){
      width: auto;
      margin-bottom: -1px;
    }
    &:first-child {
      margin-right: 40px;
      @include _sp(){
        margin-right: 0;
      }
    }
  }

  &__item {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $COLOR-BORDER-05;
    @include _sp(){
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: 25px;
      padding: 0 20px 20px;
    }

    &:first-child {
      @include _sp(){
        padding-top: 25px;
        border-top: 1px solid $COLOR-BORDER-05;
      }
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
      @include _sp(){
        padding-bottom: 20px;
        border-bottom: 1px solid $COLOR-BORDER-05;
      }
    }
  }
}


// -----------------------------------------------------------------
.p-column-help {
  display: flex;
  flex-direction: row;
  margin-top: 65px;
  @include _sp(){
    flex-direction: column;
    margin: 50px -20px 0;
  }

  &__sp-ttl {
    order: 1;
    text-align: center;

    .c-hd-2 {
      margin-bottom: 20px;
    }

    .c-hd-5 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      border-top: 1px solid $COLOR-BORDER-02;
      background: $COLOR-13;
    }
  }

  &__col-1 {
    width: 220px;
    @include _sp(){
      order: 3;
      width: auto;
    }
  }

  &__col-2 {
    width: 800px;
    margin-top: 35px;
    margin-right: auto;
    @include _sp(){
      order: 2;
      width: auto;
      margin: 0 0 60px;
    }
  }

  &__nav {
    &:not(:last-child){
      margin-bottom: 30px;
      @include _sp(){
        margin-bottom: 35px;
      }
    }

    .c-hd-5 {
      height: 35px;
      font-size: 14px;
      @include _sp(){
        height: auto;
        margin-bottom: 15px;
        font-size: 15px;
        text-align: center;
      }
    }
  }
}








