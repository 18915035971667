@charset "utf-8";

.p-point {
  $__self: &;
  $__boeder: 8px solid $COLOR-24;

  margin-top: 50px;
  background: $COLOR-24;
  @include _sp(){
    margin-top: 30px;
  }

  &--narrow {
    margin-left: 110px;
    margin-right: 110px;
    @include _sp(){
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    @include _sp(){
      min-height: 72px;
      height: auto;
      padding: 10px 15px;
    }

    + #{$__self}__body {
      border-top: none;
    }
  }

  &__ttl {
    position: relative;
    display: inline-block;
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-size: 18px;
    font-weight: normal;
    line-height: $LINE-HEIGHT-170;
    @include _sp(){
      letter-spacing: $LETTER-SPACING-03;
    }

    // &--line {
    //   &::before,
    //   &::after {
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     top: 50%;
    //     width: 30px;
    //     border-top: 1px solid rgba(#000,.5);
    //     @include _sp(){
    //       display: none;
    //     }
    //   }

    //   &::before {
    //     left: -30px - 20px;
    //   }

    //   &::after {
    //     right: -30px - 15px;
    //   }
    // }
  }

  &__ttl-2 {
    position: relative;
    display: inline-block;
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-size: 18px;
    font-weight: normal;
    line-height: $LINE-HEIGHT-170;
    @include _sp(){
      letter-spacing: $LETTER-SPACING-03;
      line-height: $LINE-HEIGHT-150;
      text-align: center;
    }
  }

  &__body {
    border-top: $__boeder;
    
    @include _sp(){
      .c-list--disc {
        li {
          &:not(:last-child){
            margin-bottom: 10px;
          }
          line-height: 1.7;
        }
      }
    }
  }

  &__item {
    padding: 25px 35px 20px;
    border: $__boeder;
    border-top: none;
    background: $COLOR-02;
    @include _sp(){
      padding: 30px 20px 25px;
    }
  }

  &__inbox {
    margin-top: 30px;
    padding-top: 30px;
    padding-left: 40px;
    border-top: 1px solid $COLOR-BORDER-05;
    @include _sp(){
      padding-left: 20px;
    }
  }
}


// -----------------------------------------------------------------
.p-point-ingroup {
  &:not(:last-child){
    margin-bottom: 40px;
  }

  &__ttl {
    position: relative;
    margin-bottom: 10px;
    padding-left: 1.75em;
    &::before {
      @include _ix-icon('\e914');
      position: absolute;
      top: 6px;
      left: 0;
      font-size: 16px;
      color: $COLOR-15;
    }
  }
}




