@charset "utf-8";


// -----------------------------------------------------------------
.c-dl {
  dt {
    @include _fontbold();
  }
  dd {
  }
}


// -----------------------------------------------------------------
.c-dl-1 {
  margin-left: 1em;

  dt {
    @include _fontbold();
  }

  dd {
    + dt {
      margin-top: 1em;
    }
  }
}


// -----------------------------------------------------------------
.c-dl-2 {
  margin-left: 2em;
  @include _sp(){
    margin-left: 1em;
  }

  dt {
    margin-bottom: 1em;
  }

  dd {
    margin-left: 4.5em;
    @include _sp(){
      margin-left: 4em;
    }

    + dt {
      margin-top: 2em;
    }
  }
}


// -----------------------------------------------------------------
.c-dl-3 {
  dt {
    margin-bottom: 1em;
    // @include _fontbold();
  }

  dd {
    + dt {
      margin-top: 2em;
    }
  }
}

// -----------------------------------------------------------------
.c-dl-number {
  position: relative;
  padding-left: 50px;
  @include _sp(){
    padding-left: 0;
  }

  dt {
    margin-bottom: 10px;
    @include _sp(){
      margin-bottom: 15px;
      padding-left: 40px;
    }
  }

  &__ttl {
    position: relative;
    top: 3px;
    @include _sp(){
      top: 0;
      font-size: 15px;
    }
  }

  &__num {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    font-family: $FONT-FAMILY-ADOBE-GARAMOND;
    font-size: 18px;
    line-height: 1;
    color: $COLOR-02;
    border-radius: 50%;
    background: $COLOR-15;
    @include _sp(){
      width: 26px;
      height: 26px;
      font-size: 14px;
    }
  }

  &__pct {
    @include _sp(){
      margin: 15px 0 20px -40px;
      border: 1px solid #D0D0D0;
      overflow: hidden;
    }
  }

  dd {
    li {
      line-height: $LINE-HEIGHT-170;
      &:not(:last-child){
        margin-bottom: 10px;
      }
    }
  }

  &--pl {
    dd {
      padding-left: 20px;
      @include _sp(){
        padding-left: 25px;
      }
    }
  }

  &--no-pl {
    padding-left: 0;
    dt {
      margin-bottom: 25px;
      padding-left: 50px;
      @include _sp(){
        margin-bottom: 15px;
        padding-left: 40px;
      }
    }
  }
}








