@charset "utf-8";

.ix-l-header {
  position: relative;
  width: 100%;
  min-width: $MAX-CONTENT-WIDTH;
  background: $COLOR-02;
  box-shadow: $COLOR-HEADER-SHADOW;
  z-index: $Z-INDEX-HEADER;
  // @include _transition-transform(.6s, $_ease-out-quart);
  // opacity: 1;
  
  // 
  // .is-fixed-header & {
    // top: 0;
  // }
  position: fixed;
  // transform: translateY(0);
  // .is-hide & {
  //   transform: translateY(-$GLOBAL-HEADER-HEIGHT - 4px);
  //   @include _sp(){
  //     .is-hide & {
  //       transform: translateY(-$GLOBAL-HEADER-HEIGHT-SP - 4px);
  //     }
  //   }
  // }

  @include _sp(){
    min-width: initial;
    min-width: auto;

    // .is-sp-menu-open & {
    //   width: 100vw
    // }
  }

  // &__inner {
  //   width: $base-content-width;
  //   margin: 0 auto;
  //   // padding: 30px 0;
  // }

}