@charset "utf-8";

//太字の設定
@mixin _fontbold() {
  font-family: $FONT-FAMILY-BOLD;
  font-weight: bold;
}

@mixin line-space($fontsize,$line-height) {
  margin-top: -($line-height - $fontsize)  / 2 + rem ;
  margin-bottom: -($line-height - $fontsize)  / 2 + rem ;
  font-size: $fontsize + rem;
  line-height: $line-height + rem;
}



// -----------------------------------------------------------------
%_icon-font {
  font-family: 'ix-tenshoku' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin _ix-icon($char:'') {
  @extend %_icon-font;
  // content: $char;
  content: unquote("\"") + $char + unquote("\"");
}

@mixin _max($min-width: $MAX-CONTENT-WIDTH + 1) {
  @media screen and (min-width: $min-width) {
    @content;
  }
}

@mixin _pc($min-width: $BREAKPOINT + 1) {
  @media print,
  screen and (min-width: $min-width) {
    @content;
  }
}

@mixin _sp($max-width: $BREAKPOINT) {
  @media only screen and (max-width: $max-width) {
    .is-responsive & {
      @content;
    }
  }
}

@mixin _mid($min-width: $BREAKPOINT + 1, $max-width: $MAX-CONTENT-WIDTH) {
  @media screen and (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}

@mixin _landscape(){
  @media screen and (orientation: landscape) {
    @content;
  }
}

@mixin _portrait(){
  @media screen and (orientation: portrait) {
    @content;
  }
}

@mixin _ie11(){
  @media (-ms-high-contrast: none) {
    @content;
  }
}

@mixin _firefox(){
  @-moz-document url-prefix() {
    @content;
  }
}


@mixin _transition-color($duration: .3s, $easing: ease, $delay: 0s) {
  transition: color $duration $easing $delay;
}

@mixin _transition-bg-color($duration: .3s, $easing: ease, $delay: 0s) {
  transition: background-color $duration $easing $delay;
}

@mixin _transition-colors($duration: .3s, $easing: ease, $delay: 0s) {
  transition: color $duration $easing $delay,
              background-color $duration $easing $delay;
}

@mixin _transition-opacity($duration: .3s, $easing: ease,$delay: 0s) {
  transition: opacity $duration $easing $delay;
}

@mixin _transition-transform($duration: .3s, $easing: ease, $delay: 0s) {
  transition: transform $duration $easing $delay;
}


@mixin _transition-boxshadow($duration: .3s, $easing: ease, $delay: 0s) {
  transition: box-shadow $duration $easing $delay;
}

@mixin _transition-all($duration: .3s, $easing: ease, $delay: 0s) {
  transition: all $duration $easing $delay;
}

@mixin _inline-block-list ($letter-spacing:normal, $display:inline-block) {
  list-style: none;
  letter-spacing: -.4em;
  > * {
    display: $display;
    letter-spacing: $letter-spacing;
  }
}

@mixin _clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}
