@charset "utf-8";


// -----------------------------------------------------------------
.p-recruitment-1 {
  margin-bottom: 90px;
  &._full {
    @include _pc(){
      margin-left: -50px;
      margin-right: -50px;
    }
  }
  @include _sp(){
    margin-bottom: 80px;
  }
  &__pct {
    width: 540px;
    margin: 40px auto 0;
    text-align: center;
    @include _sp(){
      width: auto;
    }
  }

  &.p-margin-small {
    margin-top: 90px;
    @include _sp(){
      margin-bottom: 60px !important;
      margin-top: 60px !important;
    }
  }
}


// -----------------------------------------------------------------
.p-recruitment-2 {
  margin: 0 -50px 90px;
  padding: 50px 75px;
  border: 8px solid $COLOR-BORDER-03;
  @include _sp(){
    margin: 0 0 80px;
    padding: 40px 20px;
  }

  &._gap_sm {
    margin-bottom: 50px;
    @include _sp(){
      margin-bottom: 40px;
    }
  }

  &__ttl {
    margin-bottom: 20px;
    text-align: center;
  }

  &__pct {
    margin: 30px auto 0;
    text-align: center;

    &._pc_mt50 {
      @include _pc(){
        margin-top: 50px;
      }
    }
    &._width_50 {
      @include _pc(){
        width: 50%;
      }
    }

    &._sp100 {
      img {
        @include _sp(){
          width: 100%;
          height: auto;
        }
      }
    }
  }
}


// -----------------------------------------------------------------
.p-recruitment-3 {
  &._mb {
    margin-bottom: 100px;
    @include _sp(){
      margin-bottom: 80px;
    }
  }
}