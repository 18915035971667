@charset "utf-8";

// -----------------------------------------------------------------
.p-hunter {
}

// -----------------------------------------------------------------
.p-hunter-heading {
  margin-bottom: 80px;
  @include _sp {
    margin-bottom: 60px;
  }

  &__ttl {
    margin: 65px 0 40px;
    text-align: center;
    @include _sp {
      margin: 60px 0 30px;
    }
  }

  &__lede {
    width: $NARROW-CONTENT-WIDTH;
    margin: 0 auto;
    @include _sp {
      width: auto;
    }

    > * {
      &:not(:last-child) {
        margin-bottom: 2em;
      }
    }
  }
}

// -----------------------------------------------------------------
.p-hunter-nav {
  &__ttl {
    margin-bottom: 40px;
    font-family: $FONT-FAMILY-ADOBE-GARAMOND;
    font-size: 24px;
    font-weight: normal;
    letter-spacing: 0.1em;
    line-height: 1;
    line-height: $LINE-HEIGHT-135;
    text-align: center;
    color: $COLOR-06;
    @include _sp {
      margin-bottom: 25px;
      font-size: 20px;
    }

    &::after {
      content: "";
      display: block;
      width: 64px;
      margin: 30px auto 0;
      border-top: 1px solid $COLOR-06;
      @include _sp {
        width: 46px;
        margin: 25px auto 0;
      }
    }
  }

  &__group {
    display: flex;
    justify-content: center;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    @include _sp {
      display: block;
      margin-bottom: 20px;
    }
  }

  &__item {
    width: 240px;
    @include _sp {
      width: auto;
    }

    &:not(:last-child) {
      margin-right: 32px;
      @include _sp {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}

// -----------------------------------------------------------------
.p-hunter-nav-list {
  margin-top: 40px;
  &__ttl {
    font-size: 16px !important;
    margin-bottom: 10px;
    @include _sp() {
      margin-bottom: 15px;
      text-align: center;
    }
  }
  &__items {
    margin-bottom: 20px;
    @include _sp() {
      margin-bottom: 40px;
    }
    &-top {
    }
    &-bottom {
      display: none;
      .p-hunter-nav-list__item:first-child {
        border-top: none;
      }
    }
  }
  &__item {
    border-bottom: 1px solid #ebebeb;
    &:first-child {
      border-top: 1px solid #ebebeb;
    }
    > a {
      display: flex;
      padding: 10px 10px 10px 0;
      transition: background-color 0.3s ease 0s;
      &:hover {
        background: #f5f5f5;
      }
    }
  }
  &__img {
    flex: 0 0 64px;
  }
  &__info {
    font-size: 12px;
    margin-left: 15px;
    color: #222;
    @include _sp() {
      font-size: 14px;
    }
    &-industry {
      line-height: 1.6;
    }
    &-name {
    }
  }
  &__trigger-btn {
    font-size: 13px;
    margin-top: 0;
    cursor: pointer;
    height: 37px;
    width: 100%;
    @include _sp() {
      padding: 5px 0;
      font-size: 16px;
      height: 44px;
    }
    &:hover {
      background: #f5f5f5;
    }
  }
}

// -----------------------------------------------------------------
.p-hunter-mv {
  position: relative;
  margin-top: $GLOBAL-HEADER-HEIGHT;
  @include _sp() {
    margin-top: $GLOBAL-HEADER-HEIGHT-SP;
  }

  $__mv: haruyuki-nishikawa, maki-ikejiri, jun-takemura, shizuka-sawamoto,
    junji-shibata, takuya-ito, yasuhiro-takahashi, yoshitomo-okita;
  @each $key in $__mv {
    &--#{$key} {
      background: url(/_assets/img/headhunter/#{$key}/mv_01.jpg)
        no-repeat
        center;
      @include _sp() {
        background: #e9e3e5 url(/_assets/img/headhunter/#{$key}/mv_01@2x.jpg)
          no-repeat bottom right;
        background-size: auto 100%;
      }
    }
  }

  .ix-l-content__fig-top {
    > * {
      top: 0;
      @include _sp() {
        top: 0;
      }
    }
  }

  &__inner {
    position: relative;
    width: $BASE-CONTENT-WIDTH;
    height: 600px;
    margin: 0 auto;
    @include _sp() {
      width: auto;
      height: 562px;
      margin: 0 20px;
    }
  }

  &__person {
    width: 550px;
    padding-top: 90px;
    @include _sp() {
      width: auto;
      padding-top: 30px;
      padding-bottom: 170px;
    }
  }

  &__sub {
    display: inline-block;
    margin-bottom: 15px;
    font-family: $FONT-FAMILY-ADOBE-GARAMOND;
    font-size: 24px;
    letter-spacing: 0.1em;
    line-height: 1.6;
    border-bottom: 1px solid $COLOR-06;
    color: $COLOR-06;
    @include _sp() {
      margin-bottom: 20px;
      font-size: 16px;
      border-bottom-color: rgba($COLOR-06, 0.5);
    }
  }

  &__ttl {
    margin-bottom: 40px;
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 0.05em;
    line-height: 1.8;
    @include _sp() {
      margin-bottom: 80px;
      font-size: 22px;
      letter-spacing: 0;
    }
  }

  &__career {
    margin-bottom: 5px;
    font-size: 13px;
    letter-spacing: $LETTER-SPACING-03;
    @include _sp() {
      font-size: 10px;
      line-height: $LINE-HEIGHT-160;
    }
  }

  &__name {
    margin-bottom: 20px;
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-size: 32px;
    letter-spacing: 0.1em;
    line-height: $LINE-HEIGHT-135;
    color: #836535;
    @include _sp() {
      margin-bottom: 0;
    }
  }

  &__dl {
    margin-right: 30px;
    font-size: 12px;
    line-height: $LINE-HEIGHT-160;
    @include _sp() {
      margin-right: 0;
    }
    dt {
      margin-bottom: 10px;
      @include _fontbold();
    }
  }

  &__bottom {
    padding: 25px 20px 20px;
    background: $COLOR-12;
  }
}

// -----------------------------------------------------------------
.p-hunter-interview {
  margin: 140px 0 90px;
  @include _sp() {
    margin: 30px 0 60px;
  }

  &__inner {
    max-width: 1400px;
    margin: 0 auto;
    @include _sp() {
      max-width: initial;
    }
  }

  &__group {
    margin-bottom: 130px;
    @include _sp() {
      margin-bottom: 80px;
    }
  }

  &__bottom {
    position: relative;
    img {
      width: 100%;
      height: auto;
    }

    .c-fig {
      position: absolute;
      top: -68.75vw;
      left: 0;
      font-size: 68.75vw;
      color: #f3f3f3;
      transform: scaleY(-1);
      z-index: -1;
      @include _sp() {
        display: none;
      }
    }
  }
}

// -----------------------------------------------------------------
.p-hunter-interview-group {
  $__self: &;
  display: flex;
  justify-content: center;
  @include _sp() {
    display: block;
  }

  &:first-child {
    margin-bottom: 80px;
    @include _sp() {
      margin-bottom: 0;
    }
  }

  &:not(:last-child) {
    @include _sp() {
      margin-bottom: 70px;
    }
  }

  &.is-reverse {
    flex-direction: row-reverse;
  }

  &__col-1 {
    width: calc(50% - 28px);
    margin-right: 28px;
    @include _sp() {
      width: auto;
      margin-right: 0;
      margin-bottom: 30px;
    }

    .is-reverse & {
      margin-left: 28px;
      @include _sp() {
        margin-left: 0;
      }
    }

    &.c-3 {
      margin-top: 150px;
      @include _sp() {
        margin-top: 0;
      }
    }
  }

  &__col-2 {
    width: calc(50% - 28px);
    margin-left: 28px;
    padding-right: 12.8%;
    @include _sp() {
      width: auto;
      margin-left: 0;
      padding-right: 0;
      margin: 0 20px;
    }
    .is-reverse & {
      margin-right: 28px;
      padding-right: 0;
      padding-left: 12.8%;
      @include _sp() {
        margin-right: 0;
        padding-left: 0;
        margin: 0 20px;
      }
    }

    @media screen and (min-width: $BREAKPOINT + 1) and (max-width: 1400px) {
      width: 500px;
      min-width: 500px;
      margin-right: auto;
      padding-right: 0;
      .is-reverse & {
        margin-left: auto;
        padding-left: 0;
      }
    }

    &.c-1 {
      margin-top: 80px;
      @include _sp() {
        margin-top: 0;
      }
    }

    &.c-2 {
      margin-top: 60px;
      @include _sp() {
        margin-top: 0;
      }
    }

    &.c-3 {
      margin-top: -20px;
      @include _sp() {
        margin-top: 0;
      }
    }
  }

  &__ttl {
    margin-bottom: 25px;
    @include _sp() {
      // text-align: center;
      padding: 10px 0;
    }
  }

  &__body {
    > * {
      &:not(:last-child) {
        margin-bottom: 2em;
      }
      @include _sp() {
        font-size: 16px;
        letter-spacing: 0.03em;
        line-height: 1.8;
      }
    }
  }
}