@charset "utf-8";


// -----------------------------------------------------------------
.c-hd-1 {
  position: relative;
  font-family: $FONT-FAMILY-NOTO-SERIF;
  font-size: 32px;
  font-weight: normal;
  line-height: $LINE-HEIGHT-180;
  @include _sp() {
    font-size: 24px;
    text-align: center;
    line-height: $LINE-HEIGHT-150;
  }

  &__ttl {
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-size: 32px;
    font-weight: normal;
    line-height: $LINE-HEIGHT-180;
    @include _sp() {
      font-size: 24px;
      line-height: $LINE-HEIGHT-150;
    }

    &-en {
      margin-right: 20px;
      font-family: $FONT-FAMILY-ADOBE-GARAMOND;
      font-size: 38px;
      @include _sp() {
        display: block;
        margin-bottom: 4px;
        font-size: 24px;
      }
    }
  }

  &__sub {
    display: block;
    font-family: $FONT-FAMILY;
    font-size: 16px;
    line-height: 1;
    @include _sp() {
      position: absolute;
      top: -34px;
      left: 0;
      width: 100%;
      font-size: 15px;
    }
  }
}


// -----------------------------------------------------------------
.c-hd-2 {
  font-family: $FONT-FAMILY-NOTO-SERIF;
  font-size: 24px;
  font-weight: normal;
  line-height: $LINE-HEIGHT-180;
  letter-spacing: $LETTER-SPACING-07;
  @include _sp() {
    font-size: 20px;
    letter-spacing: $LETTER-SPACING-03;
  }

  &--white {
    color: $COLOR-02;
  }
}

// -----------------------------------------------------------------
.c-hd-2-top {
  margin-bottom: 50px;
  font-family: $FONT-FAMILY-NOTO-SERIF;
  font-size: 32px;
  font-weight: normal;
  line-height: $LINE-HEIGHT-180;
  text-align: center;
  @include _sp() {
    font-size: 24px;
    letter-spacing: $LETTER-SPACING-07;
  }
}


// -----------------------------------------------------------------
.c-hd-2-interview {
  font-family: $FONT-FAMILY-NOTO-SERIF;
  font-size: 24px;
  font-weight: normal;
  line-height: $LINE-HEIGHT-180;
  letter-spacing: $LETTER-SPACING-07;
  @include _sp() {
    font-size: 20px;
    letter-spacing: $LETTER-SPACING-03;
  }
}

// -----------------------------------------------------------------
.c-hd-3 {
  font-family: $FONT-FAMILY-NOTO-SERIF;
  font-size: 22px;
  font-weight: normal;
  line-height: $LINE-HEIGHT-180;
  letter-spacing: $LETTER-SPACING-07;
  @include _sp() {
  }
}


// -----------------------------------------------------------------
.c-hd-4 {
  font-family: $FONT-FAMILY-NOTO-SERIF;
  font-size: 18px;
  font-weight: normal;
  line-height: $LINE-HEIGHT-170;
  @include _sp() {
    font-family: $FONT-FAMILY;
    font-size: 16px;
    @include _fontbold();
  }
}


// -----------------------------------------------------------------
.c-hd-5 {
  font-size: 16px;
  @include _fontbold();
  line-height: $LINE-HEIGHT-180;
  @include _sp() {
    font-size: 15px;
    letter-spacing: $LETTER-SPACING-03;
  }
}


// -----------------------------------------------------------------
.c-hd-6 {
  font-size: 14px;
  @include _fontbold();
  @include _sp() {
  }
}

