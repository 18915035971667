@charset "utf-8";


// -----------------------------------------------------------------
.ix-l-footer {
  position: relative;
  width: 100%;
  min-width: $MAX-CONTENT-WIDTH;
  margin-top: -1px;
  z-index: $Z-INDEX-FOOTER;
  @include _sp(){
    min-width: initial;
    min-width: auto;
  }
  @include _firefox(){
    margin-top: -6px;
  }
  
  &-1 {
    // color: $COLOR-02;
    background: $COLOR-19;

    &__inner {
      position: relative;
      width: $BASE-CONTENT-WIDTH;
      margin: 0 auto;
      padding: 30px 0 20px;
      @include _sp(){
        width: auto;
        padding: 60px 0 25px;
      }
    }
  }

  &-2 {
    background: $COLOR-02;

    &__inner {
      position: relative;
      width: $BASE-CONTENT-WIDTH;
      margin: 0 auto;
      @include _sp(){
        width: auto;
      }
    }
  }
}


// -----------------------------------------------------------------
.ix-l-footer-fixed {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background: rgba(#000,.8);
  z-index: $Z-INDEX-FOOTER-FIXED;
  transform: translateY(100%);
  @include _transition-transform(.8s, $_ease-out-quint);

  // animation-name: _fixed-footer-hide;
  // animation-duration: 1s;
  // animation-timing-function: $_ease-out-quint;
  // animation-iteration-count: 1;
  // animation-fill-mode: both;

  // &.is-active {
  //   animation-name: _fixed-footer-show;
  //   animation-duration: 1.6s;
  //   animation-timing-function: $_ease-out-quint;
  //   animation-iteration-count: 1;
  //   animation-fill-mode: both;
  // }

  &.is-active {
    transform: translateY(0);
  }

  @include _sp(){
    display: block;
  }
}

// @keyframes _fixed-footer-show {
//   0%   { bottom: -120px; }
//   60%  { bottom: 0 ; }
//   65%  { bottom: 0 ; animation-timing-function: $_ease-in-out-cubic; }
//   100% { bottom: -20px; }
// }

// @keyframes _fixed-footer-hide {
//   0%   { bottom: -20px; }
//   100% { bottom: -120px; }
// }






