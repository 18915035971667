@charset "utf-8";


// -----------------------------------------------------------------
%_c-img-wrap {
  @include _clearfix();
  margin-bottom: 60px !important;
  @include _sp(){
    margin-bottom: 50px !important;
    text-align: center;
    p {
      text-align: left;
    }
  }
}

.c-img-wrap {
  @extend %_c-img-wrap;


  &--full {
    @extend %_c-img-wrap;
    text-align: center;
    @include _sp(){
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  &--center {
    @extend %_c-img-wrap;
    text-align: center;
  }
}


// -----------------------------------------------------------------
%_c-img-left {
  float: left;
  width: 384px;
  &:not(:last-child){
    margin-right: 30px;
  }
  @include _sp(){
    float: none;
    width: auto;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.c-img-left {
  @extend %_c-img-left;

  &--small {
    @extend %_c-img-left;
    width: 253px;
    margin-right: 20px;
  }
}


// -----------------------------------------------------------------
%_c-img-right {
  float: right;
  width: 384px;
  // margin-left: 30px;
  &:not(:last-child){
    margin-left: 30px;
  }
  @include _sp(){
    float: none;
    margin-left: 0;
    margin-bottom: 15px;
  }
}

.c-img-right {
  @extend %_c-img-right;

  &--small {
    @extend %_c-img-right;
    width: 253px;
    margin-left: 20px;
  }
}


