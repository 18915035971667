@charset "utf-8";


// -----------------------------------------------------------------
.c-news {
  display: flex;
  width: 100%;
  height: 100%;

  a {
    display: flex;
    align-items: center;
    // height: 56px;
    width: 100%;
    height: 100%;
    font-size: 12px;
    letter-spacing: normal;
    line-height: $LINE-HEIGHT-180;
    color: $COLOR-01;
    overflow: hidden;
    @include _transition-bg-color();
    &:hover {
      background: #fff;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    // height: 56px;
    width: 100%;
    height: 100%;
    padding: 0 35px;
    overflow: hidden;

    @include _sp(){
      display: block;
      padding: 10px 15px 10px 20px;
    }
  }

  &__date {
    margin-right: 25px;
    color: #666;
    @include _sp(){
      margin-bottom: 5px;
    }
  }

  &__txt {
    @include _pc(){
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    @include _sp(){
      
    }
  }
}


// -----------------------------------------------------------------
.c-news-all {
  height: 100%;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 56px;
    height: 100%;
    font-size: 12px;
    color: $COLOR-01;
    @include _transition-bg-color();
    &:hover {
      background: #fff;
    }

    i {
      margin-right: 5px;
      color: $COLOR-06;
    }
  }
}



// -----------------------------------------------------------------
.c-news_item {
  a {
    text-decoration: none;
    color: #222;
  }

  &__inner {
    display: flex;
    column-gap: 6px;
    font-size: 14px;
    letter-spacing: normal;
    line-height: $LINE-HEIGHT-170;
    color: #222;
    @include _sp {
      flex-direction: column;
      row-gap: 4px;
      font-size: 12px;
      line-height: $LINE-HEIGHT-160;
    }
  }
}