@charset "utf-8";

.p-step {
  &__group {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid $COLOR-BORDER-02;
    &:first-child {
      border-top: 1px solid $COLOR-BORDER-02;
    }
    @include _sp() {
      display: block;
      border-top: none;
      border-bottom: none;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &__col-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 88px;
    margin-right: 30px;
    background: $COLOR-13;
    @include _sp() {
      display: block;
      width: auto;
      height: auto;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &__col-2 {
    width: calc(1056px - 280px);
    @include _sp() {
      width: auto;
    }

    p + p {
      margin-top: 1em;
    }
  }

  &__ttl {
    text-align: center;
    @include _sp() {
      display: flex;
      align-items: center;
      min-height: 42px;
      padding: 0 15px;
      text-align: left;
    }

    &-en {
      font-family: $FONT-FAMILY-ADOBE-GARAMOND;
      line-height: 1;
      color: $COLOR-06;
      span {
        font-size: 22px;
        @include _sp() {
          font-size: 20px;
        }
        &:nth-child(2) {
          margin-left: 4px;
          font-size: 25px;
        }
      }
    }

    &-jp {
      margin-top: 4px;
      font-size: 16px;
      @include _fontbold();
      @include _sp() {
        margin-top: 0;
        margin-left: 15px;
        line-height: $LINE-HEIGHT-135;
        // letter-spacing: .025em;
      }
    }
  }
}


// -----------------------------------------------------------------
.p-step-2 {
  &__group {
    position: relative;
    display: flex;
    align-items: center;
    height: 76px;
    padding: 10px 20px 10px 30px;
    background: $COLOR-13;
    @include _sp() {
      display: block;
      height: auto;
      padding: 20px 20px 15px;
    }

    &:not(:last-child){
      margin-bottom: 50px;
      @include _sp() {
        margin-bottom: 40px;
      }
      &::after {
        @include _ix-icon('\e906');
        position: absolute;
        bottom: -32px;
        left: calc(50% - 24px);
        font-size: 24px;
        color: $COLOR-09;
        @include _sp() {
          bottom: -27px;
          left: calc(50% - 12px);
        }
      }
    }

    &--links {
      &:not(:last-child){
        margin-bottom: 70px;
        @include _sp() {
          margin-bottom: 80px;
        }
        &::after {
          bottom: -42px;
          @include _sp() {
            bottom: -64px;
          }
        }
      }
    }
  }

  &__col-1 {
    width: 44%;
    @include _sp() {
      width: auto;
    }
  }

  &__col-2 {
    width: 56%;
    @include _sp() {
      width: auto;
    }
  }

  &__ttl {
    display: flex;
    text-align: center;
    @include _sp() {
      align-items: center;
      margin-bottom: 15px;
    }

    &-en {
      font-family: $FONT-FAMILY-ADOBE-GARAMOND;
      line-height: 1;
      color: $COLOR-06;
      span {
        font-size: 22px;
        @include _sp() {
          font-size: 22px;
        }
        &:nth-child(2) {
          margin-left: 4px;
          font-size: 26px;
        }
      }
    }

    &-jp {
      margin-left: 15px;
      font-family: $FONT-FAMILY-NOTO-SERIF;
      font-size: 18px;
      line-height: 1.2;
      @include _sp() {
        margin-left: 12px;
        font-size: 16px;
      }
    }
  }

  &__links {
    display: flex;
    position: absolute;
    top: 86px;
    right: 0;
    @include _sp() {
      justify-content: center;
      width: 100%;
      top: auto;
      bottom: -38px;
    }

    > * {
      &:not(:last-child){
        margin-right: 25px;
        @include _sp() {
          margin-right: 30px;
        }
      }
      .c-link--arw {
        &::before {
          top: 2px;
          @include _sp() {
            top: 3px;
          }
        }
      }
    }
  }
}


// -----------------------------------------------------------------
.p-step-3 {
  &__group {
    position: relative;
    display: flex;
    background: $COLOR-13;
    @include _sp() {
      display: block;
    }

    &:not(:last-child){
      margin-bottom: 40px;
      @include _sp() {
        margin-bottom: 50px;
      }

      &::after {
        @include _ix-icon('\e906');
        position: absolute;
        bottom: -25px;
        left: calc(50% - 25px);
        font-size: 50px;
        color: $COLOR-10;
        @include _sp() {
          bottom: -30px;
          left: calc(50% - 30px);
          font-size: 60px;
        }
      }
    }
  }

  &__col-1 {
    width: 220px;
    padding: 33px 30px 0 40px;
    @include _sp() {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: auto;
      padding: 20px 20px;
    }
  }

  &__col-2 {
    border: 8px solid $COLOR-13;
    background: $COLOR-02;
    @include _pc(){
      width: calc(100% - 220px);
      padding: 25px 30px 25px 40px;
      border-left: none;
    }
    @include _sp() {
      padding: 30px 20px 25px;
      border-top: none;
    }
  }

  &__ttl {
    margin-bottom: 20px;
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-size: 24px;
    letter-spacing: $LETTER-SPACING-07;
    line-height: $LINE-HEIGHT-150;
    @include _sp() {
      margin-bottom: 0;
      font-size: 20px;
      letter-spacing: $LETTER-SPACING-03;
      line-height: $LINE-HEIGHT-180;
    }
  }

  &__sub {
    line-height: $LINE-HEIGHT-170;
    @include _sp() {
      margin-top: 5px;
    }
  }

  &__lede {
    margin-bottom: 10px;
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-size: 22px;
    letter-spacing: $LETTER-SPACING-07;
    line-height: $LINE-HEIGHT-180;
    @include _sp() {
      margin-bottom: 20px;
      font-size: 18px;
      letter-spacing: $LETTER-SPACING-03;
      text-align: center;
    }
    span {
      color: $COLOR-06;
    }
  }
}





