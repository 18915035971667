.u-align {
  $__align: left, center, right;
  @each $key in $__align {
    &--#{$key} {
      text-align: #{$key};
      
      &--sp {
        @include _sp(){
          text-align: #{$key} !important;
        }
      }
    }
  }
  // &--left {
  //   text-align: left;
  // }

  // &--center {
  //   text-align: center;
  // }

  // &--right {
  //   text-align: right;
  // }
}
