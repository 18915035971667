@charset "utf-8";


// -----------------------------------------------------------------
.p-guide-1 {
  margin: 65px 0 120px;
  @include _sp(){
    margin: 60px 0 90px;

    .p-heading-2 {
      margin-bottom: 15px;
    }
  }

  &__grid {
    display: flex;
    justify-content: center;
    @include _sp(){
      display: block;
      margin: 0 -20px;
    }

    > * {
      &:not(:last-child) {
        margin-right: 10px;
        @include _sp(){
          margin-right: 0;
          margin-bottom: 4px;
        }
      }
    }
  }
}


// -----------------------------------------------------------------
.p-guide-2 {
  margin-bottom: 90px;
  
  &__grid {
    @include _sp(){
      margin: 0 -20px;
    }
  }
}


// -----------------------------------------------------------------
.p-guide-nav {
  &__grid {
    display: flex;
    justify-content: center;
    @include _sp(){
      display: block;
      margin: 0 -20px;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: 20px;
      @include _sp(){
        margin-right: 0;
        margin-bottom: 4px;
      }
    }

    .c-link-subnav {
      display: none;
      margin-top: 4px;
      @include _sp(){
        margin-top: 0;
        margin-bottom: 20px;
      }

      .is-open-guide-nav & {
        display: block;
      }
    }
  }
}


// -----------------------------------------------------------------
.p-guide-headnav {
  display: flex;
  // margin: 50px 0 60px;
  margin-top: 50px;
  @include _sp(){
    display: none;
  }

  &__item {
    flex: 1;
    &:nth-child(2){
      flex: 1.1;
    }
    &:nth-child(3){
      flex: .9;
    }
    &:not(:last-child){
      position: relative;
      margin-right: 62px;
      &::after {
        @include _ix-icon('\e913');
        position: absolute;
        bottom: -15px;
        right: -62px + 14px;
        font-size: 32px;
        color: $COLOR-06;
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    position: relative;
    height: 36px;
    padding: 0 10px;
    color: $COLOR-01;
    border-bottom: 1px solid $COLOR-BORDER-05;
    // @include _transition-bg-color();
    &.is-current,
    &:hover {
      // border-bottom-color: $COLOR-06;
      // background: $COLOR-13;
      &::before {
        transform: scaleY(1);
        @include _transition-transform(.3s, $_ease-out-back);
      }
      &::after {
        transform: scaleX(1);
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $COLOR-13;
      transform-origin: bottom left;
      transform: scaleY(0);
      @include _transition-transform(.3s, $_ease-in-out-quart);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      border-bottom: 1px solid $COLOR-06;
      transform: scaleX(0);
      @include _transition-transform(.3s, $_ease-in-out-quart);
    }
  }

  &__en {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    font-family: $FONT-FAMILY-ADOBE-GARAMOND;
    text-decoration: none;
    color: $COLOR-06;
    span {
      font-size: 18px;
      text-decoration: none;
      &:nth-child(2) {
        margin-left: 2px;
        font-size: 22px;
      }
    }
  }

  &__ja {
    position: relative;
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-size: 15px;
    letter-spacing: $LETTER-SPACING-07;
  }
}


// -----------------------------------------------------------------
.p-guide-toc {
  display: flex;
  margin-bottom: 70px;
  padding: 25px 0 20px;
  border: 1px solid $COLOR-BORDER-05;
  @include _sp(){
    display: block;
    margin-bottom: 60px;
    padding: 15px 20px 20px 25px;
  }

  &__head {
    width: 17%;
    @include _sp(){
      width: auto;
    }
  }

  &__body {
    position: relative;
    width: 83%;
    // padding: 0 20px;
    padding-left: 36px;
    // border-left: 1px solid $COLOR-06;
    @include _sp(){
      width: auto;
      padding: 0;
      // border-left: none;
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      left: 0;
      height: 54px;
      border-left: 1px solid $COLOR-06;
      @include _sp(){
        display: none;
      }
    }
  }

  &__ttl {
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-size: 18px;
    line-height: $LINE-HEIGHT-180;
    text-align: center;
    color: $COLOR-06;
    @include _sp(){
      // margin-bottom: 5px;
      text-align: left;
    }
  }
  
  &__tb {
    width: 100%;
    td {
      // padding: 2px 20px;
      padding: 2px 36px 2px 0;
      @include _sp(){
        display: block;
        margin-top: 10px;
        padding: 0;
      }
    }

    a {
      position: relative;
      color: $COLOR-01;
      @include _sp(){
        display: flex;
        font-size: 13px;
        letter-spacing: $LETTER-SPACING-03;
        line-height: $LINE-HEIGHT-170;
      }
      &:hover {
        &::before {
          border-bottom: 1px solid $COLOR-01;
        }
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        @include _ie11(){
          bottom: 5px;
        }
        @include _sp(){
          display: none;
        }
      }

      span {
        &:nth-child(1){
          margin-right: 5px;
          @include _sp(){
            margin-right: 10px;
          }
        }
      }
    }
  }

  // &__list {
  //   display: flex;
  //   flex-wrap: wrap;
  //   width: 100%;
    
  //   a {
  //     position: relative;
  //     color: $COLOR-01;
  //     @include _pc(){
  //       white-space: nowrap;
  //     }
  //     @include _sp(){
  //       display: flex;
  //       font-size: 13px;
  //       letter-spacing: $LETTER-SPACING-03;
  //       line-height: $LINE-HEIGHT-170;
  //     }
  //     &:hover {
  //       &::before {
  //         border-bottom: 1px solid $COLOR-01;
  //       }
  //     }
  //     &::before {
  //       content: '';
  //       display: block;
  //       position: absolute;
  //       bottom: 0;
  //       left: 0;
  //       width: 100%;
  //       @include _sp(){
  //         display: none;
  //       }
  //     }

  //     span {
  //       &:nth-child(1){
  //         margin-right: 5px;
  //         @include _sp(){
  //           margin-right: 10px;
  //         }
  //       }
  //     }
  //   }
  // }
}


// -----------------------------------------------------------------
.p-guide-link {
  $__self: &;
  margin-bottom: 70px;
  &__grid {
    margin-top: 40px;

    &--1 {
      .c-colbox-frame {
        @include _sp(){
          padding: 30px 0 0;
        }
      }
      .c-hd-5 {
        @include _sp(){
          font-size: 14px;
        }
      }
    }

    &--2 {
      display: flex;
      margin-bottom: 20px;
      @include _sp(){
        display: block;
        margin-bottom: -8px;
      }

      > * {
        width: 50%;
        @include _sp(){
          width: auto;
        }

        &:first-child {
          margin-right: 20px;
          @include _sp(){
            margin-right: 0;
            margin-bottom: -8px;
          }
        }
      }

      .c-hd-5 {
        @include _sp(){
          font-size: 15px;
        }
      }
    }

    .c-list--file {
      display: flex;
      flex-wrap: wrap;
      @include _sp(){
        display: block;
        border-top: 1px solid $COLOR-BORDER-04;
      }

      > li {
        width: calc((99.9% - 40px) / 3);
        margin-left: 20px;
        &:nth-child(3n+1){
          margin-left: 0;
        }
        &:nth-child(-n+3){
          border-top: 1px solid $COLOR-BORDER-04;
        }
        @include _sp(){
          width: auto;
          margin-left: 0;
          border-top: none;
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}


// -----------------------------------------------------------------
.p-guide-link-small {
  $__self: &;
  margin-top: 70px;
  
  &__ttl {
    margin-bottom: 30px;
    text-align: center;
    @include _sp(){
      margin-bottom: 20px;
    }

    .c-hd-4 {
      @include _sp(){
        font-family: $FONT-FAMILY-NOTO-SERIF;
        font-size: 18px;
        font-weight: normal;
      }
    }
  }

  &__grid {
    &--1 {
      .c-colbox-frame {
        @include _sp(){
          padding: 15px 0 0;
          &__ttl {
            margin-bottom: 15px;
          }
        }
      }
    }

    &--2 {
      display: flex;
      margin-bottom: -8px;
      @include _sp(){
        display: block;
        margin-bottom: -8px;
      }
    }

    &-col {
      width: 50%;
      border: 8px solid $COLOR-BORDER-03;
      @include _sp(){
        width: auto;
        margin-bottom: -8px;
      }
      &.col-1 {
        border-right-width: 4px;
        @include _sp(){
          border-width: 8px;
        }
      }
      &.col-2 {
        border-left-width: 4px;
        @include _sp(){
          border-width: 8px;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 74px;
        font-size: 16px;
        color: $COLOR-01;
        background: $COLOR-02;
        @include _transition-bg-color;
        @include _sp(){
          justify-content: flex-start;
          height: 58px;
          padding-left: 24px;
        }
        &:hover {
          background: $COLOR-13;
        }

        i {
          margin-right: 10px;
          font-size: 13px;
          color: $COLOR-06;
        }
      }
    }

    .c-list--file {
      display: flex;
      flex-wrap: wrap;
      @include _sp(){
        display: block;
        border-top: 1px solid $COLOR-BORDER-04;
      }

      > li {
        width: calc((99.9% - 40px) / 3);
        margin-left: 20px;
        &:nth-child(3n+1){
          margin-left: 0;
        }
        &:nth-child(-n+3){
          border-top: 1px solid $COLOR-BORDER-04;
        }
        @include _sp(){
          width: auto;
          margin-left: 0;
          border-top: none;
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}


// -----------------------------------------------------------------
.p-guide-colbox {
  &__group {
    &:not(:last-child){
      margin-bottom: 20px;
    }
  }

  &__box {
    &:not(:last-child){
      margin-bottom: -8px;
    }
    
    @include _sp(){
      .c-list--disc {
        li {
          &:not(:last-child){
            margin-bottom: 10px;
          }
          line-height: 1.7;
        }
      }
    }
  }

  &__list-2col {
    
    .c-colbox-frame {
      padding: 30px 70px 40px;
      @include _sp(){
        padding: 30px 0 0;
      }
    }
    .c-hd-5 {
      @include _sp(){
        font-size: 14px;
      }
    }

    .c-list--file {
      display: flex;
      flex-wrap: wrap;
      @include _sp(){
        display: block;
        border-top: 1px solid $COLOR-BORDER-04;
      }

      > li {
        width: calc((100% - 20px) / 2);
        margin-left: 20px;
        &:nth-child(2n+1){
          margin-left: 0;
        }
        &:nth-child(-n+2){
          border-top: 1px solid $COLOR-BORDER-04;
        }
        @include _sp(){
          width: auto;
          margin-left: 0;
          border-top: none;
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}


// -----------------------------------------------------------------
.p-guide-interview-nav {
  $__border: 7px solid $COLOR-BORDER-03;
  ul {
    @include _pc(){
      display: flex;
      border-left: $__border;
    }
    @include _sp(){
      border-top: $__border;
    }

    li {
      flex: 1;
      border: $__border;
      @include _pc(){
        border-left: none;
      }
      @include _sp(){
        border-top: none;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 74px;
      font-size: 16px;
      color: $COLOR-01;
      background: $COLOR-02;
      @include _transition-bg-color;
      @include _ie11(){
        padding-top: 4px;
      }
      @include _sp(){
        justify-content: flex-start;
        height: 60px;
        font-size: 14px;
        padding-left: 20px;
      }

      &:hover {
        background: $COLOR-13;
      }

      i {
        margin-right: 10px;
        font-size: 13px;
        color: $COLOR-06;
        @include _ie11(){
          position: relative;
          top: -3px
        }
        @include _sp(){
          font-size: 12px;
        }
      }
    }
  }
}







