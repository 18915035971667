@charset "utf-8";

.p-gnav {
  display: flex;
  color: $COLOR-02;

  @include _sp(){
    display: block;
  }

  a {
    color: $COLOR-02;
    &:hover {
      text-decoration: underline;
      @include _sp(){
        text-decoration: none;
      }
    }
  }

  &__col {
    margin-right: 80px;
    @include _sp(){
      margin-right: auto;
      &:last-child {
        border-bottom: 1px solid $COLOR-BORDER-01;
      }
    }
  }

  &__ttl {
    margin-bottom: 15px;
    font-size: 15px;
    @include _fontbold();
    @include _sp(){
      display: flex;
      align-items: center;
      height: 60px;
      margin-bottom: auto;
      padding: 0 10px;
      font-weight: normal;
      border-top: 1px solid $COLOR-BORDER-01;
      &.is-active {
        &::after {
          color: $COLOR-06;
          transform: rotate(-90deg);
        }
      }

      &::after {
        @include _ix-icon(\e908);
        display: flex;
        margin-left: auto;
        font-size: 12px;
        color: $COLOR-08;
        transform-origin: center;
        transform: rotate(90deg);
        @include _transition-all();
      }
    }
  }

  &__list {
    @include _sp(){
      display: none;
      border-top: 1px solid $COLOR-BORDER-01;
    }

    li {
      font-size: 13px;
      &:not(:last-child) {
        margin-bottom: 5px;
        @include _sp(){
          margin-bottom: 8px;
        }
      }

      @include _sp(){
        margin-left: 25px;
        &:first-child {
          margin-top: 20px;
        }
        &:last-child {
          margin-bottom: 20px;
        }
      }

      a {
        @include _sp(){
          display: block;
          letter-spacing: $LETTER-SPACING-02;
        }
      }
    }
  }
}
