@charset "utf-8";

.p-footer {
  a {
    color: $COLOR-02;

    // &:hover {
    //   text-decoration: underline;
    // }
  }
  
  &__topicpath {
    @include _sp(){
      display: none;
    }
  }
}


// -----------------------------------------------------------------
.p-footer-nav-2 {
  display: flex;
  align-items: center;
  margin-top: 40px;
  padding-top: 10px;
  border-top: 1px solid $COLOR-BORDER-01;
  @include _sp(){
    flex-direction: column-reverse;
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }

  &__col-1 {
    @include _sp(){
      margin-top: 30px;
    }
  }

  &__logo {
    @include _sp(){
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__col-2 {
    margin-top: 10px;
    margin-left: auto;
    @include _sp(){
      margin-top: 0;
      margin-left: 0;
      width: 100%;
    }
  }

  &__list {
    display: flex;
    margin-top:0 !important;
    @include _sp(){
      flex-wrap: wrap;

      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $COLOR-BORDER-01;
      }
    }

    li {
      margin-left: 20px;
      font-size: 13px;
      @include _sp(){
        position: relative;
        width: 50%;
        margin-left: 0;
        border-bottom: 1px solid $COLOR-BORDER-01;

        &:nth-child(odd){
          border-right: 1px solid $COLOR-BORDER-01;
          &:last-child {
            &::before {
              content: '';
              position: absolute;
              top: 0;
              right: 0px;
              width: 1px;
              height: 100%;
              background-color: $COLOR-BORDER-01;
            }
          }
        }
      }

      a {
        &:hover {
          text-decoration: underline;
        }
        @include _sp(){
          display: flex;
          align-items: center;
          height: 48px;
          padding: 0 20px;
          font-size: 12px;
          line-height: $LINE-HEIGHT-150;
          &:hover {
            text-decoration: none;
          }
        }

        // &[target="_blank"] {
        //   &::after {
        //     display: inline-block;
        //     @include _ix-icon(\e90b);
        //     margin-left: 5px;
        //     font-size: 11px;
        //     text-decoration: none;
        //     color: $COLOR-08;
        //   }
        // }
      }
    }
  }
  &__seo {
    &.u-use--pc {
      font-size: 0.625rem;
      font-weight: 500;
      line-height: 1.5;
      color: #9FA0A0;
      margin-block: 32px 40px;
    }
    &.u-use--sp {
      @include _sp(){ 
        font-size: 0.625rem;
        font-weight: 500;
        line-height: 1.5;
        color: #9FA0A0;
        margin-block: 12px 10px;
        margin-inline: 18px;
      }
    }
  }
}


// -----------------------------------------------------------------
.p-footer-corp {
  display: flex;
  padding: 15px 0 12px;
  @include _sp() {
    flex-direction: row-reverse;
    align-items: flex-end;
    padding: 15px 10px 12px;
  }

  &__copyright {
    font-size: 13px;
    color: #9FA0A0;
    @include _sp() {
      flex-direction: row-reverse;
      font-size: 12px;
      letter-spacing: .02em;
    }
  }

  &__logo {
    margin-left: auto;
    @include _sp() {
      margin-left: 0;
      margin-right: auto;
      img {
        height: 23px;
        @include _ie11(){
          width: 115px;
        }
      }
    }
  }
}


// -----------------------------------------------------------------
.p-footer-pagetop {
  $__self: &;
  position: absolute;
  top: -50px;
  right: -70px;
  width: 100px;
  height: 100px;
  @include _sp(){
    top: -41px;
    right: 1px;
    width: 84px;
    height: 84px;
    @include _ie11(){
      right: 4px;
    }
  }
  @include _firefox(){
    top: -47px;
  }

  a {
    position: relative;
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: $COLOR-02;

    outline: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    &:hover {
      #{$__self}__icn {
        transform: rotate(-90deg) translateX(6px);
        @include _sp(){
          transform: rotate(-90deg) translateX(3px);
        }
      }

      &::before {
        transform: scale(1.1);
        @include _sp(){
          transform: scale(1);
        }
      }
    }

    #{$__self}__icn {
      display: flex;
      position: absolute;
      top: 8px;
      left: calc(50% -28px);
      font-size: 48px;
      transform: rotate(-90deg);
      color: $COLOR-02;
      @include _transition-transform();
      @include _ie11(){
        top: 6px;
        left: 25px;
      }
      @include _sp(){
        top: 8px;
        left: calc(50% -18px);
        font-size: 36px;
        @include _ie11(){
          top: 5px;
          left: 23px;
        }
      }
    }

    #{$__self}__txt {
      display: flex;
      position: relative;
      margin-top: 57px;
      font-size: 11px;
      @include _fontbold();
      text-align: center;
      line-height: 1.2;
      color: $COLOR-02;
      @include _sp(){
        margin-top: 45px;
        font-size: 10px;
        transform: scale(.9);
      }
      @include _ie11(){
        margin-top: 60px;
      }
    }

    &::before {
      @include _ix-icon(\e901);
      position: absolute;
      top: 0;
      left: 0;
      font-size: 100px;
      color: $COLOR-06;
      @include _transition-transform();
      @include _sp(){
        font-size: 84px;
      }
    }
  }
}


