@charset "utf-8";


// -----------------------------------------------------------------
.p-resume-1 {
  margin-bottom: 30px;
  padding: 25px;
  text-align: center;
  background: $COLOR-13;
  @include _sp(){
    margin: 0 -20px;
    padding: 40px 20px;
  }

  &__pct {
    @include _sp(){
      > * {
        &:not(:last-child){
          margin-bottom: 20px;
        }
      }
    }
  }

  &__btn {
    margin-top: 15px;
    @include _sp(){
      display: none;
    }
  }
}


// -----------------------------------------------------------------
.p-resume-2 {
  display: flex;
  margin-bottom: 70px;
  padding: 50px 30px 30px 50px;
  background: $COLOR-13;
  @include _sp(){
    display: block;
    margin-bottom: 50px;
    padding: 0;
    background: none;
  }

  &__col {
    &:first-child {
      // width: 48%;
      min-width: 450px;
      margin-right: 20px;
      text-align: center;
      @include _sp(){
        min-width: auto;
        min-width: initial;
        width: auto;
        margin: 0 -20px;
        padding: 40px 20px;
        background: $COLOR-13;
      }
    }
    &:last-child {
      // width: 52%;
      margin-left: 20px;
      @include _sp(){
        width: auto;
        margin-left: 0;
      }
    }
  }

  &__group {
    > * {
      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }

  &__item {
    @include _sp(){
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: 25px;
      padding: 0 20px 20px;
      border-bottom: 1px solid $COLOR-BORDER-05;
    }

    &:first-child {
      @include _sp(){
        padding-top: 25px;
        border-top: 1px solid $COLOR-BORDER-05;
      }
    }
  }

  &__btn {
    margin-top: 15px;
    @include _sp(){
      display: none;
    }
  }
}


// -----------------------------------------------------------------
.p-resume-3 {
  margin-bottom: 40px;

  &__colwrap {
    display: flex;
    margin-top: 30px;
    @include _sp(){
      display: block;
      margin-top: 20px;
    }
  }

  &__col {
    $__border: 1px solid $COLOR-BORDER-06;
    width: 33.3%;
    border: $__border;
    border-left: none;

    &:first-child {
      border-left: 1px solid $COLOR-BORDER-06;
    }

    @include _sp(){
      width: auto;
      border: $__border;
      border-top: none;
      &:first-child {
        border-top: 1px solid $COLOR-BORDER-06;
      }
    }

    &-ttl {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 54px;
      border-bottom: $__border;
      background: $COLOR-13;
    }

    &-body {
      padding: 30px 35px;
      @include _sp(){
        padding: 25px 20px;
      }
    }

    &-lede {
      min-height: 6em * $LINE-HEIGHT-BASE;
      @include _sp(){
        min-height: initial;
      }
    }

    &-info {
      margin-top: 20px;
      @include _sp(){
        margin-top: 25px;
      }
      &-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 130px;
        height: 26px;
        margin: 0 auto 15px;
        @include _fontbold();
        color: $COLOR-02;
        border-radius: 2px;
        background: $COLOR-15;
      }

      &-txt {
        color: $COLOR-15;
        @include _sp(){
          @include _fontbold();
        }
      }
    }
  }
}





