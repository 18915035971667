@charset 'utf-8';

@for $i from 0 through 30 {
	.u-mt#{$i * 5} {
		margin-top: #{$i * 5}px !important;
	}
	.u-mr#{$i * 5} {
		margin-right: #{$i * 5}px !important;
	}
	.u-mb#{$i * 5} {
		margin-bottom: #{$i * 5}px !important;
	}
	.u-ml#{$i * 5} {
		margin-left: #{$i * 5}px !important;
	}
}


@for $i from 0 through 30 {
	.u-mt#{$i * 5}--pc {
		@include _pc {
			margin-top: #{$i * 5}px !important;
		}
	}
	.u-mr#{$i * 5}--pc {
		@include _pc {
			margin-right: #{$i * 5}px !important;
		}
	}
	.u-mb#{$i * 5}--pc {
		@include _pc {
			margin-bottom: #{$i * 5}px !important;
		}
	}
	.u-ml#{$i * 5}--pc {
		@include _pc {
			margin-left: #{$i * 5}px !important;
		}
	}
}


@for $i from 0 through 30 {
	.u-mt#{$i * 5}--sp {
		@include _sp {
			margin-top: #{$i * 5}px !important;
		}
	}
	.u-mr#{$i * 5}--sp {
		@include _sp {
			margin-right: #{$i * 5}px !important;
		}
	}
	.u-mb#{$i * 5}--sp {
		@include _sp {
			margin-bottom: #{$i * 5}px !important;
		}
	}
	.u-ml#{$i * 5}--sp {
		@include _sp {
			margin-left: #{$i * 5}px !important;
		}
	}
}
