@charset "utf-8";

// -----------------------------------------------------------------
.c-side-nav {
  $__border: 1px solid $COLOR-BORDER-02;
  background: $COLOR-02;
  border-top: $__border;

  ul {
    @include _sp(){
      border-left: none;
      border-right: none;
    }

    li {
      border-bottom: $__border;
    }


    a {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 40px;
      padding: 9px 15px;
      line-height: $LINE-HEIGHT-160;
      color: $COLOR-01;
      @include _transition-bg-color();
      @include _ie11(){
        padding: 14px 15px 6px;
      }
      @include _sp(){
        min-height: 54px;
        padding: 12px 40px 12px 20px;
        font-size: 16px;
        letter-spacing: $LETTER-SPACING-03;
      }

      &.is-current {
        background: $COLOR-13;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: -1px;
          width: 2px;
          height: 100%;
          border-left: 2px solid $COLOR-06;
          @include _sp(){
            display: none;
          }
        }
      }

      &::after {
        @include _sp(){
          @include _ix-icon(\e908);
          position: absolute;
          top: calc(50% - 7px);
          right: 15px;
          font-size: 14px;
          color: $COLOR-06;
        }
      }

      &:hover {
        background: $COLOR-13;
      }
    }
  }
}


