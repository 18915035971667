@charset "utf-8";

// よくある質問
// -----------------------------------------------------------------
.c-toggle {
  $__border: 1px solid $COLOR-BORDER-05;
  border-bottom: $__border;
  > dt {
    display: flex;
    position: relative;
    padding: 15px 30px 15px 0;
    border-top: $__border;
    background: $COLOR-02;
    cursor: pointer;
    @include _transition-bg-color();
    @include _ie11(){
      padding: 18px 30px 12px 0;
    }

    &::before {
      display: flex;
      @include _ix-icon(\e90d);
      font-size: 26px;
      margin-left: 5px;
      margin-right: 15px;
      @include _sp(){
        margin-left: 15px;
      }
      @include _ie11(){
        position: relative;
        top: -2px;
        min-width: 26px;
        width: 26px;
      }
    }
    &::after {
      position: absolute;
      top: calc(50% - 6px);
      right: 10px;
      @include _ix-icon(\e908);
      font-size: 12px;
      transform: rotate(90deg);
      color: $COLOR-10;
      @include _transition-all();
    }

    &:hover {
      background: $COLOR-13;
      @include _sp(){
        background: none;
      }
      @include _pc(){
        &::after {
          color: $COLOR-06;
        }
      }
    }

    &.is-active {
      &::after {
        color: $COLOR-06;
        transform: rotate(-90deg);
      }
    }
  }

  > dd {
    display: none;
    padding: 30px 50px;
    border-top: $__border;
    background: $COLOR-13;
    @include _sp {
      padding: 30px 20px 30px 50px;
    }
    > * {
      &:not(:last-child){
        margin-bottom: 1em;
      }
    }
  }
}


