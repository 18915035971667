@charset "utf-8";

%__c-note {
  li {
    display: flex;
    line-height: $LINE-HEIGHT-160;
    &:not(:last-child) {
      margin-bottom: 2px;
    }
    @include _sp(){
      letter-spacing: $LETTER-SPACING-03;
      line-height: $LINE-HEIGHT-180;
    }

    > * {
      &:first-child {
        margin-right: 4px;
      }
    }
  }
}

.c-note {
  @extend %__c-note;
}

.c-note--s {
  @extend %__c-note;
  li {
    font-size: 12px;
  }
}

.c-note-list {
  @extend %__c-note;
  margin-top: 8px;
  li {
    font-size: 11px;
    color: #999;
  }

  &._mt-wide {
    margin-top: 50px;
    @include _sp(){
      margin-top: 40px;
    }
  }
}
