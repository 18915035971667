@charset "utf-8";

.c-select {
  font-size: 16px;
  padding: 5px 25px 5px 10px;
  white-space: nowrap;
  position: relative;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #9fa0a0;
  border-radius: 3px;
  appearance: none;
  outline: none;
  background-image: url(/_assets/img/top/i-arrow-down.svg);
  background-repeat: no-repeat;
  background-size: 12px 14px;
  background-position: right 10px top 36%;
  background-color: #fff;
  @include _sp () {
    font-size: 14px;
  }
  &:focus-within {
    box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.4);
  }
}

.c-select-size {
  &--1 {
    width: 420px;
    height: 46px;
    @include _sp () {
      height: 40px;
    }
    .c-select--txt {
      line-height: 46px;
      height: 46px;
      @include _sp () {
        line-height: 40px;
        height: 40px;
      }
    }
  }
  &--2 {
    width: 200px;
    height: 46px;
    @include _sp () {
      height: 40px;
    }
    .c-select--txt {
      line-height: 46px;
      height: 46px;
      @include _sp () {
        line-height: 40px;
        height: 40px;
      }
    }
  }
}

.c-select--arrow {
  &::after {
    content: "";
    background: url("/_assets/img/top/i-arrow-down.svg");
    background-repeat: no-repeat;
    background-size: 12px 14px;
    background-position: right 0px top 36%;
    width: 100%;
    height: 100%;
  }
}