@charset "utf-8";

// コンサルタントからのアドバイス
// -----------------------------------------------------------------
.p-advice {
  display: flex;
  margin-bottom: 80px;
  padding: 20px 30px 20px 40px;
  background: $COLOR-13;
  @include _sp(){
    position: relative;
    margin: 0 -20px 70px;
    padding: 30px 20px;
  }
  &__info {
    width: 810px;
    margin-right: auto;
    dt {
      margin: 10px 0 15px;
      @include _sp(){
        display: flex;
        align-items: center;
        width: calc(100% - 90px);
        min-height: 90px;
        margin: 0 15px 20px 0;

        span {
          display: inline-block;
        }
      }
    }
  }

  &__pct {
    width: 140px;
    @include _sp(){
      position: absolute;
      top: 30px;
      right: 20px;
      width: 90px;
    }
  }
}
