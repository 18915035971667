@charset "utf-8";

.p-flow {
  @include _pc {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__branch {
    @include _pc {
      display: flex;
      justify-content: space-between;
      width: 1054px;
      margin-top: 64px;
    }
    @include _sp {
      margin-top: 45px;
    }
  }

  &__group {
    position: relative;
    z-index: 1;
    @include _pc {
      width: 480px;
    }
    &::before {
      content: '';
      position: absolute;
      top: -34px;
      left: 50%;
      width: 2px;
      height: calc(100% + 68px);
      transform: translateX(-50%);
      background-color: $COLOR-10;
      z-index: -1;
      @include _sp {
        top: 0;
        width: 3px;
        height: calc(100% - 100px);
      }
    }

    & + & {
      @include _sp {
        margin-top: 50px;
      }
    }


    > * {
      &:nth-child(2){
        margin-top: 30px;
      }
      &:nth-child(n+3){
        margin-top: 20px;

        &.p-flow_item4 {
          margin-top: 45px;
        }
      }
    }
  }

  &__merge {
    position: relative;
    @include _pc(){
      margin-top: 64px;
      &::before,
      &::after {
        content: '';
        position: absolute;
        background-color: $COLOR-10;
      }
      &::before {
        top: -31px;
        left: 50%;
        width: 2px;
        height: 31px;
        transform: translateX(-50%);
      }
      &::after {
        left: 0;
        top: -31px;
        width: 100%;
        height: 2px;
      }
    }
    @include _sp(){
      margin-top: 45px;
      &::before {
        content: '';
        position: absolute;
        top: -42px;
        left: 50%;
        width: 3px;
        height: 42px;
        transform: translateX(-50%);
        background: url(/_assets/img/beginner/top/bg_dot_line_02.svg);
        background-size: cover;
      }
    }

    > * + * {
      margin-top: 24px;
      @include _sp(){
        margin-top: 20px;
      }
    }
  }

  &__item {
    position: relative;
    @include _pc(){
      width: 576px;
    }

    &._line1 {
      @include _pc(){
        &::before,
        &::after {
          content: '';
          position: absolute;
          background-color: $COLOR-10;
        }
        &::before {
          bottom: -31px;
          left: 50%;
          width: 2px;
          height: 31px;
          transform: translateX(-50%);
        }
        &::after {
          left: 0;
          bottom: -31px;
          width: 100%;
          height: 2px;
        }
      }
      @include _sp(){
        &::before {
          content: '';
          position: absolute;
          bottom: -42px;
          left: 50%;
          width: 3px;
          height: 42px;
          transform: translateX(-50%);
          background: url(/_assets/img/beginner/top/bg_dot_line_01.svg);
          background-size: cover;
        }
      }
    }

    &._line2 {
      &::before {
        content: '';
        position: absolute;
        top: -24px;
        left: 50%;
        width: 2px;
        height: 24px;
        transform: translateX(-50%);
        background-color: $COLOR-10;
        @include _sp(){
          top: -20px;
          width: 3px;
          height: 20px;
        }
      }
    }
  }

  &.column-3 {
    @include _pc(){
      .p-flow__item._line1::after {
        left: -78px;
        width: calc(100% + 155px);
      }
      .p-flow__group {
        width: 325px;
      }
      .p-flow__merge::after {
        left: -78px;
        width: calc(100% + 155px);
      }
    }
  }
}


.p-flow_item1 {
  padding: 20px 30px 25px;
  border: 8px solid $COLOR-BORDER-03;
  background: $COLOR-02;
  @include _sp {
    padding: 20px;
  }

  &__ttl {
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: $LETTER-SPACING-07;
    line-height: $LINE-HEIGHT-170;
    @include _pc {
      text-align: center;
    }
  }

  &__txt {
    margin-top: 10px;
  }

  &__list {
    margin-top: 15px;
    @include _pc {
      display: flex;
      justify-content: center;
      li + li {
        margin-left: 20px;
      }
    }
    @include _sp {
      li + li {
        margin-top: 10px;
      }
    }
  }
}


.p-flow_item2 {
  padding: 10px 10px 14px;
  font-family: $FONT-FAMILY-NOTO-SERIF;
  font-size: 22px;
  font-weight: normal;
  letter-spacing: $LETTER-SPACING-07;
  text-align: center;
  color: #fff;
  background-color: $COLOR-06;
  @include _sp {
    font-size: 20px;
  }
}


.p-flow_item3 {
  padding: 20px 30px 25px;
  background: $COLOR-13;
  @include _sp {
    padding: 20px;
  }

  &__ttl {
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: $LETTER-SPACING-07;
    line-height: $LINE-HEIGHT-170;
    @include _pc {
      text-align: center;
    }
  }

  &__txt {
    margin-top: 10px;
  }
}


.p-flow_item4 {
  position: relative;
  font-family: $FONT-FAMILY-BOLD;
  font-size: 16px;
  @include _fontbold();
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    top: -45px;
    left: 50%;
    width: 3px;
    height: 42px;
    transform: translateX(-50%);
    background: url(/_assets/img/beginner/top/bg_dot_line_01.svg);
    background-size: cover;
  }
}