@charset "utf-8";

.ix-l-content {
  position: relative;
  width: 100%;
  min-width: $MAX-CONTENT-WIDTH;
  overflow: hidden;
  @include _sp(){
    min-width: initial;
    min-width: auto;
  }


  &__top {
    position: fixed;
    top: 0;
    left: 0;
    min-width: $MAX-CONTENT-WIDTH;
    width: 100%;
    height: 100vh;
    // min-height: 768px;
    background: url(/_assets/img/top/bg_01_2.jpg) no-repeat top center;
    background-size: cover;

    @include _sp(){
      min-width: initial;
      min-height: initial;
      height: 100vh;
      // background: url(/_assets/img/top/bg_01@2x.jpg) no-repeat top center;
      background-size: cover;

      @include _landscape(){
        background-size: cover;
      }
    }
  }

  &__brand {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(/_assets/img/brand/bg_01.jpg) no-repeat top center;
    background-size: cover;
    @include _sp(){
      height: 100vh;
      background: url(/_assets/img/brand/bg_01@2x.jpg) no-repeat top center;
      background-size: cover;

      @include _landscape(){
        background-size: cover;
      }
    }
  }

  &__wrap {
    position: relative;
    z-index: $Z-INDEX-CONTENT;
    margin-bottom: 110px;
    @include _sp(){
      margin-bottom: 80px;
    }

    &._mb0 {
      margin-bottom: 0;
      @include _sp(){
        margin-bottom: 0;
      }
    }
  }

  &__inner {
    position: relative;
    width: $BASE-CONTENT-WIDTH;
    margin: 0 auto;
    @include _sp(){
      width: auto;
      margin: 0 20px;
    }
  }

  &__fig-top {
    $__self: &;
    position: relative;

    .c-fig-1 {
      position: absolute;
      top: $GLOBAL-HEADER-HEIGHT;
      left: 0;
      width: 100%;
      height: 200px;
      background: $COLOR-20;
      @include _sp(){
        top: $GLOBAL-HEADER-HEIGHT-SP;
        height: 66px;
      }
    }

    .c-fig-2 {
      position: absolute;
      top: $GLOBAL-HEADER-HEIGHT;
      left: 0;
      font-size: 380px;
      color: $COLOR-20;
      @include _sp(){
        top: $GLOBAL-HEADER-HEIGHT-SP;
        font-size: 266px;
      }
    }

    .c-fig-2-2 {
      position: absolute;
      top: $GLOBAL-HEADER-HEIGHT;
      left: 0;
      font-size: 380px;
      color: rgba($COLOR-02,.4);
      @include _sp(){
        top: $GLOBAL-HEADER-HEIGHT-SP;
        font-size: 266px;
        color: rgba($COLOR-02,.2);
      }
    }

    .c-fig-2-3 {
      position: absolute;
      top: $GLOBAL-HEADER-HEIGHT;
      left: 0;
      font-size: 380px;
      color: rgba($COLOR-02,.3);
      @include _sp(){
        display: none;
      }
    }

    .c-fig-3 {
      position: absolute;
      top: $GLOBAL-HEADER-HEIGHT;
      left: 0;
      font-size: 200px;
      color: $COLOR-04;
      @include _sp(){
        top: $GLOBAL-HEADER-HEIGHT-SP;
        font-size: 66px;
      }
    }

    .c-fig-4 {
      position: absolute;
      top: $GLOBAL-HEADER-HEIGHT;
      right: 0;
      font-size: 380px;
      color: pink;
      transform: scaleX(-1);
      @include _sp(){
        top: $GLOBAL-HEADER-HEIGHT-SP;
        font-size: 180px;
      }
    }

    //
    $__cat: beginner, guide, recruitment, headhunter, success, jobs;
    @each $key in $__cat {
      .c-fig-#{$key} {
        position: absolute;
        top: $GLOBAL-HEADER-HEIGHT;
        right: -4px;
        width: 380px;
        height: 380px;
        background: url(/_assets/img/#{$key}/top/bg_fig_01.png) no-repeat;
        @include _sp(){
          top: $GLOBAL-HEADER-HEIGHT-SP;
          width: 108px;
          height: 108px;
          background-size: cover;
        }
      }
    }

    $__cat: start, resume, interview, resign;
    @each $key in $__cat {
      .c-fig-guide-#{$key} {
        position: absolute;
        top: $GLOBAL-HEADER-HEIGHT;
        right: 0;
        width: 100%;
        height: 200px;
        background: url(/_assets/img/guide/#{$key}/bg_01.jpg) no-repeat top right;
        @include _sp(){
          display: none;
        }
      }
    }

    .c-fig-headhunter-interview {
      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: $GLOBAL-HEADER-HEIGHT;
        width: 100vw;
        height: 600px;
        transform: scaleX(-1);
        @include _sp(){
          display: none;
        }
      }
      &::before {
        right: 50%;
        background: #e9e3e5;
      }
      &::after {
        left: 50%;
        background: #aeaba9;
      }
    }
  }

  &__fig-bottom {
    position: relative;
    z-index: $Z-INDEX-CONTENT-FIG-BOTTOM;

    .c-fig-1 {
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 360px;
      color: $COLOR-06;
      transform: scaleY(-1);
      @include _sp(){
        font-size: 188px;
      }
    }

    .c-fig-2 {
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 216px;
      color: $COLOR-21;
      transform: scaleY(-1);
      @include _sp(){
        font-size: 112px;
      }
    }
    
    // headhunter
    .c-fig-3 {
      position: absolute;
      bottom: -250px;
      right: 0;
      font-size: 500px;
      color: rgba(#E4E4E4,.3);
      transform: scaleX(-1);
      @include _sp(){
        display: none;
      }
    }
    
    // headhunter
    .c-fig-4 {
      position: absolute;
      bottom: -75px;
      right: 0;
      font-size: 150px;
      color: $COLOR-06;
      transform: scaleX(-1);
      @include _sp(){
        display: none;
      }
    }
  }

  &__fig-brand-top {
    position: relative;
    .c-fig-1 {
      position: absolute;
      top: $GLOBAL-HEADER-HEIGHT;
      left: 0;
      font-size: 200px;
      color: $COLOR-04;
      @include _sp(){
        top: $GLOBAL-HEADER-HEIGHT-SP;
        font-size: 66px;
      }
    }

    .c-fig-2 {
      position: absolute;
      top: $GLOBAL-HEADER-HEIGHT;
      left: calc(50% - 76px);
      font-size: 152px;
      color: rgba($COLOR-04,.3);
      @include _sp(){
        top: $GLOBAL-HEADER-HEIGHT-SP;
        left: calc(50% - 21px);
        font-size: 42px;
      }
    }

    .c-fig-3 {
      position: absolute;
      top: 76px + $GLOBAL-HEADER-HEIGHT;
      left: calc(50% - 50vw);
      font-size: 100vw;
      // color: rgba(green,.05);
      color: #fff;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: calc(50% - 1px);
        width: 100%;
        height: 30vw;
        background: #fff;
        @include _sp {
          height: 480%;
        }
      }

      @include _mid(){
        left: 0;
        font-size: $MAX-CONTENT-WIDTH;
        // color: rgba(red,.2);
        color: #fff;
      }
      @include _sp(){
        top: 21px + $GLOBAL-HEADER-HEIGHT-SP;
        left: calc(50% - 50vw);
        font-size: 100vw;
      }
    }

    .c-fig-4 {
      position: absolute;
      top: calc(76px + 60px + 30vw);
      left: calc(50% - 50vw);
      font-size: 100vw;
      color: #fff;

      @include _mid(){
        left: 0;
        font-size: $MAX-CONTENT-WIDTH;
        // color: rgba(red,.2);
        color: #fff;
      }
      @include _sp(){
        display: none;
      }
    }

  }

}

