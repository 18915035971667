@charset "utf-8";

.p-gird {
  $__self: &;
  display: flex;
  flex-wrap: wrap;
  @include _sp(){
    display: block;
  }

  // &--col-2 {
  //   #{$__self}__col {
  //     width: calc((100% - 30px) / 2);
  //     margin-left: 30px;
  //     &:nth-child(2n+1){
  //       margin-left: 0;
  //     }
  //     &:nth-child(n+3){
  //       margin-top: 30px;
  //     }

  //   }
  // }
  
  &--col-3 {
    #{$__self}__col {
      width: calc((100% - 60px) / 3);
      margin-left: 30px;
      &:nth-child(3n+1){
        margin-left: 0;
      }
      &:nth-child(n+4){
        margin-top: 30px;
      }
    }
  }

  &__col {
    @include _sp(){
      width: auto;
      margin-top: 50px !important;
      margin-left: 0;
    }
  }
}


// -----------------------------------------------------------------
.p-grid-def {

  &__inner {
    display: flex;
    justify-content: center;
    margin-top: 65px;
    @include _sp(){
      display: block;
      margin-top: 60px;
    }
  }

  &__main {
    order: 1;
    width: 800px;
    @include _sp(){
      width: auto;
      margin-bottom: 70px;
    }
  }

  &__aside {
    order: 2;
    width: 220px;
    margin-left: 36px;
    @include _sp(){
      width: auto;
      margin-left: 0;
      margin-bottom: 40px;
    }

    &--side-nav {
      @include _sp(){
        margin-top: 0;
      }
    }
  }

  &__nav {
    @include _sp(){
      margin-left: -20px;
      margin-right: -20px;
    }

    &:not(:last-child){
      margin-bottom: 30px;
      @include _sp(){
        margin-bottom: 35px;
      }
    }

    .c-hd-5 {
      height: 35px;
      font-size: 14px;
      @include _sp(){
        height: auto;
        margin-bottom: 15px;
        font-size: 15px;
        text-align: center;
      }
    }
  }
}


// -----------------------------------------------------------------

.p-grid-def-2 {
  margin-top: 65px;
  
  @include _sp(){
    margin-top: 60px;
  }

  &__inner {
    display: flex;
    justify-content: center;
    margin-top: 65px;
    @include _sp(){
      display: flex;
      flex-direction: column-reverse;
      margin-top: 30px;
    }
  }

  &__main {
    order: 1;
    width: 800px;
    @include _sp(){
      width: auto;
      margin-bottom: 0;
    }
  }

  &__aside {
    order: 2;
    width: 220px;
    margin-left: 36px;
    margin-top: calc((14px * 2 + 70px + 28px) * -1);
    @include _sp(){
      width: auto;
      margin: 0 0 40px 0;
    }

    &--side-nav {
      @include _sp(){
        margin-top: 0;
      }
    }
  }
}