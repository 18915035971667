@charset "utf-8";

// スライダー
// -----------------------------------------------------------------
.p-slider {
  padding: 0 0 60px;
  @include _sp {
    padding-bottom: 50px;
  }

  &__ttl {
    padding: 40px 0;
    text-align: center;
    .c-hd-4 {
      font-size: 24px;
      @include _sp(){
        font-family: $FONT-FAMILY-NOTO-SERIF;
        font-size: 20px;
        font-weight: normal;
      }
    }
  }
}