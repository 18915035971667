@charset "utf-8";

%_c-list-decimal {
  li {
    list-style-position: outside;
    list-style-type: decimal;
  }
}

%_c-list-disc {
  li {
    list-style-position: outside;
    list-style-type: disc;
  }
}


// -----------------------------------------------------------------
.c-list--decimal {
  @extend %_c-list-decimal;
  padding-left: 1.25em;
  li {
    &:not(:last-child){
      margin-bottom: 5px;
    }
  }
}


// -----------------------------------------------------------------
.c-list--decimal-2 {
  @extend %_c-list-decimal;
  > li {
    &:not(:last-child) {
      margin-bottom: 2em;
    }

    > * {
      margin-top: 1em;
      margin-left: 1em;
    }
  }
}


// -----------------------------------------------------------------
.c-list--decimal-no-mb {
  @extend %_c-list-decimal;
  padding-left: 1.25em;
}

// -----------------------------------------------------------------
.c-list--disc {
  @extend %_c-list-disc;
  padding-left: 1.25em;
  li {
    &:not(:last-child){
      margin-bottom: 5px;
    }
  }
}


// -----------------------------------------------------------------
.c-list--disc-2 {
  @extend %_c-list-disc;
  > li {
    > * {
      margin-top: 1em;
      margin-left: 1em;
    }
  }
}


// -----------------------------------------------------------------
.c-list--file {
  > li {
    border-bottom: 1px solid $COLOR-BORDER-04;
    a {
      display: flex;
      position: relative;
      height: 100%;
      padding: 12px 0 12px 2.15em;
      color: $COLOR-01;
      @include _transition-bg-color();
      @include _ie11(){
        padding: 14px 0 10px 2.15em;
      }
      @include _sp(){
        padding: 12px 20px 12px calc(2.15em + 20px);
      }
      &::before {
        @include _ix-icon(\e90c);
        position: absolute;
        top: 12px + 3px;
        left: 0;
        font-size: 20px;
        color: rgba($COLOR-22,.5);
        @include _sp(){
          left: 20px;
        }
      }

      &:hover {
        background: $COLOR-13;
      }
    }
  }
}

