@charset "utf-8";

.c-logo-footer {
  $__self: &;
  a {
    #{$__self}__pc {
      display: flex;
      align-items: flex-end;

      #{$__self}__icn {
        font-size: 48px;
      }

      #{$__self}__txt {
        margin-left: 20px;
        font-size: 12px;
        line-height: 1.6;
      }
    }
  }
}
