@charset "utf-8";


// -----------------------------------------------------------------
.p-top-ix {
  display: flex;
  height: 100%;
  align-items: flex-end;
  // padding-top: 120px;
  // min-height: 768px;
  // height: 100vh;

  @include _sp(){
    display: block;
    // padding-top: 70px;
    // padding-bottom: 60px;
    padding-top: 49px;
    padding-bottom: 0;
  }

  &__logo {
    width: 400px;
    margin: 0 auto;
    text-align: center;
    img {
      width: 100%;
      height: auto;
    }
    @include _pc {
      display: none;
    }
    @include _sp(){
      width: auto;
    }
  }

  &__news {
    display: flex;
    width: 780px;
    height: 56px;
    margin: 0 auto 40px;
    @include _sp(){
      width: auto;
      height: auto;
      margin-top: -10px;
      justify-content: center;
    }

    &-col-1 {
      width: 700px;
      background: rgba(#fff,.85);
      @include _sp(){
        width: auto;
      }
    }

    &-col-2 {
      width: 82px;
      margin-left: 1px;
      background: rgba(#fff,.85);
      @include _sp(){
        min-width: 64px;
        width: 64px;
      }
    }
  }

  .p-top-ix__cv {
    margin: 0 auto 40px;
    // width: 1056px;
    @include _sp(){
      display: none;
    }
    &-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 68px;
      padding: 0 40px;
      background: rgba(0,0,0,.7);
    }

    &-txt {
      margin-right: 40px;
      font-family: $FONT-FAMILY-NOTO-SERIF;
      font-size: 18px;
      color: $COLOR-02;
    }
  }
}

.p-top_mv {
  position: relative;
  padding-top: $GLOBAL-HEADER-HEIGHT;
  @include _sp(){
    padding-top: $GLOBAL-HEADER-HEIGHT-SP;
  }

  // &__pct00 {
  //   @include _pc(1200px){
  //     img {
  //       width: 100%;
  //       height: 100%;
  //       object-fit: cover;
  //     }
  //   }
  // }

  &__pct {
    @include _pc(){
      height: 448px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    @include _pc(2000px){
      height: auto;
    }
  }

  &__links {
    position: absolute;
    inset: 0;

    a {
      position: absolute;
      display: block;
    }

    ._link1 {
      bottom: 202px;
      left: 845px;
      width: 279px;
      height: 61px;
      @include _pc(1200px){
        left: calc(50% + 246px);
      }
      @include _pc(2000px){
        bottom: calc(199/2000*100vw);
        left: calc(50% + 240/2000*100vw);
        width: calc(277/2000*100vw);
        height: calc(61/2000*100vw);
      }
      @include _sp {
        bottom: calc(46/375*100vw);
        left: calc(63/375*100vw);
        width: calc(249/375*100vw);
        height: calc(55/375*100vw);
      }
    }

    ._link2 {
      bottom: 168px;
      left: 890px;
      width: 193px;
      height: 16px;
      @include _pc(1200px){
        left: calc(50% + 291px);
      }
      @include _pc(2000px){
        bottom: calc(164/2000*100vw);
        left: calc(50% + 286/2000*100vw);
        width: calc(190/2000*100vw);
        height: calc(18/2000*100vw);
      }
      @include _sp {
        bottom: calc(21/375*100vw);
        left: calc(103/375*100vw);
        width: calc(172/375*100vw);
        height: calc(14/375*100vw);
      }
    }
  }
}

// -----------------------------------------------------------------

.p-top-job-search {
  background: #fff;
  padding: 25px 40px 40px;
  // width: 960px;
  width: 1056px;
  margin: 0 auto;
  @include _sp () {
    width: 100%;
    padding: 15px 20px 30px;
  }
  &__ttl {
    padding-bottom: 20px;
    font-family: "Noto Serif JP",serif;
    font-size: 28px;
    text-align: center;
    @include _sp () {
      font-size: 24px;
      line-height: 1.6;
    }
  }
  &__error-area {
    width: 650px;
    font-size: 14px;
    letter-spacing: 0.05rem;
    color: #b81c22;
    border: solid 2px #b81c22;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: -5px auto 20px;
    @include _sp () {
      width: 100%;
    }
    &-icon {
      position: relative;
      padding-left: 30px;
      line-height: 24px;
      @include _sp() {
        line-height: 22px;
      }
      &::before {
        content: '\E90E';
        font-family: 'sharp-icon' !important;
        font-size: 22px;
        height: 30px;
        line-height: 30px;
        font-weight: normal;
        display: block;
        position: absolute;
        left: 0;
        top: -4px;
        color: #b81c22;
      }
    }
  }
  &__content {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @include _sp () {
      display: block;
      text-align: center;
    }
  }
  &__form-group {
    display: flex;
    flex-direction: column;
    position: relative;
    .c-select:focus {
      outline: none;
    }
    .dropdown-toggle {
      text-align: left;
      box-sizing: border-box;
    }
    .dropdown-toggle::after {
      display: none;
    }
    .c-select--txt {
      white-space: normal;
      overflow: hidden;
      color: #222;
    }
    .dropdown-menu {
      padding: 0;
      height: 349px;
      overflow-y: auto;
      overflow-x: hidden;
      border: 1px solid #9FA0A0;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      float: left;
      min-width: 10rem;
      color: #212529;
      text-align: left;
      background-color: #fff;
      background-clip: padding-box;
      border-radius: 0.25rem;

      &.show {
        display: block;
      }
      &.job-type {
        width: 420px;
        @include _sp () {
          width: 100%;
        }
      }
      &.desired-prefecture {
        width: 200px;
        @include _sp () {
          width: 100%;
        }
      }
    }
    .dropdown-item {
      cursor: pointer;
      font-size: 14px;
      padding: 0.05rem 0.6rem;
      letter-spacing: 0;
      display: block;
      width: 100%;
      clear: both;
      font-weight: 400;
      color: #212529;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border: 0;
      &:hover {
        background-color: #E8F8FF;
      }
      &:active {
        color: #212529;
      }
      @include _sp () {
        white-space: normal;
      }
    }
    @include _sp () {
      .c-select {
        width: 100%;
      }
      &-ttl {
        display: none;
      }
    }
  }
  &__cross {
    font-size: 53px;
    font-weight: bold;
    line-height: 0.8;
    @include _sp () {
      display: none;
    }
  }
  &__button {
    width: 180px;
    height: 46px;
    font-size: 16px;
    // background: #0068b7;
    background: #142F71;
    border: none;
    outline: none;
    color: #ffffff;
    border-radius: 3px;
    appearance: none;
    @include _sp () {
      width: calc(100% - 60px);
      margin-top: 20px;
    }
    &:hover {
      background: #5185c5 !important;
    }
  }
}


// -----------------------------------------------------------------
.p-top-news {
  display: grid;
  row-gap: 12px;
}


// -----------------------------------------------------------------
.p-top-brand {
  width: 550px;
  margin: 0 auto 120px;
  padding-top: 60px;
  color: #fff;
  @include _sp(){
    width: auto;
    margin: 0 50px 90px;
    padding-top: 50px;
  }

  &__ttl {
  }

  &__lede {
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-size: 16px;
    letter-spacing: $LETTER-SPACING-07;
    line-height: 2.5;
    @include _sp(){
      font-size: 15px;
      line-height: 2.1;
    }
  }

  &__btn {
    margin: 0 auto;
    text-align: center;
  }
}


// -----------------------------------------------------------------
.p-top-brand-reason {
  $__self: &;
  $__border: 1px solid rgba(#fff,.4);
  color: #fff;

  &__ttl {
  }

  &__body {
    display: grid;
    row-gap: 30px;
    @include _pc {
      grid-template-columns: 1fr 1fr;
      column-gap: 28px;
      row-gap: 28px;
    }
  }

  &__item {
    padding: 35px 40px;
    border: $__border;
    background-color: rgba(#000, .4);
    @include _sp {
      padding: 35px 17px;
    }
  }

  &__ttl {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-weight: normal;
    line-height: $LINE-HEIGHT-150;
    text-align: center;
    &::after {
      content: '';
      display: block;
      width: 90px;
      height: 2px;
      margin: 20px auto 0;
      background: linear-gradient(90deg, #392C19, #E4D38A, #392C19);
    }

    &__sub {
      font-size: 16px;
      @include _sp {
        font-size: 13px;
      }
      @include _sp(374px) {
        font-size: calc(13/375*100vw);
      }
    }

    &__main {
      font-size: 26px;
      @include _sp {
        margin: 0 -12px;
        font-size: 21px;
      }
      @include _sp(374px) {
        font-size: calc(21/375*100vw);
      }
    }
  }

  &__info {
    margin-top: 30px;
    @include _sp {
      margin-top: 25px;
    }
  }

  &__catch {
    font-size: 20px;
    font-weight: bold;
    line-height: $LINE-HEIGHT-150;
    text-align: center;
    color: #B99969;
    @include _sp {
      font-size: 16px;
    }
    @include _sp(374px) {
      font-size: calc(16/375*100vw);
    }

    + #{$__self}__txt {
      margin-top: 20px;
    }
  }

  &__txt {
  }

  &__notice {
    display: flex;
    align-items: center;
    width: max-content;
    height: 24px;
    margin: 20px auto 0;
    padding: 0 13px;
    font-size: 13px;
    background-color: #494032;
  }

  &__note {
    font-size: 12px;
    line-height: $LINE-HEIGHT-160;
    color: #9FA0A0;
  }

  &__btn {
    width: max-content;
    margin: 40px auto 0;
    @include _sp {
      width: auto;
      max-width: 320px;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 320px;
      height: 46px;
      font-size: 16px;
      text-decoration: none;
      color: #fff;
      border: 1px solid #999;
      border-radius: 3px;
      background-color: #333;
      @include _transition-bg-color;
      &:hover {
        background-color: #111;
      }
      @include _sp {
        width: 100%;
      }
    }

    &__txt {
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        display: inline-block;
        @include _ix-icon(\e90b);
        margin-left: 10px;
        font-size: 11px;
        text-decoration: none;
        color: $COLOR-02;
      }
    }

    &__pay {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 43px;
      height: 20px;
      margin-left: 8px;
      font-size: 13px;
      border: 1px solid #fff;
      border-radius: 3px;
    }
  }
}

// -----------------------------------------------------------------
.p-top-recruitment {
  &__inner {
    @include _pc {
      padding: 48px 38px 44px;
      background-color: rgba(#fff, .4);
    }
  }

  &__head {
    display: grid;
    row-gap: 20px;
    @include _sp() {
      row-gap: 30px;
    }

    .c-hd-2-top {
      margin-bottom: 0;
      font-size: 27px;
      @include _sp() {
        font-size: 22px;
      }
      @include _sp(374px) {
        font-size: calc(22/375*100vw);
      }
    }

    &__lead {
      font-size: 14px;
      text-align: center;
      @include _sp() {
        text-align: left;
      }
    }

    &__note {
      font-size: 12px;
    }
  }

  &__body {
    display: grid;
    row-gap: 20px;
    margin-top: 30px;
    @include _pc {
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
      margin-top: 48px;
    }
    &._pc-full {
      @include _pc {
        display: block;

        .p-top-recruitment__item {
          background-color: transparent;

          .p-top-recruitment__pct {
            padding: 0;
            text-align: center;

            img {
              width: 800px;
            }
          }
        }
      }
    }
  }

  &__item {
    background-color: rgba(#fff,.5);
  }

  &__ttl {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding: 20px 10px;
    font-weight: bold;
    line-height: $LINE-HEIGHT-150;
    text-align: center;
    color: #fff;
    background-color: #142F71;
    @include _sp() {
      padding: 15px 10px;
    }

    &__sub {
      font-size: 14px;
      @include _sp() {
        font-size: 13px;
      }
    }

    &__main {
      font-size: 20px;
      @include _sp() {
        font-size: 18px;
        letter-spacing: normal;
      }
    }
  }

  &__pct {
    padding: 30px 36px 44px;
    @include _sp() {
      padding: 25px 15px;
      display: flex;
      justify-content: center;
    }
  }

  &__btn {
    @include _pc {
      .c-button--center {
        margin-top: 48px;
      }
    }
  }
}


// -----------------------------------------------------------------
// .p-top-headhunter-success-wrap {
//   display: flex;
//   flex-direction: column;
//   height: 1506px;
// }


// -----------------------------------------------------------------
.p-top-headhunter {
  padding: 70px 0;
  @include _sp(){
    margin: 0 20px;
    padding: 70px 0 60px;
  }

  &__ttl {
    color: $COLOR-02;
    // @include _sp(){
    //   color: $COLOR-02;
    // }
  }

  &__lede {
    margin-bottom: 60px;
    text-align: center;
    color: $COLOR-02;
    @include _sp(){
      margin-bottom: 40px;
      text-align: left;
    }
  }

  &__wrap {
    @include _sp(){
      // .p-hunter-list__item {
      //   &:not(:last-child){
      //     margin-bottom: 10px;
      //   }
      // }
    }
  }
}


// -----------------------------------------------------------------
.p-top-success {
  padding-bottom: 100px;
  &::before {
    display: none;
    @include _ix-icon(\e900);
    position: absolute;
    top: 0;
    left: 0;
    font-size: 66px;
    color: $COLOR-06;
  }

  @include _sp(){
    position: relative;
    padding: 60px 0;
    background: $COLOR-12;
    &::before {
      display: block;
    }
  }

  &__ttl {
    color: #fff;
    @include _sp(){
      color: $COLOR-01;
    }
  }

  &__wrap {
    .p-slider__wrap {
      display: flex;
      @include _sp(){
        display: block;
        margin: 0 20px;
      }

      > * {
        width: 333px;
        @include _sp(){
          width: auto;
        }
        &:not(:last-child) {
          margin-right: 29px;
          @include _sp(){
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.p-top-success-2 {
  padding: 80px 0 90px;

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include _sp(){
      display: block;
      margin: 0 10px;
    }
  }

  &__item {
    width: calc(50% - 15px);
    @include _pc(){
      &:nth-child(n+3){
        margin-top: 40px;
      }
    }
    @include _sp(){
      width: 100%;
      margin-top: 20px;
    }
  }
}


// -----------------------------------------------------------------
.p-top-case {
  padding: 90px 0;
  @include _sp(){
    margin: 0 20px;
    padding: 60px 0;
  }

  &__ttl {
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: 187px;
    margin-right: 30px;

    &:nth-child(5n){
      margin-right: 0;
      // @include _sp(){
      //   margin-right: auto;
      // }
    }
    &:nth-child(n+6){
      margin-top: 30px;
      // @include _sp(){
      //   margin-top: auto;
      // }
    }

    @include _sp(){
      width: calc(50% - 10px);
      margin-right: 20px;
      &:nth-child(even){
       margin-right: 0;
      }
      &:nth-child(n+3){
        margin-top: 30px;
      }
    }
  }
}


// -----------------------------------------------------------------
.p-top-jobs {
  padding: 80px 0;
  @include _sp(){
    margin: 0 20px;
    padding: 60px 0;
  }


  &__ttl {
    margin-bottom: -10px;
    @include _sp(){
    }
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    @include _sp(){
      display: block;
    }
  }

  &__item {
    @include _pc(){
      width: calc(25% - 20px * 3/4);
      &:not(:nth-child(4n)){
        margin-right: 20px;
      }
      &:nth-child(n+5){
        margin-top: 20px;
      }
    }

    @include _sp(){
      &:not(:last-child){
        margin-bottom: 10px;
      }
    }
  }

  &__nav {
    margin-top: 40px;
  }

  &__note {
    margin-top: 15px;
    font-size: 11px;
    color: #555;
  }
}

.p-top-jobs-record {
  + * {
    margin-top: 80px;
    @include _sp(){
      margin-top: 60px;
    }
  }

  &__ttl {
    margin-bottom: 40px;
    text-align: center;
    @include _sp(){
    }

    .c-hd-3 {
      font-size: 20px;
      @include _sp(){
        font-size: 24px;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    background: $COLOR-02;
    @include _sp(){
      padding: 20px 10px;
    }

    li {
      @include _pc(){
        width: calc(20% - (10px * 4/5));
        &:not(:nth-child(5n)){
          margin-right: 10px;
        }
        &:nth-child(n+6){
          margin-top: 10px;
        }
      }
      @include _sp(){
        width: calc(50% - 5px);
        &:not(:nth-child(2n)){
          margin-right: 10px;
        }
        &:nth-child(n+3){
          margin-top: 10px;
        }
      }
    }
  }
}

.p-top-jobs-nav {
  padding: 30px 40px 40px;
  background: $COLOR-02;
  @include _sp(){
    padding: 10px 0 0;
  }

  .c-hd-6 {
    margin-bottom: 25px;
    text-align: center;
    @include _sp(){
      margin: 12px 0;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    @include _sp(){
      display: block;
    }

    li {
      border-bottom: 1px solid $COLOR-BORDER-04;
      @include _pc(){
        width: calc(25% - 20px * 3/4);
        &:nth-child(-n+4){
          border-top: 1px solid $COLOR-BORDER-04;
        }
        &:not(:nth-child(4n)){
          margin-right: 20px;
        }
      }
      @include _sp(){
        border-top: 1px solid $COLOR-BORDER-04;
        border-bottom: none;
      }
    }

    a {
      display: flex;
      height: 100%;
      padding: 12px 5px;
      font-size: 13px;
      color: $COLOR-01;
      @include _transition-bg-color();
      @include _ie11(){
        padding: 14px 5px 10px;
      }
      @include _pc(){
        &:hover {
          background: $COLOR-13;
        }
      }
      @include _sp(){
        padding: 14px 15px 10px 20px;
        font-size: 14px;
        &::after {
          @include _ix-icon(\e908);
          display: flex;
          align-items: center;
          margin-left: auto;
          font-size: 12px;
          color: $COLOR-06;
        }
      }

    }
  }
}


// -----------------------------------------------------------------
.p-top-guide {
  $__border: 8px solid $COLOR-13;

  position: relative;
  padding: 80px 0;
  
  @include _sp(){
    padding: 0;
    background: $COLOR-02;
  }

  &__bg {
    @include _sp(){
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      // height: 234px;
      height: 225px;
      background: url(/_assets/img/top/bg_guide_01.jpg) no-repeat top center;
      background-size: cover;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000,.1);
      }
    }
  }

  &__inner {
    position: relative;
    @include _sp(){
      padding: 60px 0;
    }

    &::before {
      display: none;
      @include _ix-icon(\e900);
      position: absolute;
      top: 0;
      left: 0;
      font-size: 66px;
      color: $COLOR-06;
      @include _sp(){
        display: block;
      }
    }

  }

  &__ttl {
  }

  &__wrap {
    margin: 0 20px;
  }

  &__group-1 {
    display: flex;
    margin-bottom: 25px;
    @include _sp(){
      display: block;
      margin-bottom: 0;
    }

    > * {
      width: 50%;
      &:first-child {
        margin-right: 15px;
      }

      &:last-child {
        margin-left: 15px;
      }
      @include _sp(){
        width: auto;
        margin: 0;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 74px;
      font-size: 16px;
      color: $COLOR-01;
      background: $COLOR-02;
      @include _transition-bg-color;
      @include _ie11(){
        padding-top: 4px;
      }
      @include _sp(){
        justify-content: flex-start;
        height: 58px + 16px;
        padding-left: 24px;
        border: $__border;
        border-bottom: none;
      }
      &:hover {
        background: $COLOR-13;
      }

      i {
        margin-right: 10px;
        font-size: 13px;
        color: $COLOR-06;
        @include _ie11(){
          position: relative;
          top: -4px;
        }
      }
    }
  }

  &__group-2 {
    padding: 30px 40px 40px;
    background: $COLOR-02;
    @include _sp(){
      padding: 0;
      border: $__border;
    }

    .c-hd-6 {
      margin-bottom: 25px;
      text-align: center;
      @include _sp(){
        margin: 12px 0;
      }
    }

    .c-list--file {
      display: flex;
      flex-wrap: wrap;
      @include _sp(){
        display: block;
        border-top: 1px solid $COLOR-BORDER-04;
      }

      > li {
        width: calc((99.9% - 60px) / 4);
        // width: 229px;
        margin-left: 20px;
        &:nth-child(4n+1){
          margin-left: 0;
        }
        &:nth-child(-n+4){
          border-top: 1px solid $COLOR-BORDER-04;
        }
        @include _sp(){
          width: auto;
          margin-left: 0;
          border-top: none;
          &:last-child {
            border-bottom: none;
          }
        }

        a {
          font-size: 13px;
          letter-spacing: normal;
          @include _ie11(){
            padding-top: 16px;
          }
          @include _sp(){
            font-size: 14px;
          }

          &::before {
            @include _ie11(){
            }
          }
        }
      }
    }
  }

  &__group-3 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    @include _sp(){
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 0;
      padding: 10px 10px 0;
      background: $COLOR-13;
    }

    > * {
      + * {
        @include _pc(){
          margin-left: 10px;
        }
        @include _sp(){
          margin-top: 10px;
        }
      }
    }
  }

  &__btn {
    @include _sp(){
      margin-top: -10px;
    }
  }
}


// -----------------------------------------------------------------
.p-top-help {
  padding: 80px 0 40px;
  @include _sp(){
    padding: 0 0 30px;
  }

  &__ttl {
    margin-bottom: -10px;
    @include _sp(){
      margin-bottom: -25px;
    }
  }

  &__wrap {
    margin-bottom: 30px;
    padding-bottom: 1px;
    @include _sp(){
      margin-bottom: 25px;
    }
  }

  &__btn {
    @include _sp(){
      margin-top: -10px;
    }
  }
}

/*転職体験談TOP202009*/

.ix-l-section-top-experiences{
  background: #fff;
  position: relative;

  .c-fig-experiences{
  content: "\e900";
  position: absolute;
  top: 0;
  left: 0;
  font-size: 66px;
  color: #0068B7;
  }
}

.p-top-experiences{
  padding: 80px 0;

  @include _sp(){
    padding-top: 70px;
    margin: 0 20px;
  }
}

.c-hd-experiences-top{
  margin-bottom: 50px;
  font-family: "Noto Serif JP",serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 1.8;
  text-align: center;

  @include _sp(){
    font-size: 24px;
  }

}

.p-top-experiences__lead{
  margin-bottom: 53px;
  text-align: center;

  @include _sp(){
    margin-bottom: 40px;
    text-align: left;
    padding: 0 2.5px;
  }
}

.p-top-experiences-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__item{
    width: 510px;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    position: relative;
    margin-bottom: 30px;

    &:hover{
      background: #f9f9f9;
      box-shadow: 1px 3px 18px rgba(30,30,30,.16);
      transition:all .5s ease 0s;
    }

    &:nth-child(odd){
      margin-right: 35px;
    }

    &:nth-child(n+3){
      margin-bottom: 0px;
    }

    

    @include _sp(){
      margin-bottom:
       20px;
        &:nth-child(3){
          margin-bottom: 20px;
        }
        &:last-child{
          margin-bottom: 0px;
        }

        &:nth-child(odd){
          margin-right: 0px;
        }
      }
    }

  &__link{
    padding: 25px 18px 20px 20px;
    display: block;
    height: 100%;

    @include _sp(){
      padding: 20px 15px;
    }
  }

  &__wrap{
    display: flex;
  }

  &__icon{
    width: 56px;
    margin-right: 25px;
    flex-shrink: 0;
    text-align: center;

    @include _sp(){
      width: 48px;
      margin-right: 10px;
    }
  }

  &__img{
    margin-bottom: 8px;
  }

  &__age{
    font-size:11px ;
    letter-spacing: 0;
    font-weight: bold;
    color: #222222;
    line-height: 1.81;

    @include _sp(){
      line-height: 1.63;
    }
  }

  &__text{
  color: #222222;
  font-weight: bold;
  }

  &__ttl{
    line-height: 1.75;
    letter-spacing: -0.75px;
    margin-bottom: 4px;

    @include _sp(){
      line-height: 1.5;
      margin-bottom: 3px;
    }
  }

  &__body{
    line-height: 1.6;

    @include _sp(){
      line-height: 1.5;
    }
  }

  &__label{
    background: #A37F47;
    padding: 1px 4px;
    color: #fff;
    font-size: 12px;
    font-weight: normal;

    @include _sp(){
      font-size: 13px;
    }
  }

  &__company{
    margin: 0 15px 0 10px;
    font-size: 13px;

    @include _sp(){
      margin: 0 10px 0 5px;
    }
  }

  &__position{
    font-size: 13px;
  }

  .c-fig{
    position: absolute;
    top: 0;
    left: 0;
    font-size: 40px;
    color: #b99969;
  }
}

.p-top-experiences-list__nakaguro{
  @include _sp(){
    display: none;
  }
}

.p-top-brand-cta {
  display: flex;
  justify-content: center;
  align-items: center;

  @include _sp {
    flex-direction: column;
  }

  &__item {
    padding: 20px;
    background-color: $COLOR-02;
    color: $COLOR-01;
    transition: all .5s ease 0s;
    border: 1px solid $COLOR-11;
    @include _pc {
      height: 356px;
    }
    &:first-child {
      @include _pc {
        margin-right: 46px;
      }
      @include _sp {
        margin-bottom: 20px;
      }
    }
    @include _sp {
      padding: 15px 15px 30px;
    }
    &:hover {
      cursor: pointer;
      background: #f9f9f9;
      &:active {
        color: initial;
      }
      .p-top-brand-cta__img--1,
      .p-top-brand-cta__img--2 {
        opacity: .8;
      }
    }
    &:focus {
      outline: none;
    }
  }

  &__img {
    &--1,
    &--2 {
      display: block;
      text-decoration: none;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      @include _pc {
        width: 470px;
        height: 210px;
      }
      @include _sp {
        height: 136px;
      }
      @include _transition-opacity;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0);
        transition: background-color .3s ease 0s;
      }
    }
    &--1 {
      background-image: url('/_assets/img/top/cta_brand_bg_2.jpg');
    }
    &--2 {
      background-image: url('/_assets/img/top/cta_brand_bg_4.jpg');
    }
  }
  &__ttl {
    display: flex;
    justify-content: center;
    padding: 40px 0;
    align-items: center;
    height: 100%;
    @include _sp {
      margin: 0 auto;
      padding: 52px 0;
    }
    img {
      filter: drop-shadow(0 -3px 0px rgba(255, 255, 255, 0.3));
      width: 340px;
      @include _sp {
        width: 220px;
      }
    }
  }

  &__detail {
    margin-top: 20px;
  }
}

