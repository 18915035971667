@charset "utf-8";

.p-lede {
  padding: 65px 0 70px;
  @include _sp() {
    padding: 60px 0 50px;
  }
}

.p-lede-2 {
  padding: 65px 0 50px;
  @include _sp() {
    padding: 60px 0 40px;
  }
}



// -----------------------------------------------------------------
.p-lede-brand {
  font-family: $FONT-FAMILY-NOTO-SERIF;
  font-size: 16px;
  letter-spacing: .1em;
  line-height: 2.625;
  text-align: center;
  @include _sp(){
    font-size: 15px;
    letter-spacing: .05em;
    line-height: 2.26;
    text-align: left;
  }
}


// -----------------------------------------------------------------
.p-lede-job {
  margin-bottom: 70px;
  @include _sp() {
    margin-bottom: 30px;
  }
}

.p-lede-job-2 {
  margin-bottom: 70px;
  width: 800px;
  @include _sp() {
    margin-bottom: 30px;
    width: 100%;
  }
}