@charset "utf-8";

.ix-l-section {
  position: relative;
  width: 100%;
  min-width: $MAX-CONTENT-WIDTH;
  overflow: hidden;
  @include _sp(){
    min-width: initial;
    min-width: auto;
  }
  
  &--slider {
    background: $COLOR-12;
  }

  &__inner {
    position: relative;
    width: $BASE-CONTENT-WIDTH;
    margin: 0 auto;
    @include _sp(){
      width: auto;
    }
  }
  
  &__narrow {
    position: relative;
    width: $NARROW-CONTENT-WIDTH;
    margin: 0 auto;
    @include _sp(){
      width: auto;
    }
  }
  
  &__block {
    // &:not(:last-child){
      margin-bottom: 60px;
    // }
    @include _sp(){
      margin-bottom: 50px;
    }
  }
}


// -----------------------------------------------------------------
.ix-l-section-top {
  &__inner {
    position: relative;
    width: $BASE-CONTENT-WIDTH;
    margin: 0 auto;
    @include _sp(){
      width: auto;
    }
  }
}

.ix-l-section-top-ix {
  height: 690px;
  background: url(/_assets/img/top/bg_02.png) no-repeat bottom center,
              url(/_assets/img/top/bg_01_3.png) no-repeat top center;
  background-size: 100% auto,cover;
  padding: 60px 0;
  @include _sp(){
    min-height: initial;
    min-height: auto;
    height: auto;
    background: url(/_assets/img/top/bg_02@2x.png) no-repeat bottom center;
    // url(/_assets/img/top/bg_01@2x.jpg) no-repeat top center;
    background-size: 100% auto , cover;
    padding: 0;
  }

  @include _max(){
    // min-height: 780px;
    // max-height: 1000px;
  }
}

.ix-l-section-top-record-list {
  background-color: #fff;
  padding: 10px 0;
  display: flex;
  flex-flow: row nowrap;
  @include _sp () {
    padding: 5px 0;
  }
}

.ix-l-section-top-job-search {
  padding: 80px 0 60px;
  background-color: #F5F5F5;

  // background: #000;
  // padding: 60px 0;
  // position: relative;
  // z-index: 1;
  // @include _sp () {
  //   padding: 40px 20px;
  // }
  // &::before {
  //   content: '';
  //   height: 100%;
  //   top: 0;
  //   position: absolute;
  //   width: 170px;
  //   background-color: #b99969;
  //   right: -174px;
  //   z-index: -1;
  //   transform: skewX(-45deg) translateX(-50%);
  //   @include _sp () {
  //     width: 90px;
  //     right: -185px;
  //   }
  // }
  // &::after {
  //   content: '';
  //   height: 100%;
  //   top: 0;
  //   position: absolute;
  //   width: 170px;
  //   background-color: #fff;
  //   right: -344px;
  //   z-index: -1;
  //   transform: skewX(-45deg) translateX(-50%);
  //   @include _sp () {
  //     width: 50px;
  //     right: -214px;
  //   }
  // }
}

.ix-l-section-top-news {
  padding: 15px 0;
  background-color: #F5F5F5;
  @include _sp(){
    padding: 16px 20px;
  }
}

.ix-l-section-top-brand {
  padding: 80px 0 100px;
  background: #fff url(/_assets/img/top/bg_brand_01_3.svg) no-repeat center;
  background-size: cover;

  @include _sp(){
    padding: 60px 20px;
    background: #fff url(/_assets/img/top/bg_brand_01_3@2x.svg) no-repeat top center;
    background-size: cover;
  }
}

.ix-l-section-top-recruitment {
  padding: 90px 0 65px;
  background: #DBD7D0;
  @include _sp(){
    padding: 60px 20px 50px;
  }
}

.ix-l-section-top-headhunter {
  background: #151515 url(/_assets/img/top/bg_headhunter_01.jpg) no-repeat center;
  background-size: cover;
  @include _sp(){
    position: relative;
    background: #000 url(/_assets/img/top/bg_headhunter_01@2x.jpg) no-repeat top center;
    background-size: 100% auto;
    &::before {
      @include _ix-icon(\e900);
      position: absolute;
      top: 0;
      left: 0;
      font-size: 66px;
      color: $COLOR-06;
    }
  }
}

.ix-l-section-top-case {
  background: $COLOR-02;
  @include _sp(){
    position: relative;
    &::before {
      @include _ix-icon(\e900);
      position: absolute;
      top: 0;
      left: 0;
      font-size: 66px;
      color: $COLOR-04;
    }
  }
}

.ix-l-section-top-jobs {
  background: $COLOR-13;
  @include _sp(){
    position: relative;
    &::before {
      @include _ix-icon(\e900);
      position: absolute;
      top: 0;
      left: 0;
      font-size: 66px;
      color: $COLOR-04;
    }
  }
}

.ix-l-section-top-guide {
  position: relative;
  background: url(/_assets/img/top/bg_guide_01.jpg) no-repeat top center;
  background-size: cover;
  @include _sp(){
    background: none;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000,.1);
    @include _sp(){
      display: none;
    }
  }
}

.ix-l-section-top-help {
  background: $COLOR-02;
}

.ix-l-section-top-cta {
  position: relative;
  padding: 100px 0;
  margin-bottom: -110px;
  background: rgba(#000,.1);
  @include _sp(){
    margin: 0 0 -80px;
    padding: 20px 20px 90px;
  }

  .p-cta {
    margin-top: 0;
  }
}

.ix-l-section-top-cta2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 170px;
  background-color: #142F71;

  &._bg_balck {
    background-color: #000;
  }
}

.ix-l-section-top-cta2.fixed-content {
  position: fixed;
  bottom: 0;
  width: 100%;
  min-width: 1200px;
  height: 85px;
  z-index: 1000;
  transition: bottom 350ms ease-out;

  @include _sp(){
    height: 75px;
    min-width: 0;
  }
}
.js-fixed-content.is-hidden {
  bottom: -250px !important;
}

.ix-l-section-top-cta-brand {
  background-color: #F5F5F5;
  @include _pc {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 456px;
  }
  @include _sp(){
    padding: 40px 20px;
  }
}

.ix-l-section-top-success {
  background: $COLOR-02;
  @include _sp(){
    position: relative;
    &::before {
      @include _ix-icon(\e900);
      position: absolute;
      top: 0;
      left: 0;
      font-size: 66px;
      color: $COLOR-04;
    }
  }
}


// -----------------------------------------------------------------
%__beginne-before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000,.3);
  @include _sp(){
    background: rgba(#000,.5);
  }
}

.ix-l-section-beginner-1 {
  position: relative;
  padding: 60px 0 100px;
  background: url(/_assets/img/beginner/top/bg_01.jpg) no-repeat top center;
  background-size: cover;
  @include _sp(){
    padding: 50px 0 60px;
    background: url(/_assets/img/beginner/top/bg_01@2x.jpg) no-repeat top center;
    background-size: 100% auto;
  }

  &::before {
    @extend %__beginne-before;
  }

  .p-cta {
    margin-top: 70px;
    @include _sp(){
      margin-top: 40px;
      min-height: 184px;
    }
  }
}

.ix-l-section-beginner-2 {
  padding: 90px 0 160px;
  @include _sp(){
    padding: 60px 0 90px;
  }

  .p-beginner-ttl {
    @include _sp(){
      margin-bottom: 30px;
    }
  }
}

.ix-l-section-beginner-3 {
  position: relative;
  padding: 50px 0 70px;
  background: url(/_assets/img/beginner/top/bg_02.jpg) no-repeat top center;
  background-size: cover;
  @include _sp(){
    padding: 50px 0 40px;
    background: url(/_assets/img/beginner/top/bg_02@2x.jpg) no-repeat top center;
    background-size: cover;
  }

  &::before {
    @extend %__beginne-before;
    background: rgba(#000,.4);
    @include _sp(){
      background: rgba(#000,.4);
    }
  }
}

.ix-l-section-beginner-4 {
  .p-cta {
    @include _sp(){
      min-height: 148px;
    }
  }
}

.ix-l-section-beginner-5 {
  padding: 90px 0 0;
  @include _sp(){
    padding: 60px 0 0;
  }

  .p-beginner-ttl {
    @include _sp(){
      margin-bottom: 30px;
    }
  }
}
