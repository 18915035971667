@charset "utf-8";

// -----------------------------------------------------------------
.c-help-nav {
  $__border: 1px solid $COLOR-BORDER-02;
  border-top: $__border;

  ul {
    border-bottom: none;

    li {
      border-bottom: $__border;
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 40px;
      padding: 0 14px;
      color: $COLOR-01;
      @include _transition-bg-color();
      @include _sp(){
        height: 54px;
        padding: 0 20px;
        font-size: 16px;
        letter-spacing: $LETTER-SPACING-03;
      }

      &.is-current {
        background: $COLOR-13;

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: -1px;
          width: 2px;
          height: 100%;
          border-left: 2px solid $COLOR-06;
          @include _sp(){
            display: none;
          }
        }
      }

      &:hover {
        background: $COLOR-13;
      }
    }
  }
}








