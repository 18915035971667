@charset "utf-8";


// -----------------------------------------------------------------
.p-pressroom {
  margin-top: 70px;
  @include _sp(){
    margin-top: 50px;
  }

  &__inner {
    @include _sp(){
      margin: 0 -20px;
    }
    > * {
      &:not(:last-child){
        margin-bottom: 20px;
        @include _sp(){
        }
      }
    }
  }

  &__pager {
    margin-top: 70px;
  }
}


// -----------------------------------------------------------------
.p-pressroom-detail {
  margin-top: 60px;
  @include _sp {
    margin-top: 50px;
  }

  &__body {
    > * {
      &:not(:last-child){
        margin-bottom: 40px;
      }
    }
  }
}


