@charset "utf-8";

// 会員登録
// -----------------------------------------------------------------
.p-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 160px;
  margin-top: 110px;
  padding: 20px;
  background: $COLOR-13;
  @include _ie11(){
    height: 160px;
  }
  @include _sp(){
    margin-top: 70px;
  }

  &__txt {
    margin-bottom: 20px;
    @include _sp(){
      margin-bottom: 15px;
      font-size: 14px;
      text-align: center;
    }

    &--m {
      margin-bottom: 15px;
      font-size: 16px;
      @include _sp(){
        font-size: 14px;
        letter-spacing: $LETTER-SPACING-03;
      }
    }

    &--l {
      margin-bottom: 10px;
      font-size: 18px;
      @include _sp(){
        font-size: 14px;
        letter-spacing: $LETTER-SPACING-03;
      }
    }
  }
}


.p-cta-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 160px;
  margin-top: 90px;
  padding: 32px;
  background: $COLOR-13;
  @include _ie11(){
    height: 160px;
  }
  @include _sp(){
    margin-top: 60px;
    padding: 20px;
  }

  &__lead {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
  }

  &__txt {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1.5;

    &--m {
      margin-bottom: 15px;
      font-size: 16px;
      @include _sp(){
        font-size: 14px;
        letter-spacing: $LETTER-SPACING-03;
      }
    }

    &--l {
      margin-bottom: 10px;
      font-size: 18px;
      @include _sp(){
        font-size: 14px;
        letter-spacing: $LETTER-SPACING-03;
      }
    }
  }

  &__note {
    font-size: 11px;
    color: #999;
    margin-top: 8px;
    display: block;
  }

  &__btn {
    margin-top: 20px;

    &--link {
      font-size: 16px;
    }
  }

  &:has(+ .p-heading-2) {
    margin-bottom: 80px;
    @include _sp(){
      margin-bottom: 60px;
    }
  }
}


.p-cta-box-light {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 32px;
  background: $COLOR-13;
  @include _ie11(){
    height: 160px;
  }
  @include _sp(){
    margin-top: 40px;
    padding: 20px;
  }

  &__txt {
    font-size: 14px;
    line-height: 1.5;
  }

  &__btn {
    margin-top: 20px;

    &--link {
      font-size: 16px;
    }
  }
}
