@charset "utf-8";

.p-job {
  &__group {
    &:not(:last-child) {
      margin-bottom: 70px;
      @include _sp() {
        margin-bottom: 40px;
      }
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__ttl {
    margin-bottom: 20px;
    @include _sp() {
      margin-bottom: 10px;
      text-align: center;
    }
  }
}