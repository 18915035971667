@charset "utf-8";

.ix-l-wrapper {
  min-width: 320px;
  font-family: $FONT-FAMILY;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: $LETTER-SPACING-05;
  line-height: $LINE-HEIGHT-BASE;
  color: $COLOR-01;
  box-sizing: border-box;
  
  .is-sp-menu-open & {
    width: 100vw
  }

  // .is-fixed-header & {
  //   &:nth-of-type(1) {
  //     padding-top: $GLOBAL-HEADER-HEIGHT-SP;
  //     @include _sp(){
  //       padding-top: $GLOBAL-HEADER-HEIGHT-SP;
  //       opacity: .4;
  //     }
  //   }
  // }
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  main {
    display: block; // IE11
  }

  a {
    // color: $color-01;
    text-decoration: none;
    &[href^="tel:"] {
      @include _sp(){
        color: $COLOR-01;
        pointer-events: none;
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//
.is-prevent-scroll {
  // position: fixed;
  // width: 100vw;
  // height: 100vh;
  // z-index: -1;
  overflow: hidden;
}
