@charset "utf-8";


// -----------------------------------------------------------------
%_c-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 236px;
  height: 46px;
  font-size: 16px;
  line-height: $LINE-HEIGHT-150;
  color: $COLOR-02;
  border-radius: 3px;
  overflow: hidden;
  z-index: 0;
  &:hover {
    &::before {
      opacity: 0;
    }
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::before {
    background: linear-gradient(to bottom, $COLOR-06 0%, #a37f47 100%);
    z-index: -1;
    @include _transition-opacity();
  }

  &::after {
    background: linear-gradient(to bottom, #B4843D 0%, #744D10 100%);
    z-index: -2;
  }
  @include _ie11(){
    padding-top: 4px;
  }
}

.c-button {
  @extend %_c-button;

  &--center {
    margin: 40px auto 0;
  }
}

.c-button--1 {
  @extend %_c-button;
  font-size: 22px;
  width: 300px;
  height: 60px;
}

.c-button--l {
  font-size: 18px;
}


.c-button--full {
  @extend %_c-button;
  width: 100%;
}

.c-button-line-2 {
  @extend %_c-button;
  width: 342px;
  height: 68px;
  line-height: $LINE-HEIGHT-150;
  @include _sp(){
    width: 100%;
  }

  &--center {
    margin: 0 auto;
  }
}

.c-button-pc-header-1 {
  @extend %_c-button;
  width: 100%;
  height: 34px;
}

.c-button-pc-header-2 {
  @extend %_c-button;
  width: 100%;
  height: 34px;
  color: $COLOR-01;
  border: 1px solid #D5D5D5;
  &::before {
    background: $COLOR-02;
  }
  &::after {
    background: $COLOR-11;
  }
}

.c-button-sp-header {
  @extend %_c-button;
  width: 100%;
  height: 42px;
  font-size: 18px;
}

.c-button-sp-header-2 {
  @extend %_c-button;
  width: 100%;
  height: 42px;
  font-size: 18px;
  // &::before {
  //   background: linear-gradient(to bottom, $COLOR-15 0%, #004176 100%);
  // }

  // &::after {
  //   background: linear-gradient(to bottom, #014A84 0%, #022B4E 100%);
  // }
  color: $COLOR-01;
  border: 1px solid #D5D5D5;
  &::before {
    background: $COLOR-02;
  }
  &::after {
    background: $COLOR-11;
  }
}


// -----------------------------------------------------------------
.c-button-flat {
  @extend %_c-button;
  // width: 100%;
  // height: 34px;
  color: $COLOR-01;
  border: 1px solid #D5D5D5;
  &::before {
    background: $COLOR-02;
  }
  &::after {
    background: $COLOR-11;
  }
}

.c-button-flat--opposite {
  @extend %_c-button;
  // width: 100%;
  // height: 34px;
  color: $COLOR-01;
  border: 1px solid #D5D5D5;
  &::before {
    background: $COLOR-02;
  }
  &::after {
    background: $COLOR-11;
  }

  @include _sp(){
    color: $COLOR-02;
    border: 1px solid rgba($COLOR-02,.6);

    &::before {
      background: rgba(#000,.6);
    }
    &::after {
      opacity: 0;
      background: rgba(#000,.3);
      @include _transition-opacity();
    }
  }
}

.c-button-flat--dark {
  @extend %_c-button;
  // width: 100%;
  // height: 34px;
  color: $COLOR-02;
  border: 1px solid rgba($COLOR-02,.6);

  &::before {
    background: rgba(#000,.6);
  }
  &::after {
    opacity: 0;
    background: rgba(#000,.3);
    @include _transition-opacity();
  }
  &:hover {
    &::after {
      opacity: 1;
    }
  }
}

.c-button-flat--dark-light {
  @extend %_c-button;
  color: $COLOR-02;
  border: 1px solid rgba($COLOR-02,.6);

  &::before {
    background: rgba(#000,.6);
  }
  &::after {
    opacity: 0;
    background: rgba(#fff,.3);
    @include _transition-opacity();
  }
  &:hover {
    &::after {
      opacity: 1;
    }
  }
}

.c-button-flat--dark-opposite {
  @extend %_c-button;
  // width: 100%;
  // height: 34px;
  color: $COLOR-02;
  border: 1px solid rgba($COLOR-02,.6);

  &::before {
    background: rgba(#000,.6);
  }
  &::after {
    opacity: 0;
    background: rgba(#000,.3);
    @include _transition-opacity();
  }
  &:hover {
    &::after {
      opacity: 1;
    }
  }

  @include _sp(){
    color: $COLOR-01;
    border: 1px solid #D5D5D5;
    &::before {
      background: $COLOR-02;
    }
    &::after {
      background: $COLOR-11;
    }
  }
}


.c-button-top-cta {
  @extend %_c-button;

  width: 400px;
  height: 55px;
  font-size: 18px;
  @include _sp(){
    width: 300px;
  }
  @include _sp(374px){
    width: 280px;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 1px;
    width: 44px;
    height: 20px;
    margin-left: 10px;
    font-size: 13px;
    border: 1px solid rgba(#fff, .5);
    border-radius: 3px;
  }
}

//GIO記述
.c-button-sp-header-3 {
  @extend %_c-button;
  width: 100%;
  height: 42px;
  font-size: 1.125rem;
  // &::before {
  //   background: linear-gradient(to bottom, $COLOR-15 0%, #004176 100%);
  // }

  // &::after {
  //   background: linear-gradient(to bottom, #014A84 0%, #022B4E 100%);
  // }
  color: #FFFFFF;
  border: 1px solid #D5D5D5;
  &::before {
    background: #2B2B2B;
  }
  &::after {
    background: $COLOR-11;
  }

  @include _sp {
    font-size: 1rem;
  }
}