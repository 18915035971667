@charset "utf-8";

// -----------------------------------------------------------------
.c-colbox {
  padding: 45px;
  text-align: center;
  background: $COLOR-13;
}


// -----------------------------------------------------------------
.c-colbox-frame {
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  border: 8px solid $COLOR-BORDER-03;
  background: $COLOR-02;
  @include _sp(){
    border-width: 8px;
    padding: 30px 20px;
    text-align: justify;
  }

  &__ttl {
    margin-bottom: 20px;
    text-align: center;
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin-top: auto;
    padding-top: 20px;
  }
}

// -----------------------------------------------------------------
.c-colbox-button-full {
  padding: 40px 20px;
  text-align: center;
  background: $COLOR-13;
  @include _sp(){
    margin-left: -20px;
    margin-right: -20px;
    padding: 30px 20px;
  }

  > * {
    margin: 0 auto;
  }
}
