@charset "utf-8";


// -----------------------------------------------------------------
.p-success {
  @include _sp(){
    margin: 0 -20px;
    padding: 40px 20px;
    background: $COLOR-12;
  }

  &__inner {
    > * {
      &:not(:last-child){
        margin-bottom: 40px;
        @include _sp(){
          margin-bottom: 20px;
        }
      }
    }
  }
}


// -----------------------------------------------------------------
.p-success-mv {
  position: relative;
  margin-top: $GLOBAL-HEADER-HEIGHT;
  @include _sp(){
    margin-top: $GLOBAL-HEADER-HEIGHT-SP;
  }

  $__mv: story_01, story_02, story_03;
  @each $key in $__mv {
    &--#{$key} {
      background: url(/_assets/img/success/#{$key}/mv_01.jpg) no-repeat center;
      background-size: cover;
      @include _sp(){
        background: url(/_assets/img/success/#{$key}/mv_01@2x.jpg) no-repeat bottom right;
        background-size: cover;
      }
    }
  }

  &::before {
    @include _sp(){
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000,.5);
    }
  }

  .ix-l-content__fig-top {
    > * {
      top: 0;
      @include _sp(){
        top: 0;
      }
    }
  }

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: $BASE-CONTENT-WIDTH;
    height: 436px;
    margin: 0 auto;

    @include _sp(){
      width: auto;
      height: auto;
      // height: 416px;
      margin: 0 20px;
      text-align: center;
      color: $COLOR-02;
    }
  }

  &__bottom {
    display: flex;
    color: $COLOR-02;
    > * {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 50%;
      padding: 23px 20px 15px;
      text-align: center;
    }

    &-col-1 {
      background: $COLOR-21;

      .c-txt-2s {
        margin-bottom: 3px;
      }
    }

    &-col-2 {
      background: $COLOR-15;

      .c-txt-2s {
        margin-bottom: 3px;
      }

      .c-txt-l {
        @include _fontbold();
      }

      .c-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -35px;
        left: calc(50% - 25px);
        width: 50px;
        height: 50px;
        font-size: 12px;
        @include _fontbold();
        border-radius: 50%;
        background: $COLOR-06;
      }

      i {
        position: absolute;
        top: calc(50% - 8px);
        left: 0;
        font-size: 16px;
        color: $COLOR-06;
      }
    }
  }
}


// -----------------------------------------------------------------
.p-success-story {
  $__self: &;
  width: $NARROW-CONTENT-WIDTH;
  margin: 0 auto 70px;
  @include _sp(){
    width: auto;
  }

  &__group {
    &:not(:last-child){
      margin-bottom: 70px;
      @include _sp(){
        margin-bottom: 60px;
      }
    }
  }

  &__ttl {
    margin-bottom: 30px;
    text-align: center;
    @include _sp(){
      margin-bottom: 20px;
    }
  }

  &__subttl {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 256px;
    height: 30px;
    margin: 0 auto 15px;
    font-size: 13px;
    @include _fontbold();
    color: $COLOR-08;
    background: $COLOR-13;
  }

  &__body {
    > * {
      &:not(:last-child){
        margin-bottom: 2em;
      }

      @include _sp(){
        font-size: 16px;
      }
    }

    // #{$__self}__ttl {
    //   margin-top: 4em;
    // }
  }
}


// -----------------------------------------------------------------
.p-success-story-profile {
  margin: 70px 0;
  @include _sp(){
    margin: 0 -20px 70px;
  }

  &__head {
    display: flex;
    padding: 40px 50px;
    background: $COLOR-13;
    @include _sp(){
      display: block;
      padding: 30px 20px;
    }

    &-col-1 {
      min-width: 330px;
      width: 330px;
      margin-right: auto;
      @include _sp(){
        min-width: initial;
        min-width: auto;
        width: auto;
      }
      dl {
        display: flex;
        font-size: 13px;
        letter-spacing: $LETTER-SPACING-03;
        &:not(:last-child){
          margin-bottom: 5px;
        }
        dt {
          @include _fontbold();
          min-width: 6em;
        }
        dd {
          position: relative;
          padding-left: 1em;
          &::before {
            content: '：';
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }

    &-col-2 {
      min-width: 600px;
      width: 600px;
      margin-left: auto;
      @include _sp(){
        min-width: initial;
        min-width: auto;
        width: auto;
        margin-top: 5px;
      }
      dl{
        dt{
          margin-bottom: 5px;
          font-size: 13px;
          @include _fontbold();
          letter-spacing: $LETTER-SPACING-03;
          @include _sp(){
            margin-bottom: 2px;
          }
        }
        dd {
          font-size: 12px;
        }
      }
    }
  }

  &__body {
    padding: 40px;
    background: $COLOR-08;
    @include _sp(){
      padding: 40px 20px 35px;
    }

    &-ttl {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 256px;
      height: 30px;
      margin: 0 auto 15px;
      @include _fontbold();
      color: $COLOR-02;
      background: rgba(#000,.3);
      @include _sp(){
        width: auto;
        margin-bottom: 20px;
      }
    }

    &-pct {
      width: 466px;
      margin: 0 auto;
      @include _sp(){
        width: auto;
      }
    }

    &-txt {
      margin-top: 20px;
      font-size: 13px;
      line-height: $LINE-HEIGHT-180;
      color: $COLOR-02;
    }
  }
}


// -----------------------------------------------------------------
.p-success-story-point {
  position: relative;
  padding: 30px 40px;
  border: 8px solid $COLOR-BORDER-03;
  @include _sp(){
    margin: 0 -20px;
    padding: 30px 20px;
  }

  .c-fig {
    position: absolute;
    top: -8px;
    left: -8px;
    font-size: 94px;
    color: $COLOR-06;
    @include _sp(){
      font-size: 74px;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @include _sp(){
      display: block;
    }

    .c-hd-4 {
      @include _sp(){
        margin-bottom: 30px;
        font-size: 14px;
        text-align: center;
      }
    }

    .c-box-hunter-2 {
      margin-left: auto;
    }
  }

  &__body {
    > * {
      &:not(:last-child){
        margin-bottom: 2em;
      }
    }
  }
}


// -----------------------------------------------------------------
.p-success-2 {
  margin-bottom: 80px;

  &__heading {
    margin-bottom: 40px;
    text-align: center;
    @include _sp(){
      display: none;
    }
  }

  &__group {
    &:not(:last-child) {
      margin-bottom: 70px;
      @include _sp() {
        margin-bottom: 60px;
      }
    }
  }

  &__item {
    @include _pc() {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    
    @include _sp() {
      margin: 0 -20px;
      border-top: 1px solid $COLOR-BORDER-05;
      &:last-child {
        border-bottom: 1px solid $COLOR-BORDER-05;
      }
    }
  }

  &__ttl {
    margin-bottom: 20px;
    @include _sp() {
      margin-bottom: 25px;
      text-align: center;

      .c-hd-3 {
        font-size: 20px;
        letter-spacing: $LETTER-SPACING-03;
      }
    }
  }
}


.p-success-sample {
  @include _pc() {
    border: 8px solid $COLOR-BORDER-03;
  }
  @include _sp() {
    border: none;
  }

  &__inner {
    margin: 30px 40px;
    @include _sp() {
      margin: 25px 10px 30px;
    }
  }

  &__ttl {
    margin-bottom: 10px;
    font-size: 18px;
    @include _fontbold();
    line-height: $LINE-HEIGHT-160;
    @include _sp() {
      margin: 0 10px 10px;
      font-size: 16px;
      letter-spacing: $LETTER-SPACING-03;
    }
  }

  &__txt {
    font-size: 14px;
    line-height: $LINE-HEIGHT-170;
    @include _sp() {
      margin: 0 10px;
      font-size: 13px;
      letter-spacing: $LETTER-SPACING-03;
    }
  }

  &__fig {
    margin-top: 22px;
  }
}

/*一覧ページ20200903ジオキュービック*/

.ix-l-content__inner__sp{
  @include _sp(){
    margin: 0;
  }
}

.p-lead-success{
  width: 800px;
  letter-spacing: 0.02em;
  padding: 65px 0 70px;
  @include _sp(){
    width: 100%;
    padding:60px 20px 21px ;
  }
}

.c-hd-5.c-hd-5--experiences{
  @include _sp(){
    margin-bottom: 17.4px;
    font-size: 14px;
  }
}

.p-experiences-wrap{
  display: flex;
  @include _sp(){
    flex-direction: column-reverse;
  }
}

.p-experiences-right{
  @include _sp(){
    width: 100%;
  }
  .p-grid-def-2__aside.p-grid-def-2__aside--side-nav.p-grid-def-2__aside--side-nav__margin{
    margin-top: 0;
  }
  // .c-side-nav ul li .p-experiences-right__link{
  //   @include _sp(){
  //     font-size: 14px;
  //     line-height: 2;
  //     padding: 7.5px 20px;
  //     min-height: 43px;
  //   }
  //   &:after{
  //     @include _sp(){
  //     content: "\e908";
  //     position: absolute;
  //     top: calc(50% - 3.5px);
  //     right: 17px;
  //     font-size: 7px;
  //     color: #b99969;
  //     }
  //   }
  // }
  &__age{
    @include _sp(){
    display: flex;
    flex-wrap: wrap;
    }
  }
  &__ageClass{
      @include _sp(){
      width: 50%;
      &:nth-child(odd){
        border-right: 1px solid #ebebeb;
      }
    }
  }
}
.p-grid-def__nav.p-grid-def__nav--margin{
  margin-bottom: 58px;
  &:last-child{
    margin-bottom: 0;
  }
  @include _sp(){
    margin:0 0  40px 0;
  }
}

.p-experiences-left{
  margin: 0 ;
  @include _sp(){
    margin: 0 20px;
  }
}

.p-experiences-list{
  width: 800px;
  margin: 0 auto 62px;

  &:last-child{
    margin-bottom: 80px;
  }

  @include _sp(){
    width: 100%;
    margin-bottom: 36px;

    &:last-child{
      margin-bottom: 70px;
    }
  }

  &__generation{
    margin-bottom: 40px;
    &:last-child{
      margin-bottom: 0px;
    }
    @include _sp(){
      margin-bottom: 40px;
    }
  }

  &__ttl{
    font-size: 24px;
    font-weight: normal;
    line-height: 1.8;
    margin-bottom: 20.4px;
    font-family: "Noto Serif JP",serif;

    @include _sp(){
      text-align: center;
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 23px;
    }
  }

  &__item{
    box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    position: relative;
    margin-bottom: 45px;
    &:last-child{
      margin-bottom: 0;
    }

    @include _sp(){
      margin-bottom: 25px;

      &:last-child{
        margin-bottom: 0px;
      }
    }
    &.is-hide{
      display: none !important;
    }
  }

  &__wrap{
    @include _sp(){
    overflow: hidden;
    padding-left: 5px;
    }
  }

  &__link{
    padding: 20px 25px;
    display: block;

    &:hover{
      background: #f9f9f9;
      box-shadow: 1px 3px 18px rgba(30,30,30,.16);
      transition:all .5s ease 0s;
    }

    @include _sp(){
      padding: 20px 10px;
    }
  }

  &__icon{
    width: 56px;
    flex-shrink: 0;
    text-align: center;
    float: left;

    @include _sp(){
      width: 48px;
    float: left;
    }
  }

  &__img{
    margin-bottom: 10px;
  }

  &__age{
    font-size:11px ;
    letter-spacing: 0;
    font-weight: bold;
    color: #222222;
    line-height: 1.63;

    @include _sp(){
      line-height: 1.63;
    }
  }

  &__nakaguro{
    @include _sp(){
      display: none;
    }
  }

  &__text{
    color: #222222;
    font-weight: bold;
    letter-spacing: 0.02em;
    margin-left: 81px;

    @include _sp(){
      margin-left: 58px;

      &:nth-child(odd){
        margin-right: 0px;
      }
    }
  }

  &__ttl2{
    line-height: 1.5;
    font-size: 18px;
    margin-bottom: 10px;

    @include _sp(){
      line-height: 1.5;
      font-size: 16px;
      margin-bottom: 0px;
    }
  }

  &__label{
    background: #A37F47;
    padding: 1px 4px;
    color: #fff;
    font-size: 13px;
    font-weight: normal;
    border-radius: 1px;
  }

  &__company{
    margin-left: 8px;
    font-size: 14px;
    line-height: 1.69;
    @include _sp(){
      font-size: 13px;
    }
  }

  &__position{
    font-size: 14px;
    margin-left: 1rem;
    line-height: 1.69;
    @include _sp(){
      font-size: 13px;
    }
  }

  &__comment{
    background: #f5f5f5;
    margin: 14px 0 0 81px;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42;
    color: #222;

    @include _sp(){
      margin-top: 10px;
      margin-left: 0px;
      padding: 10px 15px;
      font-size: 13px;
      line-height: 1.69;
    }
  }

  &__text2{
    line-height: 0;
    @include _sp(){
      line-height: 1.5 !important;
      margin-top: 6px;
    }
  }
}

.c-fig{
  position: absolute;
  top: 0;
  left: 0;
  font-size: 40px;
  color: #b99969;
}
.p-experiences-slider .p-slider__ttl{
  padding: 71.6px 0 41.6px;

  @include _sp(){
  padding:40px 0 40px ;
  }
}
.p-experiences-slider .p-slider{
  padding-bottom:71px ;
}
@media all and (-ms-high-contrast: none){
  .p-experiences-list__label,.p-slider-detail__label{
    padding: 2px 4px 0px;
    height: 19px;
    line-height: 1;
    vertical-align: middle;
  }
}

/*カテゴリー別一覧ページここから20201102 ジオキュービック*/
.c-fig-success.c-fig-success__category{
  background-image: unset;
}
.p-heading--category{
  @include _sp(){
    margin: 50px 20px 0;
  }
}
.c-hd-1__category{
  @include _sp(){
  margin: 0 20px;
  }
}
.c-hd-1__sub--bold{
  font-weight: bold;
  @include _sp(){
    font-weight: normal;
  }
}
.p-experiences-wrap__category{
  margin-top: 70px;
  @include _sp(){
    margin-top: 60px;
  }
}
.p-category-button{
  height: 46px;
  @include _pc {
    margin-top: 60px;
  }
  @include _sp(){
    margin-top: 40px;
  }
  .p-category-button__more{
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 236px;
    height: 46px;
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
    border-radius: 3px;
    color: #222;
    border: 1px solid #d5d5d5;
    background: #fff;
    position: relative;
    top: -80px;
    &:hover{
      background: #e6e6e6;
      transition: all .3s ease 0s;
    }
  }
    &.is-disabled{
      display: none;
    }
}

.p-experiences-list__category{
  margin-bottom: 0 !important;
  @include _sp(){
    margin-bottom: 0 !important;
  }
}
.ix-l-content__inner__category{
  width: 800px;
  margin: 110px 0 ;
}
.p-experiences-wrap.p-experiences-wrap__category{
  @include _sp{
  flex-direction: column;
  }
}
.p-experiences-left.p-experiences-left--category{
  @include _sp(){
  margin-bottom: 0px;
  }
}

.p-experiences-right--category{
  @include _sp(){
    margin-top: 94.4px;
  }
}

.p-experiences-list__generation--category{
  @include _sp(){
    margin-bottom: 0;
  }
}

#include-nav-job .p-grid-def__nav--margin.p-grid-def__nav--marginCategory{
  margin-bottom:36px;
  &:last-child{
    margin-bottom: 0;
    @include _sp{
    margin-bottom: 0;
    }
  }
}
.p-experiences-right .p-grid-def-2__aside--side-nav--marginCategory{
  margin-bottom: 0;
}

/*詳細ページここから20200901ジオキュービック*/

.ix-l-content__fig-top--detail{
  position: unset;
}

.p-detail-mv{
  position: relative;
  //background: url(/_assets/img/success/kv_type003_m.png) no-repeat center;
  background-repeat: no-repeat;
  background-position:center;
  background-color:#F5F5F5;
  margin-top: 60px;
  margin-bottom: 70px;

  @include _sp(){
     width: auto;
     margin-bottom: 0px;
     margin-top: 50px;
     background-position:bottom right;
     background-size:cover;
   }

  &--001m{
    background-image: url(/_assets/img/success/kv_type001_m.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type001_m_sp.png);
    }
  }

  &--002m{
    background-image: url(/_assets/img/success/kv_type002_m.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type002_m_sp.png);
    }
  }

  &--003m{
    background-image: url(/_assets/img/success/kv_type003_m.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type003_m_sp.png);
    }
  }

  &--004m{
    background-image: url(/_assets/img/success/kv_type004_m.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type004_m_sp.png);
    }
  }

  &--005m{
    background-image: url(/_assets/img/success/kv_type005_m.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type005_m_sp.png);
    }
  }

  &--006m{
    background-image: url(/_assets/img/success/kv_type006_m.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type006_m_sp.png);
    }
  }

  &--007m{
    background-image: url(/_assets/img/success/kv_type007_m.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type007_m_sp.png);
    }
  }

  &--008m{
    background-image: url(/_assets/img/success/kv_type008_m.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type008_m_sp.png);
    }
  }

  &--009m{
    background-image: url(/_assets/img/success/kv_type009_m.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type009_m_sp.png);
    }
  }

  &--010m{
    background-image: url(/_assets/img/success/kv_type010_m.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type010_m_sp.png);
    }
  }

  &--001f{
    background-image: url(/_assets/img/success/kv_type001_f.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type001_f_sp.png);
    }
  }

  &--002f{
    background-image: url(/_assets/img/success/kv_type002_f.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type002_f_sp.png);
    }
  }

  &--003f{
    background-image: url(/_assets/img/success/kv_type003_f.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type003_f_sp.png);
    }
  }

  &--004f{
    background-image: url(/_assets/img/success/kv_type004_f.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type004_f_sp.png);
    }
  }

  &--005f{
    background-image: url(/_assets/img/success/kv_type005_f.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type005_f_sp.png);
    }
  }

  &--006f{
    background-image: url(/_assets/img/success/kv_type006_f.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type006_f_sp.png);
    }
  }

  &--007f{
    background-image: url(/_assets/img/success/kv_type007_f.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type007_f_sp.png);
    }
  }

  &--008f{
    background-image: url(/_assets/img/success/kv_type008_f.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type008_f_sp.png);
    }
  }

  &--009f{
    background-image: url(/_assets/img/success/kv_type009_f.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type009_f_sp.png);
    }
  }

  &--010f{
    background-image: url(/_assets/img/success/kv_type010_f.png);

    @include _sp(){
      background-image: url(/_assets/img/success/kv_type010_f_sp.png);
    }
  }

  &__inner{
    position: relative;
    width: 1056px;
    height: 436px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    @include _sp(){
      height: 380px;
      margin: 0 20px;
      width: auto;
      display: block;
    }
  }

  &__person{
    width: 735px;

    @include _sp(){
      width: auto;
      padding-top: 45px;
    }
  }

  &__sub{
    display: inline-block;
    margin-bottom: 4px;
    font-size: 20px;
    letter-spacing: 0.02em;
    line-height: 2.7;
    color: #222222;
    font-weight: bold;

    @include _sp(){
      margin-bottom: 8px;
      font-size: 14px;
      letter-spacing: .02em;
      line-height: 2;
      color: #222;
      font-weight: bold;
    }
  }

  &__ttl{
    margin-bottom: 30px;
    font-family: "Noto Serif JP",serif;
    font-size: 36px;
    font-weight: normal;
    letter-spacing: 0.02em;
    line-height: 1.5;

    @include _sp(){
      margin-bottom: 35px;
      font-family: "Noto Serif JP",serif;
      font-size: 26px;
      font-weight: 400;
      letter-spacing: 0em;
      line-height: 1.38;
      width: 100%;
    }
  }

  &__heading{
    display: inline-block;
    font-size: 12px;
    background: #2F2F2F;
    color: #fff;
    padding: 8px 15px;
    border-top: 1px solid #707070;
    border-left: 1px solid #707070;
    border-bottom: 1px solid #707070;

    @include _sp(){
      display: block;
      padding: 1px 10px;
      border-top: 1px solid #707070;
      border-left: 1px solid #707070;
      border-right: 1px solid #707070;
      border-bottom: none;
      width: 83px;
    }
  }

  &__body{
    display: inline-block;
    padding: 8px 15px;
    background-color: #fff;
    border: 1px solid #707070;
    font-size: 12px;

    @include _sp(){
      display: block;
      padding: 7px 10px;
      background-color: #fff;
      border: 1px solid #707070;
      font-size: 11px;
      width: 55%;
      line-height: 1.45;
      font-weight: bold;
      letter-spacing: 0em;
      min-width: 188px;
    }
  }
}

/*カルーセルスライダー部分*/
.ix-l-content__inner--wrap .p-cta{
  margin-top: 30px;
  @include _sp(){
    margin-top: -2px;
  }
}
.ix-l-section--slider + .ix-l-content__inner .p-cta{
  margin-top: 110px;
  @include _sp(){
    margin-top: 70px;
  }
}
.ix-l-section__inner--detail{
  width: 1055px;
  margin-bottom: 80px;
  @include _sp{
    padding: 0;
    margin-bottom: 60px;
  }
}
.c-hd-4--detail{
  font-size: 24px;
  text-align: center;
  @include _sp(){
    font-size: 20px;
    font-weight: normal;
    font-family: "Noto Serif JP",serif;
  }
}
.slider > div {
  width: 1055px;
}
.p-slider-detail{
  &__wrap{
    padding-left: 5px;
    @include _sp(){
      padding: 0;
      overflow: hidden;
    }
  }
  &__list{
    width: 1055px;
    margin-left: 15px;
    @include _sp(){
      width: 100%;
      margin-left: 0;
    }
  }

  &__item{
    background: #fff;
    position: relative;
    max-width: 335px;
    @include _sp{
     max-width: 295px;
     min-width: 295px;
    //  margin-right: 15px;
    }
    // @include _sp(350px){
    //   max-width: 250px;
    // }
    // &:last-child{
    //   margin-right: 0;
    // }
    @include _sp(360px){
      min-width: 240px;
      max-width: 240px;
    }
  }

  &__ttl--experiences{
    font-size: 24px;
    font-weight: normal;
    line-height: 1.16;
    padding: 70.6px 0 40px;
    font-weight: normal;
    font-family: "Noto Serif JP",serif;

    @include _sp(){
      margin: 0 42px;
      letter-spacing: .02em;
      padding: 40px 0 38px;
      text-align: center;
      font-size: 20px;
      line-height: 1.4;
    }
  }

  &__link{
    padding: 20px 10px;
    display: block;
    @include _sp(){
      padding: 20px 15px 19px;
    }

    &:hover{
      background: #f9f9f9;
      box-shadow: 1px 3px 18px rgba(30,30,30,.16);
      transition:all .5s ease 0s;
    }
  }

  &__icon{
    width: 48px;
    flex-shrink: 0;
    text-align: center;
    float: left;
    margin-bottom: 9px;
    height: 95px;

    @include _sp(){
      width: 48px;
    float: left;
    margin-bottom: 0;
    height: 0;
    }
  }

  &__img{
    width: 48px;
    height: 48px;
    margin-bottom: 10px;
  }

  &__age{
    font-size:11px ;
    letter-spacing: 0;
    font-weight: bold;
    color: #222222;
    line-height: 1.63;
    
    @include _sp(){
      line-height: 1.63;
    }
  }

  &__text{
    color: #222222;
    font-weight: bold;
    letter-spacing: 0.02em;
    margin-left: 58px;

    @include _sp(){
      margin-left: 58px;

      &:nth-child(odd){
        margin-right: 0px;
      }
    }
  }

  &__ttl2{
    line-height: 1.5;
    font-size: 16px;
    margin-bottom: 6px;
    overflow: hidden;
    height: 48px;
    @include _sp(){
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: 1.5;
      font-size: 16px;
      margin-bottom: 0px;
    }
    
    @include _sp(320px){
      font-size: 15px;
      height: 46px;
    }
    
  }

  &__label{
    background: #A37F47;
    padding: 1px 4px;
    color: #fff;
    font-size: 13px;
    font-weight: normal;
    border-radius: 1px;
  }

  &__company{
    margin-left: 2px;
    font-size: 13px;
    line-height: 1.69;
  }

  &__position{
    font-size: 13px;
    margin-left: 10px;
    line-height: 1.69;
  }

  &__comment{
    background: #f5f5f5;
    margin-top: 10px;
    padding: 10px 15px;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.69;
    color: #222;
    clear: both;
    height: 146px;

    @include _sp(){
      display: none;
    }
  }

  &__text2{
    line-height: 0;
    overflow: hidden;
    height: 42px;

    @include _sp(){
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      line-height: 1.5 !important;
      margin-top: 6px;
      // -webkit-line-clamp: 3;
    }

    @include _sp(320px){
      margin-top: 8px;
    }
  }
  .c-button--center--detail{
    margin-top: 37px;
    @include _sp(){
      margin-top: 48px;
    }
  }
}
.slick-list.draggable{
  @include _sp(){
    margin-left: 0px;
  }
}
.slick-slide{
  margin:0 12.5px;
  @include _sp(){
    margin: 0 15px 0 0;
  }
}
.slick-dots{
  display: none !important;
  @include _sp(){
  bottom: -9px !important;
  position: relative;
  display: block !important;
}
}
.navigation{
  width: 100%;
  margin: 0 auto;
  position: relative;
  @include _sp(){
    display: none;
  }
}

.navigation-forward{
  width: 27px;
  height: 35px;
  position: absolute;
  top: -173px;
  right: -60px;
  cursor: pointer;
  &::before{
    content: "";
    width: 5px;
    height: 27px;
    background: #A5A5A5;
    display: block;
    transform: rotate(135deg);
    position: absolute;
    top: -5px;
    right: 7px;
  }
  &::after{
    content: "";
    width: 5px;
    height: 27px;
    background: #A5A5A5;
    display: block;
    transform: rotate(-135deg);
    position: absolute;
    top: 11px;
    right: 7px;
  }

}
.navigation-prev{
  position: absolute;
  top: -174px;
  left: -60px;
  width: 27px;
  height: 35px;
  cursor: pointer;
  &::before{
    content: "";
    width: 5px;
    height: 27px;
    background: #A5A5A5;
    display: block;
    transform: rotate(45deg);
    position: absolute;
    top: -5px;
    left: 7px;
  }
  &::after{
    content: "";
    width: 5px;
    height: 27px;
    background: #A5A5A5;
    display: block;
    transform: rotate(-45deg);
    position: absolute;
    top: 11px;
    left: 7px;
  }
}

.navigation-dots{
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.navigation-dot{
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}
.navigation-dot.is-current{
  cursor: default;
}
.navigation-dot-1 + .navigation-dot-2{
  margin-left: 12px;
}
.navigation-dot-2 + .navigation-dot-3{
  margin-left: 12px;
}

.navigation-dot:last-child{
  margin-right: 0;
}
.navigation-dot.is-current{
  color: #A5A5A5;
}
.navigation-prev.is-dead,
.navigation-forward.is-dead{
  cursor: default;

  &::before{
    background-color: #D5D5D5;
    cursor: default;
  }
  &::after{
    background-color: #D5D5D5;
    cursor: default;
  }
}
.u-hide{
  display: none !important;
}


@media all and (-ms-high-contrast: none){
  .p-slider-detail__label{
    background: #a37f47;
    padding: 2px 4px 0px !important;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    border-radius: 1px;
    height: 18.67px;
    line-height: 2;
    vertical-align: middle;
  }
}

@media screen and (max-width: 667px){
  .ix-l-wrapper.p-detail-otherVer01 {
    z-index: 710;
    position: relative;
    .p-detail-mv__heading {
      display: inline-block;
      width: auto;
    }
    .p-detail-box__top {
      display: block;
    }
    .p-detail-box__arrow {
      justify-content: center;
      padding: 8px 0;
    }
    .p-detail-box__svg {
      transform: rotate(90deg);
    }
    .p-detail-box__after,
    .p-detail-box__before {
      width: 100%;
    }
    .p-detail-interview__comment--emphasis:not(:first-child) {
      padding-top: 26px;
    }
    .p-detail-interview__profile__portrait {
      width: 64px;
    }
    .p-detail-interview__profile {
      margin: 26px 0;
      padding: 24px
    }
    .p-detail-interview__profile__annotation {
      padding-top: 26px;
    }
  }
}

@media screen and (max-width:335px){
  .p-detail-mv__ttl--se{
    width: 100% !important;
  }
}

.p-detail-box{

  &__inner{
    margin: 0 auto 70px;
    width: 1056px;

    @include _sp(){
      margin: 0 0 30px 0;
      width: auto;
    }
  }

  &__wrap{
    background-color: #F5F5F5;
    padding: 30px 40px;

    @include _sp(){
      background-color: #f5f5f5;
      padding: 30px 20px;
    }
  }

  &__top{
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
  }

  &__before{
    width: 420px;
    text-align: center;
    background-color: #fff;

    @include _sp(){
      width: 50%;
    }
  }

  &__heading{
    background-color: #0068B7;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    padding: 2px;

    @include _sp(){
      font-size: 16px;
      padding: 0px 0;
      line-height: 1.75;
      padding :1px 0;
    }

    &--brown{
      background-color:#B99969;
    }
  }

  &__body{
    padding:17px 10px 15px;
    @include _sp(){

    padding: 15px 10px 19.5px;
    }
  }

  &__income{
    font-size: 16px;
    font-weight: bold;
    line-height: 1.75;

     @include _sp(){
      font-size: 14px;
     }
  }

  &__job{
    font-size: 14px;
    line-height: 2;

    @include _sp(){
      font-size: 13px;
      line-height: 1.84;
    }
  }

  &__after{
    width: 420px;
    text-align: center;
    background-color: #fff;

    @include _sp(){
      width: 50%;
    }
  }

  &__bottom{
    display: flex;
    justify-content: space-between;

    @include _sp(){
      display: block;
    }
  }

  &__data{
    width: 300px;

    @include _sp(){
      width: auto;
    }
  }

  &__item{
    border-bottom: 1px solid #ccc;
    padding: 10px;

    @include _sp(){
      border-bottom: 1px solid #ccc;
      padding: 8.5px 0 10.5px 8px;

      &--top{
        border-top: 1px solid #ccc;
      }
    }
  }

  &__performance{
    display: flex;
    justify-content: space-between;
  }

  &__ttl{
    font-size: 13px;
    color: #212121;
    line-height: 1.23;

    @include _sp(){
      width: 70%;
      margin-right: 15px;
    }

  }

  &__text{
    font-size: 13px;
    font-weight: bold;
    line-height: 1.23;

    @include _sp(){
      width: 25%;
    }

  }

  &__arrow{
    display: flex;
    align-items: center;
  }

  &__svg{
    @include _sp(){
      margin-left:8px;
      margin-right: 8px;
      width: 40px;
      height: 40px;
    }
  }
}

.p-arrow-icon{
  @include _pc(){
    width: 56px;
    height: 56px;
  }

  @include _sp(){
    width: 50px;
    height: 50px;
  }
}

.p-detail-interview{
   width: 800px;
   margin: 0 auto;
   padding-bottom: 73px;
   letter-spacing: 0.02em;

   @include _sp(){
    width: auto;
    padding: 0 20px;
    margin-bottom: 62px;
   }


   &__block{
     margin-bottom: 69px;
     &:last-child{
       margin-bottom: 0;
     }

     @include _sp(){
      margin-bottom: 64px;
     }

   }

   &__ttl{
    font-size: 24px;
    line-height: 1.16;
    margin-bottom: 40px;
    text-align: center;
    font-family: "Noto Serif JP",serif;
    font-weight: 400;
    letter-spacing: 0.07em;

    @include _sp(){
      font-size: 20px;
      line-height: 1.8;
      margin-bottom: 26px;
      text-align: center;
    }

  }

  &__comment{
    line-height: 2;

    &--emphasis {
      font-size: 16px;
      text-align: center;
      display: block;
      margin-bottom: 3px;
      font-weight: bold;
      &:not(:first-child) {
        padding-top: 40px;
      }
    }

    @include _sp(){
      line-height: 2;
      font-size: 16px;
    }
  }
  &__profile {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 32px;
    line-height: 1.5;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    &__portrait {
      width: 96px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      overflow: hidden;
    }
    &__text {
      padding-left: 20px;
      width: calc(100% - 96px);
    }
    &__name {
      font-size: 1.2857142857em;
      font-weight: bold;
    }
    &__title {
      margin-top: 0.75em;
    }
    &__annotation {
      padding-top: 40px;
      font-size: 11px;
      display: block;
    }
  }
  &__cta {
    margin: 70px 0 7px;
    display: flex;
    flex-wrap: wrap;
    &__img {
      width: 50%;
      aspect-ratio: 368/246;
      @include _sp(){
        width: 100%;
        aspect-ratio: 16/9;
      }
      img {
        object-fit: cover;
        object-position: center;
        height: 100%;
        @include _sp(){
          width: 100%;
        }
      }
    }
    &__txt {
      background-color: #f5f5f5;
      line-height: 1.5;
      letter-spacing: .02em;
      display: grid;
      place-content: center;
      padding: 30px;
      width: 50%;
      @include _sp(){
        width: 100%;
      }
    }
    &__title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    &__lead {
      margin-bottom: 20px;
    }
    &__btn {
      max-width: 304px;
      margin: 0 auto;
    }
  }
  &__fix-footer {
    margin-top: 175px;
    min-width: 1200px;
    position: relative;
    z-index: 800;
    @include _sp(){
      margin-top: 180px;
      min-width: auto;
    }
    &__inner {
      padding: 60px 0;
      min-width: 1200px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #142f71;
      z-index: 710;
      transition: padding .3s cubic-bezier(0.215, 0.61, 0.355, 1);
      @include _sp(){
        padding: 62.4px 0;
        min-width: auto;
      }
      &.fixed {
        padding: 15px 0;
        position: fixed;
      }
    }
    .c-button--full {
      width: 400px;
      margin: 0 auto;
      height: 55px;
      font-size: 18px;
      text-decoration: none;
      @include _sp(){
        width: 300px;
      }
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: 1px;
      width: 44px;
      height: 20px;
      margin-left: 10px;
      font-size: 13px;
      border: 1px solid rgba(255,255,255,.5);
      border-radius: 3px;
    }
  }
}

.c-button--center__detail{
  margin: 0 auto;
}

.ix-l-section-detail-jobs{
  background: #f5f5f5;
}

@media all and (-ms-high-contrast: none){
.p-detail-box__heading{
    padding-top: 7px;
  }
}



/* ================================================
 スピナー
================================================ */
.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #0068b7;
  background: -moz-linear-gradient(left, #0068b7 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #0068b7 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #0068b7 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #0068b7 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #0068b7 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  top: 5px;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  display: block;
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #0068b7;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #fff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

