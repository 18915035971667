@charset "utf-8";

.p-topicpath {
  margin-bottom: 30px;
  
  ul {
    display: flex;

    @include _pc {
      flex-wrap: wrap;
      row-gap: 15px;
    }

    li {
      color: $COLOR-02;
      &:not(:last-child) {
        margin-right: 8px;

        &::after {
          @include _ix-icon(\e908);
          display: inline-flex;
          margin-left: 8px;
          font-size: 10px;
          transform: scale(.7);
          color: $COLOR-09;
        }
      }
    }
  }
  a {
    color: $COLOR-02;

    &:hover {
      text-decoration: underline;
    }
  }
}