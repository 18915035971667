@charset "utf-8";

.c-table {
  $__border: 1px solid #E9E9E9;

  &__ttl {
    margin-bottom: 15px;
    font-size: 14px;
    @include _fontbold();
  }

  &__wrap {
    @include _sp() {
      overflow-x: auto;

      // -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background: $COLOR-13;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: $COLOR-10;
      }
    }
  }

  table {
    $__border: 1px solid #E9E9E9;
    width: 800px;
    font-size: 14px;
    line-height: $LINE-HEIGHT-170;
    border-top: $__border;
  }

  th,
  td {
    border-bottom: $__border;
  }

  th {
    padding: 20px 15px 20px 20px;
    @include _fontbold();
    border-right: $__border;
    vertical-align: top;
    background: $COLOR-24;
  }

  td {
    padding: 20px 30px;
  }

  &__tb {
    @include _sp {
      display: block;
      width: auto;
    }

    tbody,
    tr,
    th,
    td {
      @include _sp {
        display: block;
      }
    }

    th {
      width: 24%;

      @include _sp {
        width: auto;
        padding: 15px 20px;
        border-right: none;
      }
    }

    td {
      @include _sp {
        width: auto;
        padding: 20px;
      }
    }
  }

  &__tb-scroll {
    th {
      width: 16%;
    }

    td {
      &:not(:last-child) {
        border-right: $__border;
      }
    }
  }
}

//  help-page table 20200122 add
@media screen and (min-width:668px) {
  .h-table {
    margin-bottom: 5px;

    td,
    th {
      border: 1px solid #e6e6e6;
      padding: 5px;
      height: 25px;
      background: #ffffff;
      vertical-align: top;
      text-align: center;
    }

    th {
      font-weight: bold;
      width: 80px;

      &.h-table-wide-cell {
        width: 110px;
      }
    }

    td {
      width: 50px;
      text-align: center;
      
      &.h-table-wide-cell {
        width: 400px;
      }
    }

    .atten {
      font-size: 12px;
      line-height: 13px;
    }

    &-note {
      font-size: 12px;
      line-height: 13px;
    }
  }
}

@media screen and (max-width: 798px) {
  .h-table {
    margin-bottom: 5px;

    td,
    th {
      border: 1px solid #e6e6e6;
      background: #ffffff;
      vertical-align: top;
    }

    th {
      width: 50px;
      padding: 5px;
      font-weight: bold;
      text-align: center;

      &.h-table-wide-cell {
        width: 60px;
      }
    }

    td {
      border: 1px solid #e6e6e6;
      padding: 3px;
      width: 25px;
      vertical-align: top;
      text-align: center;

      &.h-table-wide-cell {
        width: 200px;
      }
    }

    .atten {
      margin-top: 3px;
      font-size: 12px;
    }

    &-note {
      margin-top: 3px;
      font-size: 12px;
      line-height: 20px;
    }
  }
}