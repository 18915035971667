@charset "utf-8";


// 本文（標準）
// -----------------------------------------------------------------
.c-txt {
  font-size: 14px;
  letter-spacing: $LETTER-SPACING-05;
  line-height: $LINE-HEIGHT-BASE;
  @include _sp(){
    letter-spacing: $LETTER-SPACING-03;
  }
}

// 本文（標準太字）
// -----------------------------------------------------------------
.c-txt--bold {
  font-size: 14px;
  @include _fontbold();
  letter-spacing: $LETTER-SPACING-05;
  line-height: $LINE-HEIGHT-BASE;
  @include _sp(){
    letter-spacing: $LETTER-SPACING-03;
  }
}

// 本文（標準小）
// -----------------------------------------------------------------
.c-txt--small {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: $LETTER-SPACING-05;
  line-height: $LINE-HEIGHT-BASE;
  @include _sp(){
    font-size: 13px;
    letter-spacing: $LETTER-SPACING-03;
  }
}

// 本文（小）
// -----------------------------------------------------------------
.c-txt-s {
  font-size: 13px;
  letter-spacing: $LETTER-SPACING-05;
  line-height: $LINE-HEIGHT-180;
  @include _sp(){
    letter-spacing: $LETTER-SPACING-03;
  }
}

// 本文（最小）
// -----------------------------------------------------------------
.c-txt-2s {
  font-size: 12px;
  letter-spacing: $LETTER-SPACING-05;
  line-height: $LINE-HEIGHT-170;
  @include _sp(){
    font-size: 11px;
    letter-spacing: $LETTER-SPACING-03;
    line-height: $LINE-HEIGHT-160;
  }
}

// 文字（キャプション）
// -----------------------------------------------------------------
.c-txt-xs {
  font-size: 10px;
  letter-spacing: $LETTER-SPACING-02;
  line-height: $LINE-HEIGHT-170;
  @include _sp(){
    letter-spacing: $LETTER-SPACING-03;
    line-height: $LINE-HEIGHT-160;
  }
}

// 本文（大）
// -----------------------------------------------------------------
.c-txt-l {
  font-size: 16px;
  letter-spacing: $LETTER-SPACING-05;
  line-height: $LINE-HEIGHT-180;
  @include _sp(){
    letter-spacing: $LETTER-SPACING-03;
  }
}

// 本文（最大）
// -----------------------------------------------------------------
.c-txt-2l {
  font-size: 22px;
  letter-spacing: $LETTER-SPACING-05;
  line-height: $LINE-HEIGHT-180;
  @include _sp(){
    letter-spacing: $LETTER-SPACING-03;
  }
}




