@charset "utf-8";


// -----------------------------------------------------------------
.c-def {
  &__item {
    border-bottom: 1px solid #DDD;
    &:first-of-type {
      border-top: 1px solid #DDD;
    }
    display: flex;
    @include _pc {
      align-items: center;
      justify-content: space-between;
      padding: 22px 0;
    }
    @include _sp {
      flex-direction: column;
      padding: 25px 0;
    }
  }

  &__col1 {
    font-size: 18px;
    @include _fontbold();
    line-height: $LINE-HEIGHT-170;
    @include _sp {
      font-size: 16px;
      line-height: $LINE-HEIGHT-150;
      text-align: center;
    }
  }

  &__col2 {
    @include _pc {
      width: 635px;
      // width: calc(635/900*100%);
    }
    @include _sp {
      margin-top: 15px;
    }
  }
}