@charset "utf-8";

@import url("https://fonts.googleapis.com/css?family=Noto+Serif+JP&subset=japanese");
@import url("https://fonts.googleapis.com/css?family=Material+Icons+Outlined|Material+Icons+Sharp");

$icon-path: "/_assets/fonts";
$icon-id: "8ivb4p";

$ix-icons: (
  fig-1: \e900,
  fig-2: \e901,
  fig-3: \e902,
  fig-4: \e903,
  fig-5: \e904,
  fig-6: \e905,
  fig-7: \e906,
  logo: \e907,
  arw-1: \e908,
  arw-2: \e909,
  arw-3: \e90a,
  blank: \e90b,
  file-1: \e90c,
  qa-1: \e90d,
  menu: \e90e,
  login: \e90f,
  close-1: \e910,
  loupe: \e911,
  person-1: \e912,
  arw-4: \e913,
  check-1: \e914,
  arw-5: \e915
);

@font-face {
  font-family: "ix-tenshoku";
  src: url("#{$icon-path}/ix-tenshoku.ttf?#{$icon-id}") format("truetype"),
    url("#{$icon-path}/ix-tenshoku.woff?#{$icon-id}") format("woff"),
    url("#{$icon-path}/ix-tenshoku.svg?#{$icon-id}#b-ch") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  @extend %_icon-font;
}

[class^="ix-icon"],
[class*=" ix-icon"] {
  @extend %_icon-font;
}

@each $key, $value in $ix-icons {
  &.ix-icon--#{$key} {
    &::before {
      @include _ix-icon($value);
    }
  }
}

// .icon-fig-1:before {
//   content: "\e900";
// }
// .icon-fig-3:before {
//   content: "\e901";
// }
// .icon-fig-2:before {
//   content: "\e902";
// }
// .icon-fig-4:before {
//   content: "\e903";
// }
// .icon-fig-5:before {
//   content: "\e904";
// }
// .icon-fig-6:before {
//   content: "\e905";
// }
// .icon-fig-7:before {
//   content: "\e906";
// }
// .icon-logo-1:before {
//   content: "\e907";
// }
// .icon-arw-1:before {
//   content: "\e908";
// }
// .icon-arw-2:before {
//   content: "\e909";
// }
// .icon-arw-3:before {
//   content: "\e90a";
// }
// .icon-blank:before {
//   content: "\e90b";
// }
// .icon-file-1:before {
//   content: "\e90c";
// }
// .icon-qa-1:before {
//   content: "\e90d";
// }
// .icon-menu:before {
//   content: "\e90e";
// }
// .icon-login:before {
//   content: "\e90f";
// }
// .icon-close-1:before {
//   content: "\e910";
// }
// .icon-loupe:before {
//   content: "\e911";
// }
// .icon-person-1:before {
//   content: "\e912";
// }
// .icon-arw-4:before {
//   content: "\e913";
// }
// .icon-check-1:before {
//   content: "\e914";
// }
// .icon-arw-5:before {
//   content: "\e915";
//   color: #a58352;
// }
