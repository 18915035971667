@charset "utf-8";


// -----------------------------------------------------------------
.c-box-1 {
  &__ttl {
    margin-bottom: 10px;
    text-align: center;
    @include _sp(){
      margin-bottom: 20px;
      .c-hd-5 {
        font-size: 18px;
      }
    }
  }

  &__pct {
    margin-bottom: 20px;
  }
}


// 転職ガイド 転職活動の流れ
// -----------------------------------------------------------------
.c-box-guide-1 {
  $__self: &;

  @include _sp(){
    display: flex;
  }

  a {
    text-decoration: none;
    #{$__self}__body {
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000,.2);
        @include _transition-bg-color();
      }
    }

    &:hover {
      #{$__self}__head {
        background: #000;
      }
      #{$__self}__body {
        &::before {
          background: rgba(#000,0);
        }
      }
    }

    @include _sp(){
      display: flex;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    background: $COLOR-21;
    @include _transition-bg-color();
    @include _sp(){
      width: calc(185/375*100%);
      height: auto;
    }
  }
  &__ttl {
    letter-spacing: $LETTER-SPACING-07;
    text-align: center;
    &-en {
      position: relative;
      display: inline-block;
      font-family: $FONT-FAMILY-ADOBE-GARAMOND;
      text-decoration: none;
      line-height: 1;
      color: $COLOR-06;
      span {
        font-size: 20px;
        text-decoration: none;
        @include _sp() {
          font-size: 18px;
        }
        &:nth-child(2) {
          margin-left: 4px;
          font-size: 28px;
          @include _sp(){
            font-size: 24px;
          }
        }
      }

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        width: 30px;
        border-top: 1px solid rgba($COLOR-02,.2);
        @include _sp(){
          width: 35px;
        }
      }

      &::before {
        left: -10px;
        transform-origin: left center;
        transform: scaleX(-1);
      }

      &::after {
        right: -10px;
        transform-origin: right center;
        transform: scaleX(-1);
      }
    }

    &-jp {
      margin-top: 10px;
      font-family: $FONT-FAMILY-NOTO-SERIF;
      font-size: 16px;
      letter-spacing: $LETTER-SPACING-07;
      text-decoration: none;
      color: $COLOR-02;
      @include _sp(){
        margin-top: 5px;
        font-size: 15px;
      }
    }
  }

  &__body {
    position: relative;
    // &::before {
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: rgba(#000,.2);
    //   @include _transition-bg-color();
    // }
    @include _sp(){
      width: calc(190/375*100%);
      overflow: hidden;
      img {
        vertical-align: middle;
      }
    }
  }
}


// 転職ガイド
// -----------------------------------------------------------------
.c-box-guide-2 {
  $__self: &;

  @include _sp(){
    display: flex;
  }

  a {
    display: block;
    padding: 0 10px 10px;
    text-decoration: none;
    background: $COLOR-02;

    #{$__self}__body {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000,0);
        @include _transition-bg-color();
      }
      // img {
      //   @include _transition-transform();
      // }
    }

    &:hover {
      #{$__self}__body {
        &::after {
          background: rgba(#000,.2);
        }
        // img {
        //   transform: scale($ZOOM-IMG);
        // }
      }
    }

    @include _sp(){
      display: flex;
      width: 100%;
      padding: 0 15px;
      &::after {
        @include _ix-icon(\e908);
        display: flex;
        align-items: center;
        margin-left: auto;
        font-size: 12px;
        color: $COLOR-06;
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96px;
    padding-top: 5px;

    @include _sp(){
      height: 52px;
      padding-top: 0;
    }
  }
  &__ttl {
    letter-spacing: $LETTER-SPACING-07;
    text-align: center;
    @include _sp(){
      display: flex;
      align-items: center;
    }

    &-en {
      position: relative;
      display: inline-block;
      font-family: $FONT-FAMILY-ADOBE-GARAMOND;
      text-decoration: none;
      line-height: 1;
      color: $COLOR-06;
      span {
        font-size: 20px;
        text-decoration: none;
        @include _sp() {
          font-size: 18px;
        }
        &:nth-child(2) {
          margin-left: 4px;
          font-size: 28px;
          @include _sp(){
            font-size: 24px;
          }
        }
      }

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        width: 30px;
        border-top: 1px solid $COLOR-BORDER-05;
        @include _sp(){
          display: none;
        }
      }

      &::before {
        left: -10px;
        transform-origin: left center;
        transform: scaleX(-1);
      }

      &::after {
        right: -10px;
        transform-origin: right center;
        transform: scaleX(-1);
      }
    }

    &-jp {
      font-family: $FONT-FAMILY-NOTO-SERIF;
      font-size: 18px;
      letter-spacing: $LETTER-SPACING-07;
      text-decoration: none;
      color: $COLOR-01;
      @include _sp(){
        margin-left: 10px;
        font-size: 15px;
      }
    }
  }

  &__body {
    position: relative;
    overflow: hidden;
 
    // &::after {
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: rgba(#000,.2);
    //   @include _transition-bg-color();
    // }
    @include _sp(){
      display: none;
    }
  }
}


// 転職事例一覧
// -----------------------------------------------------------------
.c-box-success-1 {
  $__self: &;
  a {
    display: block;
    // width: 333px;
    padding: 20px 20px 25px;
    text-align: center;
    color: $COLOR-01;
    background: $COLOR-02;
    overflow: hidden;
    @include _transition-boxshadow(.5s);
    &:hover {
      box-shadow: 2px 2px 10px 0 rgba(#000, .15);
    }

    @include _sp(){
      width: auto;
      // min-height: 316px;
    }
  }

  &__num {
    margin-bottom: 15px;
    font-family: $FONT-FAMILY-ADOBE-GARAMOND;
    font-size: 18px;
    letter-spacing: $LETTER-SPACING-02;
    line-height: 1;
    color: $COLOR-06;
    @include _sp(){
    }
  }

  &__pct {
    position: relative;
    margin: 0 -20px 15px;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: .2;
      @include _transition-opacity();

      a:hover & {
        opacity: .4;
      }
    }

    img {
      width: 100%;
      height: auto;
      @include _transition-transform(.5s);
      a:hover & {
        transform: scale($ZOOM-IMG);
      }
    }
  }
  
  &__subttl {
    font-size: 12px;
    letter-spacing: $LETTER-SPACING-03;
    line-height: $LINE-HEIGHT-170;
    @include _sp(){
    }
  }

  &__ttl {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 14px * $LINE-HEIGHT-170 * 3;
    max-height: 14px * $LINE-HEIGHT-170 * 3;
    margin: 3px 0;
    // margin: 10px 0 12px;
    font-size: 14px;
    @include _fontbold();
    letter-spacing: $LETTER-SPACING-05;
    line-height: $LINE-HEIGHT-170;
    overflow: hidden;
    // a:hover & {
    //   // text-decoration: underline;
    // }
    @include _sp(){
      // margin: 10px 0 15px;
    }
  }

  &__info {
    $__border: 1px solid $COLOR-BORDER-05;
    border-top: $__border;

    > * {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 34px;
      border-bottom: $__border;
    }

    &-txt-1 {
      font-size: 12px;
      letter-spacing: $LETTER-SPACING-03;
      line-height: $LINE-HEIGHT-170;
      @include _ie11(){
        padding-top: 8px;
      }
    }

    &-txt-2 {
      @include _ie11(){
        padding-top: 8px;
      }
      .t-1 {
        font-size: 12px;
        letter-spacing: $LETTER-SPACING-03;
        line-height: $LINE-HEIGHT-170;
      }

      .t-2 {
        font-size: 15px;
        @include _fontbold();
        letter-spacing: $LETTER-SPACING-03;
        line-height: $LINE-HEIGHT-170;
        @include _sp(){
          font-size: 15px;
        }
      }

      i {
        margin: 0 15px;
        font-size: 16px;
        color: $COLOR-06;
        @include _sp(){
          margin: 0 10px;
        }
        @include _ie11(){
          position: relative;
          top: -4px;
        }
      }
    }
  }
}


// 転職成功事例
// -----------------------------------------------------------------
.c-box-success-2 {
  $__self: &;
  a {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 275px;
    padding-left: 50px;
    color: $COLOR-01;
    
    @include _sp(){
      height: auto;
      padding: 20px 20px 25px;
      text-align: center;
      color: $COLOR-01;
      background: $COLOR-02;
      overflow: hidden;
      @include _transition-boxshadow(.5s);
      &:hover {
        box-shadow: 2px 2px 10px 0 rgba(#000, .15);
      }
    }
  }

  &__pct {
    position: absolute;
    top: 0;
    left: 0;
    width: 1056px;
    height: 275px;
    overflow: hidden;
    z-index: -1;
    @include _sp(){
      position: relative;
      margin: 0 -20px 15px;
      width: auto;
      height: auto;
      z-index: auto;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0;
      @include _transition-opacity();
      @include _sp(){
        opacity: .2;
      }

      a:hover & {
        opacity: .1;
        @include _sp(){
          opacity: .4;
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      @include _transition-transform(.5s);
      a:hover & {
        transform: scale($ZOOM-IMG);
      }
    }
  }

  &__num {
    margin-bottom: 18px;
    @include _sp(){
      order: -1;
      margin-bottom: 15px;
    }

    span {
      display: inline-block;
      font-family: $FONT-FAMILY-ADOBE-GARAMOND;
      font-size: 18px;
      letter-spacing: .1em;
      line-height: $LINE-HEIGHT-135;
      border-bottom: 1px solid rgba($COLOR-06,.5);
      color: $COLOR-06;
      @include _sp(){
        line-height: 1;
        border-bottom: none;
        color: $COLOR-06;
      }
    }
  }

  &__subttl {
    margin-bottom: 3px;
    @include _fontbold();
    @include _sp(){
      margin-bottom: 0;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: $LETTER-SPACING-03;
      line-height: $LINE-HEIGHT-170;
    }
  }

  &__ttl {
    margin-bottom: 15px;

    @include _sp(){
      margin: 10px 0 15px;
      &.c-hd-2 {
        font-family: $FONT-FAMILY;
        font-size: 14px;
        @include _fontbold();
        letter-spacing: $LETTER-SPACING-05;
        line-height: $LINE-HEIGHT-170;
        a:hover & {
          // text-decoration: underline;
        }
      }
    }
  }

  &__info {
    display: flex;
    @include _sp(){
      display: block;
     $__border: 1px solid $COLOR-BORDER-05;
     border-top: $__border;

      > * {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 34px;
        border-bottom: $__border;
      }
    }

    &-txt-1 {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 20px;
      color: $COLOR-02;
      background: $COLOR-21;
      @include _sp(){
        height: auto;
        padding: 0;
        font-size: 12px;
        letter-spacing: $LETTER-SPACING-03;
        line-height: $LINE-HEIGHT-170;
        color: $COLOR-01;
        background: none;
      }
      @include _ie11(){
        padding-top: 8px;
      }
    }

    &-txt-2 {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 30px 0 25px;
      background: $COLOR-02;
      @include _sp(){
        height: auto;
        padding: 0;
      }
      @include _ie11(){
        padding-top: 8px;
      }
    }

    .t-1 {
      @include _sp(){
        font-size: 12px;
        letter-spacing: $LETTER-SPACING-03;
        line-height: $LINE-HEIGHT-170;
      }
    }

    .t-2 {
      font-size: 15px;
      @include _fontbold();
      letter-spacing: $LETTER-SPACING-03;
      @include _sp(){
        letter-spacing: $LETTER-SPACING-03;
        line-height: $LINE-HEIGHT-170;
      }
    }

    i {
      margin: 0 15px;
      font-size: 16px;
      color: $COLOR-06;
      @include _sp(){
        margin: 0 10px;
      }
      @include _ie11(){
        position: relative;
        top: -4px;
      }
    }
  }
}


// 転職成功事例トップ
// -----------------------------------------------------------------
.c-box-success-3 {
  &__num {
    margin-bottom: 30px;
    @include _sp(){
      margin: 40px 0;
    }

    span {
      display: inline-block;
      font-family: $FONT-FAMILY-ADOBE-GARAMOND;
      font-size: 24px;
      letter-spacing: .1em;
      line-height: $LINE-HEIGHT-135;
      border-bottom: 1px solid $COLOR-06;
      color: $COLOR-06;
      @include _sp(){
      }
    }
  }

  &__subttl {
    margin-bottom: 10px;
    font-size: 16px;
    @include _fontbold();
    @include _sp(){
      margin-bottom: 30px;
    }
  }

  &__ttl {
    margin-bottom: 35px;
    &.c-hd-1 {
      font-size: 30px;
    }

    @include _sp(){
      margin: 10px 0 90px;
      &.c-hd-1 {
        font-size: 22px;
        line-height: $LINE-HEIGHT-180;
      }
    }
  }

  &__info {
    display: flex;
    @include _sp(){
     display: none;
     $__border: 1px solid $COLOR-BORDER-05;
     border-top: $__border;

      > * {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 34px;
        border-bottom: $__border;
      }
    }

    &-txt-1 {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 20px;
      color: $COLOR-02;
      background: $COLOR-21;
      @include _sp(){
        height: auto;
        padding: 0;
        font-size: 12px;
        letter-spacing: $LETTER-SPACING-03;
        line-height: $LINE-HEIGHT-170;
        color: $COLOR-01;
        background: none;
      }
      @include _ie11(){
        padding-top: 8px;
      }
    }

    &-txt-2 {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 40px 0 30px;
      background: $COLOR-02;
      @include _sp(){
        height: auto;
        padding: 0;
      }
      @include _ie11(){
        padding-top: 8px;
      }
    }

    .t-1 {
      @include _sp(){
        font-size: 12px;
        letter-spacing: $LETTER-SPACING-03;
        line-height: $LINE-HEIGHT-170;
      }
    }

    .t-2 {
      font-size: 15px;
      @include _fontbold();
      letter-spacing: $LETTER-SPACING-03;
      @include _sp(){
        letter-spacing: $LETTER-SPACING-03;
        line-height: $LINE-HEIGHT-170;
      }
    }

    i {
      margin: 0 15px;
      font-size: 16px;
      color: $COLOR-06;
      @include _sp(){
        margin: 0 10px;
      }
      @include _ie11(){
        position: relative;
        top: -4px;
      }
    }
  }
}


// 転職事例一覧
// -----------------------------------------------------------------
.c-box-success-4 {
  $__self: &;
  $__before_w: 45%;
  $__after_w: 55%;

  &__ttl {
    margin-bottom: 4px;
    font-family: $FONT-FAMILY-NOTO-SERIF;
    font-size: 15px;
    letter-spacing: $LETTER-SPACING-07;
    text-align: center;

    .is-detail & {
      margin: 0 0 2px;
      font-family: inherit;
      font-size: 13px;
      text-align: left;

      &::before {
        content: '';
        display: inline-flex;
        height: 1.2em;
        position: relative;
        top: 3px;
        margin-right: 7px;
        border-left: 3px solid rgba($COLOR-06,.5);
      }
    }
  }

  &__inner {
    position: relative;
    background: #E5E5E4;
    overflow: hidden;
  }

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    width: $__after_w;
    height: 100%;
    
    span {
      width: 100%;
      height: 50.4%;
      position: absolute;
      background: $COLOR-06;
      @include _sp(){
      }
      // @include _ie11(){
      //   height: 50.4%;
      // }

      &::before {
        content: '';
        position: absolute;
        left: -1px;
        width: 4px;
        height: 100%;
        background: #fff;
      }

      &:first-child {
        top: 0;
        right: 0;
        transform-origin: left top;
        transform: translateX(0) scaleX(1) skewX(30deg);
      }

      &:last-child {
        bottom: 0;
        right: 0;
        transform-origin: left bottom;
        transform: translateX(0) scaleX(1) skewX(-30deg);
      }
    }
  }


  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    
    @include _pc(){
      height: 106px;
      padding: 10px;
      .is-detail & {
        height: auto;
        padding: 7px 10px 3px;
        @include _ie11(){
          padding: 9px 10px 5px;
        }
      }
    }
    @include _sp(){
      padding: 8px 5px 8px 15px;
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    // justify-content: center;
  }

  &__col-1 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: $__before_w;
    
    .is-range & {
      align-items: flex-end;
    }

    @include _sp(){
      justify-content: flex-start;
    }
  }

  &__col-2 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: $__after_w;
    // padding-left: 12%;
    padding-left: 6%;
    color: $COLOR-02;

    .is-range & {
      align-items: flex-end;
    }

    @include _sp(){
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 12%;
    }
  }

  &__info {
    &-txt-1 {
      font-size: 14px;
      line-height: $LINE-HEIGHT-170;
      @include _sp(){
        // font-size: 13px;
        line-height: $LINE-HEIGHT-150;
      }
    }

    &-txt-2 {
      margin-top: -2px;
      // font-size: 12px;
      font-size: 13px;
      line-height: $LINE-HEIGHT-170;
      @include _pc(){
        .is-detail & {
          margin-top: -2px;
        }
      }
      @include _sp(){
        margin-top: 0;
        // font-size: 11px;
        font-size: 13px;
        line-height: $LINE-HEIGHT-150;
      }
    }

    &-txt-3 {
      margin-top: -2px;
      font-family: $FONT-FAMILY-NOTO-SERIF;
      font-size: 24px;
      line-height: $LINE-HEIGHT-135;
      @include _pc(){
        small {
          font-size: 18px;
        }

        #{$__self}__col-1 & {
          font-size: 20px;
          line-height: $LINE-HEIGHT-180;
          small {
            font-size: 16px;
          }
        }

        .is-detail & {
          margin-top: -4px;
          @include _ie11(){
            margin-top: -8px;
          }
        }
      }

      @include _sp(){
        margin-top: 0;
        font-size: 25px;
        small {
          font-size: 19px;
        }
      }  

      #{$__self}__col-1 & {
        color: $COLOR-19;
      }    
    }

    &-txt-4 {
      @include _sp(){
        &::after {
          position: relative;
          content: '';
          display: block;
          padding-top: 1em;
        }
      }

      span {
        display: flex;
        align-items: center;
        position: relative;
        top: 2px;
        font-size: 11px;
        @include _fontbold();
        letter-spacing: normal;
        line-height: $LINE-HEIGHT-135;
        &::before {
          content: '＋';
          display: flex;
          align-items: center;
          margin: 0 5px;
          font-size: 16px;
        }

        @include _ie11(){
          top: 5px;
        }
        
        @include _pc(){
          .is-detail & {
            top: -2px;
            @include _ie11(){
              top: 1px;
            }
            &::before {
              font-size: 12px;
              font-weight: bold;
            }

            .u-use--pc {
              display: none;
            }
          }
        }

        @include _sp(){
          display: inline-flex;
          position: absolute;
          bottom: -3.5em;
          left: calc(12% + 1.25em);
          font-size: 10px;
          &::before {
            display: inline-flex;
            align-items: center;
            margin-right: 3px;
            font-size: 10px;
          }
        }
      }
    }
  }
}


// ヘッドハンター
// -----------------------------------------------------------------
.c-box-hunter-1 {
    height: 100%;
    // background: $COLOR-21;
  a {
    position: relative;
    display: flex;
    flex-direction: column;
    @include _transition-bg-color(.5s);
    @include _sp(){
      flex-direction: row;
      align-items: center;
      background: $COLOR-21;
      &:hover {
        background: #000;
        &::before {
          opacity: .1;
        }
      }
    }

    &::before {
      @include _sp(){
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        left: -10px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        background: #000;
        z-index: 1;
        opacity: 0;
        @include _transition-opacity;
      }
    }
  }

  &__pct {
    overflow: hidden;
    @include _sp(){
      width: 40%;
      height:auto;
    }
    img {
      position: relative;
      width: 100%;

      height: auto;
      @include _transition-transform(.5s);
      a:hover & {
        transform: scale($ZOOM-IMG) rotate(.1deg);
      }
    }
  }

  &__info {
    height: 95px;
    padding: 18px 15px 17px 15px;
    text-align:center;
    background: $COLOR-21;
    margin:0;
    @include _transition-bg-color(.5s);
    a:hover & {
      background: #000;
    }
    @include _sp(){
      height:100%;
      width: 60%;
      text-align: left;
      padding:0 10px 4px 15px;
    }
    &-txt {
      font-size: 13px;
      margin-bottom: 8px;
      letter-spacing: $LETTER-SPACING-03;
      line-height: $LINE-HEIGHT-170;
      color: $COLOR-02;
      @include _sp(){
        margin-bottom: 6px;
      }
    }
    &-name {
      font-family: $FONT-FAMILY-NOTO-SERIF;
      font-size: 24px;
      letter-spacing: $LETTER-SPACING-07;
      text-align: center;
      line-height: 1;
      color: $COLOR-06;
      @include _sp(){
      text-align: left;
        }
      }
    }
}


// ヘッドハンター
// -----------------------------------------------------------------
.c-box-hunter-2 {
  a {
    display: flex;
    align-items: center;
    width: 280px;
    color: $COLOR-01;
    background: $COLOR-13;
    @include _sp {
      width: 100%;
    }
  }

  &__pct {
    width: 80px;
    overflow: hidden;
    @include _sp {
      width: 100px;
    }
    img {
      width: 100%;
      height: auto;
      // @include _transition-transform(.5s);
      // a:hover & {
      //   transform: scale($ZOOM-IMG);
      // }
    }
  }

  &__info {
    margin-left: 15px;
    padding-right: 10px;
    @include _sp {
      .c-txt-xs {
        font-size: 12px;
      }
    }

    &-name {
      margin: 2px 0 4px;
      font-family: $FONT-FAMILY-NOTO-SERIF;
      font-size: 22px;
      line-height: $LINE-HEIGHT-135;
      color: $COLOR-06;
      @include _sp {
        margin: 2px 0 8px;
      }
    }

    &-link {
      @include _sp {
        .c-txt-xs {
          font-size: 11px;
        }
      }

      a:hover & {
        span {
          text-decoration: underline;
        }
      }
      i {
        // margin-right: 4px;
        color: $COLOR-06;
      }
    }
  }
}


// -----------------------------------------------------------------
.c-box-hunter-3 {
  display: flex;
  padding: 30px 25px 25px 40px;
  background: $COLOR-13;
  @include _sp {
    display: block;
    margin: 0 -20px;
    padding: 30px 20px;
  }

  &__col-1 {
    @include _pc {
      margin-right: 30px;
      .c-txt {
        line-height: $LINE-HEIGHT-170;
      }
    }
    @include _sp {
      margin-bottom: 20px;
    }
  }

  &__col-2 {
  }

  &__person {
    display: flex;
    white-space: nowrap;
    @include _sp {
      align-items: center;
      justify-content: center;
    }
  }

  &__pct {
    min-width: 132px;
    width: 132px;
    margin-right: 15px;
    @include _sp {
      min-width: 90px;
      width: 90px;
    }
  }

  &__info {
    @include _sp {
      .c-txt-xs {
        font-size: 12px;
      }
    }
    &-name {
      margin-top: 4px;
      font-family: $FONT-FAMILY-NOTO-SERIF;
      font-size: 24px;
      letter-spacing: $LETTER-SPACING-07;
      line-height: $LINE-HEIGHT-135;
      color: $COLOR-06;
      @include _sp {
        margin-top: 2px;
      }
    }
  }
}

// 案件紹介
// -----------------------------------------------------------------
.c-box-case {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: $LINE-HEIGHT-160;
  height: 100%;

  &__logo {
    width: 187px;
    height: 77px;
    margin: 0 auto;
    text-align: center;
    @include _sp(){
      width: auto;
      height: auto;
    }
  }

  &__ttl {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: calc(2em * 1.6 + 12px);
    margin: 0 0 10px;
    // padding: 0 10px;
    text-align: center;
    @include _fontbold();
    border-bottom: 1px solid rgba(#000,.15);
    span {
      padding: 5px 0;
    }
  }
  &__lede {
    margin-bottom: 15px;
  }

  ul {
    width: 100%;
    margin-top: auto;
    padding: 10px 10px 10px 2.25em;
    letter-spacing: $LETTER-SPACING-02;
    background: rgba(#000,.05);
    li {
      list-style-type: disc;
      list-style-position: outside;
      font-size: 11px;
      line-height: $LINE-HEIGHT-180;
    }
  }
}






// 求人実績
// -----------------------------------------------------------------
.c-box-job {
  border: 8px solid $COLOR-BORDER-03;

  &__inner {
    margin: 20px 20px 15px;
  }

  &__ttl {
    margin-bottom: 20px;
    font-size: 16px;
    @include _fontbold();
    line-height: $LINE-HEIGHT-180;
    @include _ie11() {
      position: relative;
      top: 4px;
    }
    @include _sp() {
      margin-bottom: 15px;
    }
  }

  &__dl {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    &:last-of-type {
      margin-bottom: 20px;
    }
    @include _sp() {
      display: block;
      margin-bottom: 15px;
    }

    dt {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 82px;
      height: 24px;
      margin-right: 18px;
      font-size: 13px;
      @include _fontbold();
      white-space: nowrap;
      color: $COLOR-02;
      background: $COLOR-09;
      @include _ie11() {
        padding-top: 5px;
      }
      @include _sp() {
        justify-content: flex-start;
        min-width: initial;
        margin-right: 0;
        margin-bottom: 10px;
        padding: 0 12px;
      }
    }

    dd {
      position: relative;
      top: -2px;
      @include _ie11() {
        position: relative;
        top: 1px;
      }
      @include _sp() {
        letter-spacing: $LETTER-SPACING-03;
      }
    }
  }

  &__no {
    margin-top: 10px;
    font-size: 12px;
    text-align: right;
    line-height: $LINE-HEIGHT-160;
    color: #555;
  }
}


// 紹介求人例
// -----------------------------------------------------------------
.c-box-jobs-2 {
  height: 100%;
  padding: 20px;
  border: 1px solid $COLOR-BORDER-05;
  background: #fff;

  &__company {
    font-size: 13px;
    line-height: $LINE-HEIGHT-180;
    @include _pc(){
      text-align: justify;
    }
  }

  &__office {
    font-size: 14px;
    @include _fontbold();
    letter-spacing: $LETTER-SPACING-03;
    line-height: $LINE-HEIGHT-150;
    @include _pc(){
      min-height: calc(2em * 1.5);
      text-align: justify;
    }
  }

  &__range {
    margin-top: 5px;
    font-size: 14px;
    line-height: $LINE-HEIGHT-170;
  }
}




