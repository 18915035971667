@charset "utf-8";

.p-header {
  position: relative;
  &__inner {
    display: flex;
    height: $GLOBAL-HEADER-HEIGHT;
    padding: 0 70px;
    @include _sp(){
      justify-content: center;
      align-items: center;
      height: $GLOBAL-HEADER-HEIGHT-SP;
      padding: 0 20px;
    }
  }
  
  &__logo {
    margin: 4px 0 0 0;
    @include _sp(){
      margin: 2px 0 0;
    }

    a {
      display: flex;
      align-items: flex-end;
      color: $COLOR-01;
    }

    img {
      width: 125px;
      height: auto;
      @include _sp(){
        width: 107px;
        height: auto;
      }
    }

    &-txt {
      margin-left: 15px;
      font-size: 12px;
      letter-spacing: $LETTER-SPACING-03;
      line-height: 1;
      white-space: nowrap;
    }
  }

  &__menu {
    @include _sp(){
     position: absolute;
     top: 6px;
     left: 10px;
     font-size: 22px;
     color: #9FA0A0;
     cursor: pointer;
     outline: none;
     -webkit-tap-highlight-color: rgba(0,0,0,0);
     @include _ie11(){
       top: 8px;
     }
    }
  }

  &__login {
    @include _sp(){
      position: absolute;
      right: calc(7px + 44px);
      @include _ie11(){
        top: 8px;
      }

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1px;
        outline: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
      }

      &-icn {
        display: flex;
        margin: 1px 0 1px;
        font-size: 21px;
        color: #9FA0A0;
        -webkit-text-stroke: .5px white;
      }

      &-txt {
        margin-top: 2px;
        margin-bottom: 2px;
        font-size: 9px;
        line-height: 1;
        color: #222;
        letter-spacing: -0.4px;
      }
    }
  }

  &__register {
    @include _sp(){
      position: absolute;
      right: 7px;
      @include _ie11(){
        top: 8px;
      }

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1px;
        outline: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
      }

      &-icn {
        display: flex;
        margin: 0;
        font-size: 24px;
        color: #9FA0A0;
        -webkit-text-stroke: .8px white;
        &.is-add {
          margin: 0;
          position: relative;
          left: -3px;
          font-size: 23px;
          -webkit-text-stroke: .5px white;
        }
      }

      &-txt {
        margin-top: 2px;
        margin-bottom: 2px;
        font-size: 9px;
        line-height: 1;
        color: #222;
        letter-spacing: 0.1px;
      }
    }
  }

  &__site-top {
    // display: none;

    // .is-site-top & {
      display: flex;
      align-items: center;
      margin-left: auto;

      > * {
        width: 120px;
        margin-left: 10px;
      }
    // }

    @include _sp(){
      display: none;
    }
  }
}


// -----------------------------------------------------------------
.p-header-sp-menu {
  transform: translateX(-105vw);

  @include _sp(){
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100%;
    height: 110vh;
    background: rgba(#000,.4);
    transform: translateX(-105vw);
    @include _transition-transform(.6s, $_ease-in-out-quint);

    .is-sp-menu-open & {
      transform: translateX(0);
    }
  }

  &__inner {
    position: relative;
    width: calc(100vw - 65px);
    height: 100vh;
    padding-bottom: 120px;
    background: $COLOR-19;
    box-shadow: 5px 0 8px 0 rgba(#000,.2);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 65px;
    height: 100vh;

    .ix-icon--close-1 {
      position: absolute;
      top: 20px;
      right: 21px;
      font-size: 20px;
      color: #fff;
      opacity: 0;
      transform: scale(.5);
      @include _transition-all(.4s, $_ease-out-quart);

      .is-sp-menu-open & {
        opacity: 1;
        transform: scale(1);
        @include _transition-all(.6s, $_ease-in-out-quart, .5s);
      }
    }
  }

  &__head {
    padding: 35px 25px;
    background: $COLOR-23;
    > * {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
//GIO記述
.p-header__inner {
  @media screen and (max-width:1280px) {
    padding-inline: 30px;
  }
}
.p-header__logo {
  display: flex;
  align-items: center;
  gap: 20px;
  @include _pc {
    margin-top: 0;
    a {
      margin-block: 10.2px;
    }
  }
}
.p-header__logo-text {
  font-weight: 500;
  font-size: 10px;
  line-height: 1.5;
  color: #142F71;
  @include _sp {
    display: none;
  }
}
.p-header__arrow-link {
  position: relative;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1;
  color: #222;
  width: fit-content;
  padding-right: 20px;
  margin-left: 0;
  margin-right: 20px;
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 49%;
    right: 0;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background: url(/_assets/img/beginner/message/arrow_back_ios.svg);
  }
  &:hover {
    color: #0068b7;
    transition: .5s;
    &::after {
      background: url(/_assets/img/beginner/message/arrow_hover_ios.svg);
    }
  }
}

.p-header__arrow-link-sp {
  position: relative;
  display: block;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 30px;
  color: #fff;
  width: fit-content;
  letter-spacing:0.07rem;
  padding-right: 20px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: 20px;
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background: url(/_assets/img/beginner/message/arrow_forward_ios.svg);
  }
}